import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

/**
 * Session context
 * @type {{
 * session: string | null;
 * createSession: (token: string) => void;
 * removeSession: () => void;
 * }}
 */
export const SessionContext = createContext();

/**
 * SessionProvider provides a user session management system
 * @param {{ children: React.ReactNode }} props
 */
function SessionProvider({ children }) {
    const [session, setSession] = useState(sessionStorage.getItem('session'));
    const timeoutRef = useRef();

    /**
     * Delete session including in storage
     */
    const removeSession = useCallback(() => {
        setSession(null);
        sessionStorage.removeItem('session');
    }, []);

    /**
     * Refreshes the remaining time to delete the session
     */
    const resetTimeout = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        const timeout = setTimeout(removeSession, parseInt(process.env.REACT_APP_SESSION_TIME, 10));
        timeoutRef.current = timeout;
    }, [removeSession]);

    /**
     * Create a session and save it to storage
     * @type {(token: string) => void}
     */
    const createSession = useCallback(
        (token) => {
            setSession(token);
            sessionStorage.setItem('session', token);
            resetTimeout();
        },
        [resetTimeout],
    );

    // Start timeout if there is a session
    useEffect(() => {
        if (session) {
            resetTimeout();
        }
    }, [session, resetTimeout]);

    // Refresh expiration time on click/keyup
    useEffect(() => {
        document.addEventListener('click', resetTimeout);
        document.addEventListener('keyup', resetTimeout);

        return () => {
            document.removeEventListener('click', resetTimeout);
            document.removeEventListener('keyup', resetTimeout);
        };
    }, [resetTimeout]);

    const memo = useMemo(() => ({ session, createSession, removeSession }), [session, createSession, removeSession]);
    return <SessionContext.Provider value={memo}>{children}</SessionContext.Provider>;
}

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SessionProvider;
