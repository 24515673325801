/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import classNames from 'classnames';
import Container from '../components/atoms/Container';
import Title from '../components/atoms/Title';
import repeat from '../utils/repeat';
import Loader from './Loader';

/**
 * Render a Container with data columns
 * @param {{
 *      color: 'white' | 'green';
 *      loading: boolean;
 *      onClick: undefined | () => void;
 *      views: number;
 *      title: string;
 *      subtitle: string | undefined;
 *      icon: JSX.Element | undefined;
 *      columns: {[column: string]: string};
 * }} props
 *
 * @returns {JSX.Element} View
 */
function View({ color = 'white', loading = false, onClick, views, title, subtitle, icon, columns }) {
    if (loading) {
        return (
            <Container>
                <div className="flex p-5 flex-col sm:flex-row gap-5">
                    <div className="w-full sm:w-1/2 flex flex-col gap-2 my-auto">
                        <div className="w-1/2">
                            <Loader shadow />
                        </div>

                        <Loader shadow />
                    </div>

                    <div className="w-full flex flex-col sm:flex-row gap-3 my-auto">
                        {repeat(
                            views,
                            <div className="w-full flex sm:flex-col gap-2 text-center">
                                <div className="w-full">
                                    <Loader shadow />
                                </div>
                                <div className="w-full">
                                    <Loader shadow />
                                </div>
                            </div>,
                        )}
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <Container color={color} onClick={onClick}>
            <div className="w-full p-5 flex flex-col sm:flex-row gap-5">
                <div
                    className={classNames('w-full overflow-hidden flex flex-col gap-2 my-auto', {
                        'sm:w-1/3': (columns && true) || false,
                    })}
                >
                    <Title size="lg">{title}</Title>
                    {subtitle && <Title size="sm">{subtitle}</Title>}
                </div>

                {columns && (
                    <div className="w-3/5 flex flex-col sm:flex-row gap-3 my-auto">
                        {Object.keys(columns).map((column, i) => {
                            if (columns[column] === null) {
                                return null;
                            }

                            return (
                                <div key={i} className="min-w-fit w-full flex sm:flex-col gap-2 text-center">
                                    <p className="text-sm font-semibold">{column}</p>
                                    <p className="text-sm ml-auto sm:ml-0">{columns[column]}</p>
                                </div>
                            );
                        })}
                    </div>
                )}

                {icon && <div className="mx-auto sm:mx-3 my-auto">{icon}</div>}
            </div>
        </Container>
    );
}

export default View;
