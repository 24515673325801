// CompetitionUpdatePage.jsx
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { faArrowsRotate, faBan, faCircle, faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Select from '../atoms/Select';
import Tag from '../atoms/Tag';
import Banner from '../atoms/Banner';
import NavbarTemplate from '../templates/NavbarTemplate';
import viewAdminCompetition from '../../services/viewAdminCompetition';
import getUser from '../../services/getUser';
import Repeat from '../atoms/Repeat';
import CardToggle from '../molecules/CardToggle';
import Container from '../atoms/Container';
import datetimeToUTC from '../../utils/datetimeToUTC';
import updateAdminCompetition from '../../services/updateAdminCompetition';
import getAdminChallenges from '../../services/getAdminChallenges';
import ChallengeAdminSearch from '../organisms/ChallengeAdminSearch';
import Pager from '../molecules/Pager';
import UTCToDatetime from '../../utils/UTCToDatetime';
import Spinner from '../atoms/Spinner';
import useModal from '../../hooks/useModal';
import ChallengeViewModal from '../modals/ChallengeViewModal';
import Toast from '../atoms/Toast';
import Redirect from '../molecules/Redirect';


function CompetitionUpdatePage() {

    function getColorForStatus(status) {
        switch (status) {
            case 'PRODUCTION':
                return 'green';
            case 'DELETED':
                return 'red';
            case 'QUEUE':
                return 'blue';
            case 'NOT_APPROVED':
                return 'orange';
            case 'DISABLED':
                return 'gray';
            case 'STAGING':
                return 'yellow';
            default:
                return 'gray';
        }
    }

    function getColorForType(type) {
        switch (type) {
            case 'HASH':
                return 'yellow';
            case 'REGEXP':
                return 'red';
            case 'DYNAMIC':
                return 'green';
            case 'PLAIN':
                return 'blue';
            case 'CODE':
                return 'gray';
            case 'OPEN':
                return 'orange';
            default:
                return 'gray';
        }
    }

    function getColorForScore(score) {
        if (score >= 75) {
            return 'green';
        }
        if (score >= 50) {
            return 'yellow';
        }
        if (score >= 25) {
            return 'orange';
        }
        return 'red';
    }

    const normalizeChallenges = (challenges) => {
        const normalized = [];
    
        Object.keys(challenges).forEach(category => {
            challenges[category].forEach(challenge => {
                normalized.push({
                    chaGUID: challenge.guid,
                    name: challenge.name,
                    score: challenge.score,
                    description: challenge.description,
                    type: challenge.typeAnswer,
                    categoryName: category
                });
            });
        });
    
        return normalized;
    };

    const extractChallengeGuids = (challenges) => {
        const guids = [];
        Object.keys(challenges).forEach(category => {
            challenges[category].forEach(challenge => {
                guids.push(challenge.guid);
            });
        });
        return guids.join(',');
    };

    const selectedChallengesGUIDs = (challenges) => {
        if (!challenges || challenges.length === 0) {
            return '';
        }
    
        const guids = challenges.map(challenge => challenge.chaGUID);
        return guids.join(',');
    };

    const navigate = useNavigate();
    
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const { competition } = useParams();
    const { session } = useSession();
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    
    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    
    // Redirect if user is not an admin
    useEffect(() => {
        if (userQuery.isSuccess && userQuery.data &&!userQuery.data?.roles.includes('admin')) {
            navigate('/');
        }
    }, [userQuery.isSuccess, userQuery.data, navigate]);

    const [formData, setFormData] = useState({
        name: '',
        status: '',
        visibility: '',
        description: '',
        duration: '',
        startDate: '',
        endDate: '',
    });

    const [errorChallengesLoading, setErrorChallengesLoading] = useState(false);

    const [challenges, setChallenges] = useState([]);
    const [challengeFeedbackMessage, setChallengeFeedbackMessage] = useState(null);
    const [challengePage, setChallengePage] = useState(0);    
    const perPage  = 10;
    
    const [selectedChallenges, setSelectedChallenges] = useState([]);
    
    const [challengeFilters, setChallengeFilters] = useState({
        challengeName: '',
        categories: [],
        answerType: '',
    });

    const competitionViewMutation = useMutation({
        mutationFn: (competitionGUID) => viewAdminCompetition(competitionGUID, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            removeFeedback();
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: (data) => {
            // Normalize challenges data for showing in the ToggleCard
            const normalizedChallenges = normalizeChallenges(data.challenges);
            // Create a string with the challenges guids
            const challengeGuids = extractChallengeGuids(data.challenges);

            setSelectedChallenges(normalizedChallenges);

            setFormData({
                name: data.name || '',
                status: data.status || 'ACTIVE',
                visibility: data.visibility || 'PUBLIC',
                description: data.description || '',
                duration: data.duration ? String(data.duration) : null,
                challenges: challengeGuids,
                startDate: data.startDate ? UTCToDatetime(data.startDate.date) : '',
                endDate: data.endDate ? UTCToDatetime(data.endDate.date) : '',
            });
            
            removeFeedback();
        },
    });

    const competitionUpdateMutation = useMutation({
        mutationFn: (comp) => updateAdminCompetition(comp.guid, comp.name, comp.status, 
            comp.visibility, comp.description, comp.duration, comp.challenges, comp.startDate, comp.endDate, session, i18next.language),
        onMutate: () => {
            setIsSending(true);
        },
        onError: (error) => {
            window.scrollTo(0, 0);
            setIsSending(false);
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            setIsSending(false);
            setIsRedirecting(true);
        }
    });

    useEffect(() => {
        if (competition) {
            competitionViewMutation.mutate(competition);
        }
    }, [competition]);


    // Challenge Logic

    const challengeQueryKey = [session, 'admin', 'challenges', challengePage, perPage, challengeFilters];

    // Get challenges
    const challengesQuery = useQuery({
        queryKey: challengeQueryKey,
        queryFn: () => getAdminChallenges(
            challengePage, 
            perPage, 
            session, 
            i18next.language,
            challengeFilters.challengeName || null, 
            challengeFilters.categories || null, 
            challengeFilters.answerType || null),
        enabled:!!session,
        retry: 3,
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
        onError: (error, variables, context) => {
            removeFeedback();
            if (context?.failureCount >= 3) {
                setErrorChallengesLoading(true);
            }
        },
        onSuccess: (data) => {
            setErrorChallengesLoading(false);
            if (data.challenges.length > 0) {
                setChallengeFeedbackMessage({ type: 'success', message: i18next.t('challenges-found') });
            } else {
                setChallengeFeedbackMessage({ type: 'warn', message: i18next.t('no-challenges-found') });
            }
        },
        onSettled: () => {
        }
    });
    

    const handleChallengesReload = () => {
        setErrorChallengesLoading(false);
        challengesQuery.refetch();
    };

    const handleChallengeSelect = (challenge) => {
        setSelectedChallenges((prevSelected) => {
            if (prevSelected.some((c) => c.chaGUID === challenge.chaGUID)) {
                setToastMessage(i18next.t('challenge-already-selected'));
                setToastVisible(true);
                return prevSelected;
            }
            return [...prevSelected, challenge];
        });
    };

    const handleChallengeView = (chaInfoObj) => {
        
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <ChallengeViewModal
                data={chaInfoObj}
                onCancel={onCancel}
            />, true, true
        );
    };

    const groupChallengesByCategory = (challengesList) => 
        challengesList.reduce((acc, { categoryName, ...rest }) => {
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            acc[categoryName].push({ categoryName, ...rest });
            return acc;
        }, {}
    );

    const handleChallengeRemove = (chaGUID) => {
        setSelectedChallenges((prevSelected) =>
            prevSelected.filter((challenge) => challenge.chaGUID !== chaGUID)
        );
    };

    const groupedChallenges = groupChallengesByCategory(selectedChallenges);

    const handleChallengeSearch = ({ challengeName, selectedCategories, selectedType }) => {
        setChallengeFeedbackMessage(null);
        setChallengeFilters({
            challengeName,
            categories: selectedCategories,
            answerType: selectedType,
        });
    };

    const handleCloseToast = () => {
        setToastVisible(false);
    };
    
    useEffect(() => {
        if (challengesQuery.isSuccess && challengesQuery.data) {
            setChallenges(challengesQuery.data.challenges);
        }
    }, [challengesQuery.isSuccess, challengesQuery.data]);


    const validate = () => {
        const validationErrors = [];

        if (!formData.name) {
            validationErrors.push(i18next.t('competition-name'));
        }

        if (!formData.status) {
            validationErrors.push(i18next.t('status'));
        }

        if (!formData.visibility) {
            validationErrors.push(i18next.t('visibility'));
        }

        if (formData.name.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        if (formData.description.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            createFeedback('warn', errorMessage);
            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {

            const challengeGuids = selectedChallengesGUIDs(selectedChallenges);

            const formattedStartDate = formData.startDate ? datetimeToUTC(formData.startDate) : null;
            const formattedEndDate = formData.endDate ? datetimeToUTC(formData.endDate) : null;


            competitionUpdateMutation.mutate({
                guid: competition,
                name: formData.name,
                status: formData.status,
                visibility: formData.visibility,
                description: formData.description,
                duration: formData.duration,
                challenges: challengeGuids,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });
        }
    };

    
    const handleCancel = () => {
        navigate('/admin/panel');
    };

    return (
        <NavbarTemplate>
            <div className="w-full p-5 flex flex-col gap-5">
                <Spinner isLoading={competitionViewMutation.isLoading} />
                <Toast 
                    message={toastMessage} 
                    type="warning" 
                    isVisible={toastVisible} 
                    duration={3000} 
                    onClose={handleCloseToast} 
                />
                <div className="w-full flex items-center gap-x-2">
                    <Banner color="green" borderColor='white'>{i18next.t('competition-update')}</Banner>
                </div>
                <div className="w-full flex">
                    {feedbackElement}
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('competition-name')}</Title>
                        <Input
                            focus
                            color="white"
                            type="text"
                            placeholder={i18next.t('competition-ex-name')}
                            value={formData.name || ''}
                            onType={(value) => setFormData({ ...formData, name: value })}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('status')}</Title>
                        <Select
                            color="white"
                            selected={formData.status}
                            onSelect={(selected) => setFormData({ ...formData, status: selected })}
                            options={{
                                [null]: {
                                    ACTIVE: i18next.t('active'),
                                    INACTIVE: i18next.t('inactive'),
                                },
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('visibility')}</Title>
                        <Select
                            color="white"
                            selected={formData.visibility}
                            onSelect={(selected) => setFormData({ ...formData, visibility: selected })}
                            options={{
                                [null]: {
                                    PUBLIC: i18next.t('competition-public'),
                                    PRIVATE: i18next.t('competition-private'),
                                },
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('competition-duration')}</Title>
                        <Input
                            color="white"
                            type="text"
                            placeholder={String(3600)}
                            value={formData.duration}
                            onType={(value) => {
                                const trimmedValue = value.trim();
                                setFormData({
                                    ...formData,
                                    duration: trimmedValue === '' ? null : trimmedValue,
                                });
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('start-date')}</Title>
                        <Input
                            color="white"
                            type="datetime-local"
                            value={UTCToDatetime(formData.startDate)}
                            onType={(value) => setFormData({ ...formData, startDate: value })}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('end-date')}</Title>
                        <Input
                            color="white"
                            type="datetime-local"
                            value={UTCToDatetime(formData.endDate)}
                            onType={(value) => setFormData({ ...formData, endDate: value })}
                        />
                    </div>
                    <div className="flex flex-col gap-2 col-span-1 md:col-span-3">
                        <Title size="sm">{i18next.t('description')}</Title>
                        <Input
                            color="white"
                            type="textarea"
                            placeholder={i18next.t('competition-ex-description')}
                            value={formData.description || ''}
                            onType={(value) => setFormData({ ...formData, description: value })}
                        />
                    </div>
                </div>
                <div className="flex w-full flex-col gap-y-2 mb-7">
                    {errorChallengesLoading ? (
                        <div className="w-1/6 flex self-center">
                            <Button
                                color='green'
                                icon={faArrowsRotate}
                                onClick={handleChallengesReload}
                            >
                                {i18next.t('reload')}
                            </Button>
                        </div>
                    ) : (
                        <div className="w-full flex flex-col mb-3">
                            {feedbackElement && (
                                <div className="w-full mb-4">
                                    {feedbackElement}
                                </div>
                            )}
                            <div className="w-full flex flex-row">
                                <div className="w-8/12">
                                    <div className="w-full flex items-center mb-4">
                                        <Tag color="green" size='lg'>{i18next.t('relate-challenges')}</Tag>
                                    </div>
                                    <ChallengeAdminSearch
                                        isLoading={challengesQuery.isLoading}
                                        onSearch={handleChallengeSearch} 
                                        feedbackMessage={challengeFeedbackMessage}
                                    />
                                    <Container>
                                        <div className="w-full divide-y">
                                            <div className="w-full flex items-center py-2 gap-x-3">
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('status')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('category')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('type')}</Title>
                                                </div>
                                                <div className="flex-grow font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('max-attempts')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score-type')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score')}</Title>
                                                </div>
                                                <div className="flex-none w-[20%] font-semibold text-center">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                                </div>
                                            </div>
                                            {challengesQuery.isLoading ? (
                                                <Repeat count={perPage}>
                                                    <div className="w-full flex p-4 gap-4">
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-16 h-5 flex-shrink-0"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-1/4 h-5"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-1/4 h-5"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-2/4 h-5"
                                                        />
                                                    </div>
                                                </Repeat>
                                            ) : (
                                                challenges && challenges.map((chaInfoObj) => {

                                                    const isSelected = selectedChallenges.some(selected => selected.chaGUID === chaInfoObj.chaGUID);

                                                    return (
                                                        <div 
                                                            key={chaInfoObj.chaGUID} 
                                                            className={`flex w-full py-2 items-center gap-x-3 cursor-pointer select-none ${isSelected ? 'bg-slate-100' : ''}`}
                                                            onClick={() => handleChallengeSelect(chaInfoObj)}
                                                            onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleChallengeSelect(chaInfoObj); }}
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            <div className="flex-none w-[8%] text-center" title={chaInfoObj.status}>
                                                                <FontAwesomeIcon color={getColorForStatus(chaInfoObj.status)} icon={faCircle} />
                                                            </div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.categoryName}
                                                            </div>
                                                            <div className="flex-none w-[8%] flex justify-center">
                                                                {["HASH", "REGEXP", "DYNAMIC", "PLAIN", "CODE", "OPEN"].includes(chaInfoObj.type) ? (
                                                                    <Tag size="sm" color={getColorForType(chaInfoObj.type)}>
                                                                        {chaInfoObj.type}
                                                                    </Tag>
                                                                ) : (
                                                                    <Tag size="sm" color="gray">
                                                                        {i18next.t('unknown')}
                                                                    </Tag>
                                                                )}
                                                            </div>
                                                            <div className="flex-grow">{chaInfoObj.name}</div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.maximum}
                                                            </div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.scoreType === "VARIABLE" ? (
                                                                    "VARIABLE"
                                                                ) : (
                                                                    "FIXED"
                                                                )}
                                                            </div>
                                                            <div className="flex-none w-[8%] flex justify-center">
                                                                <Tag
                                                                    size="sm"
                                                                    color={getColorForScore(chaInfoObj.score)}
                                                                    centerText
                                                                >
                                                                    {chaInfoObj.score}
                                                                </Tag>
                                                            </div>
                                                            <div className="flex-none w-[20%] text-center font-semibold">
                                                                <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                                    <div>
                                                                            <Button
                                                                                color="green"
                                                                                icon={faEye}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleChallengeView(chaInfoObj);
                                                                                }}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )}
                                            {!errorChallengesLoading && (
                                                !challengesQuery.isLoading && challengesQuery.data ? (
                                                    <div className="flex w-full py-2 justify-center gap-x-3">
                                                        <Pager pages={challengesQuery.data.pages} page={challengePage} setPage={setChallengePage} />
                                                    </div>
                                                ) : (
                                                    <div className="flex w-full py-2 justify-center px-4 gap-4">
                                                        <Repeat count={14}>
                                                            <Skeleton
                                                                circle
                                                                count={1}
                                                                baseColor="#e4e4e7"
                                                                highlightColor="#f4f4f5"
                                                                containerClassName="w-9"
                                                                className='h-9'
                                                            />
                                                        </Repeat>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Container>
                                </div>
                                <div className="w-4/12 ml-3">
                                    <div className="mb-5">
                                        <Tag size="md" color="orange" shadow>{i18next.t('selected-challenges')}</Tag>
                                    </div>
                                    {Object.keys(groupedChallenges).map((category) => (
                                        <div key={category} className="mt-3">
                                            <Tag size="md" shadow>{category}</Tag>
                                            {groupedChallenges[category].map((challenge) => (
                                                <div key={challenge.chaGUID} className="flex-row">
                                                    <CardToggle
                                                        border="green"
                                                        size="sm"
                                                        key={challenge.chaGUID}
                                                        title={`${challenge.type} - ${challenge.name}`}
                                                    >
                                                        <div className="flex flex-wrap p-2">
                                                            <div className="flex justify-evenly flex-row gap-5">
                                                                <div className="w-1/2 flex flex-col items-center">
                                                                    <span className="text-center">{i18next.t('score')}</span>
                                                                    <Tag size="sm" color={getColorForScore(challenge.score)}>
                                                                        {challenge.score}
                                                                    </Tag>
                                                                </div>
                                                                <div className="w-1/2 flex flex-col items-center">
                                                                    <span className="text-center">{i18next.t('type')}</span>
                                                                    <Tag size="sm" color={getColorForType(challenge.type)}>
                                                                        {challenge.type}
                                                                    </Tag>
                                                                </div>
                                                            </div>
                                                            <span>{challenge.description}</span>
                                                            <div className="flex mt-2">
                                                                <Button
                                                                    color="red"
                                                                    icon={faBan}
                                                                    onClick={() => handleChallengeRemove(challenge.chaGUID)}
                                                                >
                                                                    {i18next.t('remove')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </CardToggle>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>  
                    )}
                </div>
                <div className="w-full flex gap-5">
                    <Button color="red" icon={faBan} onClick={handleCancel}>
                        {i18next.t('cancel')}
                    </Button>
                    <Button color="green" icon={faPaperPlane} onClick={handleAccept} isLoading={challengesQuery.isLoading}>
                        {i18next.t('save')}
                    </Button>
                </div>
            </div>
            <Spinner isLoading={isSending} message={i18next.t('loading')} type="info" />
            {isRedirecting && (
                <Redirect
                    message={i18next.t('competition-updated-successfully')}
                    redirectTo="/admin/panel"
                    type="success"
                />
            )}
        </NavbarTemplate>
    );
}

export default CompetitionUpdatePage;