/**
 * getRankings gets the
 * competition ranking
 * @method POST
 * @param {number} page
 * @param {number} perPage
 * @param {string} competition
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      pages: number;
 *      personal: {
 *          username: string;
 *          score: number;
 *          date: string;
 *          position: number;
 *      } | null;
 *      scoreboard: {
 *          username: string;
 *          score: number;
 *          date: string;
 *          position: number;
 *      }[];
 * }>}
 */
export default async function getRankings(page, perPage, competition, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${competition}/ranks`;
        const method = 'POST';
        const body = { page, perPage };
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
