import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import Login from '../organisms/Login';
import FormTemplate from '../templates/FormTemplate';

/**
 * Login page shows a form with recaptcha to log in with email/password or Google GSI
 */
function LoginPage() {
    const { session } = useSession();

    // Handle session
    if (session) {
        return <Navigate to="/competitions/private" />;
    }

    return (
        <FormTemplate>
            <Login />
        </FormTemplate>
    );
}

export default LoginPage;
