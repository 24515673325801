/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */

import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import Title from '../components/atoms/Title';

/**
 * Render a dropdown panel
 * @param {{
 *      open: boolean;
 *      gap: number;
 *      title: string;
 *      children: JSX.Element;
 * }} props
 *
 * @returns {JSX.Element} Dropdown
 */
export default function Dropdown({ open = false, gap, title, children }) {
    const [drop, setDrop] = useState(!open);

    return (
        <div className="flex flex-col gap-5">
            <div className="flex gap-5 cursor-pointer" onClick={() => setDrop(!drop)}>
                <div className="my-auto">
                    {drop ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </div>
                <div className="my-auto">
                    <Title size="lg">{title}</Title>
                </div>
            </div>

            {!drop && (
                <div className={classNames('w-full flex flex-col', { [`gap-${gap}`]: (gap && true) || false })}>
                    {children}
                </div>
            )}
        </div>
    );
}
