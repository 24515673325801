import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import activeUsersBanner from '../../images/activeUsersBanner.png';
import privateCompetitionsBanner from '../../images/privateCompetitionsBanner.png';
import publicCompetitionsBanner from '../../images/publicCompetitionsBanner.png';
import rankingsBanner from '../../images/rankingsBanner.png';
import technologiesBanner from '../../images/technologiesBanner.png';
import getStadistics from '../../services/getStadistics';
import Tag from '../atoms/Tag';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

function Home() {
    // Get public stadistics
    const stastQuery = useQuery({
        queryKey: ['stadistics'],
        queryFn: () => getStadistics(i18next.language),
    });

    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    };

    const activeUsersCount = stastQuery.data?.activeUsers || 0;

    return (
        <div className="w-full">
            <Navbar />

            <div className="w-full flex lg:flex-row-reverse flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10">
                <img alt="banner" src={activeUsersBanner} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('active-users-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('active-users-text')}</p>
                    <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                        {stastQuery.isLoading ? (
                            <Skeleton width={150} height={40} className="bg-gray-200" />
                        ) : (
                            <>
                                <p className="text-5xl font-bold">{activeUsersCount.toLocaleString()}</p>
                                <p className="font-semibold my-auto">{i18next.t('active-users-count')}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10 bg-white">
                <img alt="banner" src={rankingsBanner} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('rankings-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('rankings-text')}</p>

                    <div className="divide-y">
                        <h3 className="pb-5 text-lg font-semibold">{i18next.t('rankings-top-five')}</h3>
                        {stastQuery.isLoading || !stastQuery.data ? (
                            [1, 2, 3, 4, 5].map((index) => (
                                <div key={`scoreboard-skeleton-${index}`} className="flex py-2 gap-7 items-center">
                                    <Skeleton circle width={40} height={40} className="bg-gray-200" />
                                    <Skeleton width={150} height={20} className="bg-gray-200" />
                                    <Skeleton width={100} height={20} className="bg-gray-200" />
                                    <Skeleton width={50} height={20} className="bg-gray-200" />
                                </div>
                            ))
                        ) : (
                            stastQuery.data.scoreboard.map((user, index) => (
                                <div key={`scoreboard-${user.username}`} className="flex py-2 gap-7 items-center">
                                    <p className="font-mono font-semibold">{index + 1}</p>
                                    <img
                                        alt={user.username}
                                        src={`https://www.gravatar.com/avatar/${user.gravatar}?d=retro`}
                                        className="h-9 rounded-full"
                                    />
                                    <button
                                        type="submit"
                                        className="w-full font-semibold"
                                        onClick={() => navigateTo(`/profile/details/${user.guid}`)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                navigateTo(`/profile/details/${user.guid}`);
                                            }
                                        }}
                                        role="link"
                                        tabIndex={0}
                                    >
                                        {user.username}
                                    </button>
                                    <p>{user.score} pts</p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            <div className="w-full flex lg:flex-row-reverse flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10">
                <img alt="banner" src={publicCompetitionsBanner} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('public-competitions-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('public-competitions-text')}</p>
                    <div className="flex md:flex-row flex-col gap-10 justify-between md:items-center">
                        <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                            {stastQuery.isLoading ? (
                                <Skeleton width={150} height={40} className="bg-gray-200" />
                            ) : (
                                <p className="text-5xl font-bold">
                                    +{(stastQuery.data?.publicCompetitions || 0).toLocaleString()}
                                </p>
                            )}
                            <p className="font-semibold my-auto">{i18next.t('public-competitions-count')}</p>
                        </div>

                        <Link
                            to="/competitions/public"
                            className="md:min-w-fit md:w-min w-full flex gap-5 py-3 px-10 items-center justify-center bg-orange-700 shadow-lg shadow-orange-700/30 text-white rounded-full select-none"
                        >
                            <FontAwesomeIcon icon={faEye} size="lg" />
                            <p className="font-semibold">{i18next.t('i-want-to-see')}</p>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10 bg-white">
                <img alt="banner" src={privateCompetitionsBanner} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('private-competitions-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('private-competitions-text')}</p>
                    <div className="flex md:flex-row flex-col gap-10 justify-between md:items-center">
                        <div className="flex md:flex-row flex-col-reverse md:gap-7 gap-3">
                            {stastQuery.isLoading ? (
                                <Skeleton width={150} height={40} className="bg-gray-200" />
                            ) : (
                                <p className="text-5xl font-bold">
                                    +{(stastQuery.data?.privateCompetitions || 0).toLocaleString()}
                                </p>
                            )}
                            <p className="font-semibold my-auto">{i18next.t('private-competitions-count')}</p>
                        </div>

                        <Link
                            to="https://job-seekers.smartalentit.com/jobs/Careers"
                            className="md:min-w-fit md:w-min w-full flex gap-5 py-3 px-10 items-center justify-center bg-green-700 shadow-lg shadow-green-700/30 text-white rounded-full select-none"
                        >
                            <FontAwesomeIcon icon={faEye} size="lg" />
                            <p className="font-semibold">{i18next.t('search-for-jobs')}</p>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="w-full flex lg:flex-row-reverse flex-col items-center xl:px-48 md:px-10 px-5 py-10 gap-10">
                <img alt="banner" src={technologiesBanner} width={400} className="select-none" />
                <div className="flex flex-col md:gap-7 gap-5">
                    <h1 className="md:text-3xl text-xl font-bold">{i18next.t('technologies-title')}</h1>
                    <p className="md:text-base text-sm">{i18next.t('technologies-text')}</p>
                    <div className="flex flex-wrap gap-3">
                    {stastQuery.isLoading ? (
                        <>
                            <Skeleton count={2} width={200} height={30} className="m-1 bg-gray-200 rounded-2xl" />
                            <Skeleton count={2} width={200} height={30} className="m-1 bg-gray-200 rounded-2xl" />
                            <Skeleton count={2} width={200} height={30} className="m-1 bg-gray-200 rounded-2xl" />
                            <Skeleton count={2} width={200} height={30} className="m-1 bg-gray-200 rounded-2xl" />
                        </>
                    ) : (
                        stastQuery.data?.technologies.map((tech) => (
                            <Tag key={`tech:${tech}`} size="sm">
                                {tech}
                            </Tag>
                        ))
                    )}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;
