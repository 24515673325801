import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import useDisplay from '../../hooks/useDisplay';

/**
 * Image atom creates a maximizable image with a loading state
 * @param {{
 *      isLoading: boolean;
 *      alt: string | undefined;
 *      src: string | undefined;
 * }} props
 */
function Image({ isLoading, alt, src }) {
    const { targetRef, display, setDisplay } = useDisplay({ displayed: false });

    // Enable/Disable scroll
    useEffect(() => {
        document.body.style.overflow = display ? 'hidden' : 'auto';
    }, [display]);

    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                borderRadius={6}
                containerClassName="w-full h-full"
                className="aspect-video"
            />
        );
    }

    return (
        <>
            <button
                type="button"
                onClick={() => setDisplay(true)}
                className="relative rounded-md shadow-md overflow-hidden aspect-video select-none outline-none"
            >
                <img alt={alt} src={src} className="absolute top-0 w-full" />
            </button>

            {display && (
                <div className="fixed z-50 top-0 left-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-75 select-none">
                    <img ref={targetRef} alt={alt} src={src} className="max-w-full max-h-[90vh] shadow-md" />
                </div>
            )}
        </>
    );
}

Image.propTypes = {
    isLoading: PropTypes.bool,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

Image.defaultProps = {
    isLoading: false,
    alt: undefined,
};

export default Image;
