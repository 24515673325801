import { createBrowserRouter } from 'react-router-dom';
import ActivateAccountPage from '../components/pages/ActivateAccountPage';
import AutoLoginPage from '../components/pages/AutoLoginPage';
import ChallengePage from '../components/pages/ChallengePage';
import CompetitionsPage from '../components/pages/CompetitionsPage';
import ErrorPage from '../components/pages/ErrorPage';
import Home from '../components/pages/Home';
import LoginPage from '../components/pages/LoginPage';
import NotFoundPage from '../components/pages/NotFoundPage';
import ProfilePage from '../components/pages/ProfilePage';
import RecoverAccountPage from '../components/pages/RecoverAccountPage';
import RegisterPage from '../components/pages/RegisterPage';
import ResetPasswordPage from '../components/pages/ResetPasswordPage';

import RecruiterPage from '../components/pages/RecruiterPage';
import UserProfilePage from '../components/pages/UserProfilePage';
import ControlPanel from '../components/pages/ControlPanel';
import CompetitionCreatePage from '../components/pages/CompetitionCreatePage';
import CompetitionUpdatePage from '../components/pages/CompetitionUpdatePage';
import Competition from '../v1/Competition'; // ! Remove on finish v2

export default createBrowserRouter([
    {
        path: '*',
        element: <NotFoundPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-in',
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-in/auto/:user/competitions/:competition',
        element: <AutoLoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/sign-up',
        element: <RegisterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/activate/:user',
        element: <ActivateAccountPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/recover',
        element: <RecoverAccountPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/auth/recover/:user',
        element: <ResetPasswordPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility',
        element: <CompetitionsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/create',
        element: <CompetitionCreatePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/update/:competition',
        element: <CompetitionUpdatePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility/:competition',
        element: <Competition />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/competitions/:visibility/:competition/challenges/:challenge',
        element: <ChallengePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/profile',
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/profile/:competition',
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/profile/details/:guid',
        element: <UserProfilePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/recruiter',
        element: <RecruiterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/admin/panel',
        element: <ControlPanel />,
        errorElement: <ErrorPage />,
    },
]);
