import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Spinner from '../atoms/Spinner';

function Redirect({ message, redirectTo, type }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
            navigate(redirectTo);
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate, redirectTo]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <Spinner isLoading={isLoading} message={message} type={type} />
        </div>
    );
}

Redirect.propTypes = {
    message: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['warn', 'success']).isRequired,
};

export default Redirect;