/**
 * createAdminUser creates a new User.
 * @method POST
 * @param {string} email
 * @param {string} password
 * @param {string|null} country
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} sex
 * @param {string|null} birthday
 * @param {string|null} website
 * @param {string|null} biography
 * @param {string|null} phone
 * @param {string|null} twitter
 * @param {string|null} facebook
 * @param {string|null} linkedin
 * @param {string|null} github
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function createAdminUser(email, password, country, firstName, lastName, sex, birthday,
    website, biography, phone, twitter, facebook, linkedin, github, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/panel/talent`;
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
            Authorization: jwt,
        };

            const user = {
                email,
                password,
                country,
                role: 'talent',
                firstName,
                lastName,
                sex,
                birthday,
                website,
                biography,
                phone,
                twitter,
                facebook,
                linkedin,
                github
            };

        const body = JSON.stringify({ user });

        const response = await fetch(url, { method, headers, body });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
