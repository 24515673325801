import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * useDisplay hook is an event system applied to a target to hide/show it
 * @param {{ displayed: boolean; }} props
 * @returns {{
 *      targetRef: React.RefObject<React.ReactNode>;
 *      display: boolean;
 *      setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
 * }}
 */
function useDisplay({ displayed }) {
    const [display, setDisplay] = useState(displayed);
    const targetRef = useRef();

    /**
     * Hide the target when clicked outside of it
     * @param {MouseEvent} event
     */
    const handleClickOutside = useCallback((event) => {
        if (targetRef.current && !targetRef.current.contains(event.target)) {
            setDisplay(false);
        }
    }, []);

    /**
     * Hide the target when the ESC key is pressed
     * @param {KeyboardEvent} event
     */
    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            setDisplay(false);
        }
    }, []);

    // Add event listeners
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keyup', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [handleClickOutside, handleKeyPress]);

    return { targetRef, display, setDisplay };
}

useDisplay.propTypes = {
    displayed: PropTypes.bool,
};

useDisplay.defaultProps = {
    displayed: false,
};

export default useDisplay;
