/**
 * autoLogin creates a session for an unregistered user
 * @method GET
 * @param {string} user
 * @param {string} language
 * @returns {Promise<{
 *      jwt: string;
 *      pending: boolean;
 * }>}
 */
export default async function autoLogin(user, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/auto-login/${user}`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Headers, Access-Control-Allow-Credentials, Content-Type, Accept, Authorization, Accept-Language, accept-language',
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
