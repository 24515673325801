import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const recaptchaID = 'recaptcha';
let initialized = false;

/**
 * useRecaptcha hook initializes the invisible recaptcha in the calling component
 * @param {{ mode: 'login' | 'password_reset'; }} props
 * @returns {() => Promise<string>} Recaptcha runner
 */
function useRecaptcha({ mode }) {
    const recaptchaRef = useRef();

    // Initialize the recaptcha if it hasn't been done already
    useEffect(() => {
        window.grecaptcha?.enterprise?.ready(async () => {
            if (!initialized) {
                const recaptcha = document.createElement('div');
                recaptcha.id = recaptchaID;
                document.body.appendChild(recaptcha);

                recaptchaRef.current = window.grecaptcha?.enterprise?.render(recaptchaID, {
                    sitekey: process.env.REACT_APP_RECAPTCHA_ID,
                    action: mode,
                    size: 'invisible',
                    hl: i18next.language,
                });

                initialized = true;
            }
        });

        // Cleanup the recaptcha when the component is unmounted
        return () => {
            if (initialized) {
                document.getElementById(recaptchaID).remove();
                recaptchaRef.current = undefined;
                initialized = false;
            }
        };
    }, [mode]);

    /**
     * Run the recaptcha to get the evaluation token
     * @returns {Promise<string>} token
     */
    const recaptcha = () => {
        const widget = recaptchaRef.current;
        return window.grecaptcha?.enterprise?.execute(widget);
    };

    return recaptcha;
}

useRecaptcha.propTypes = {
    mode: PropTypes.oneOf(['login', 'password_reset']).isRequired,
};

export default useRecaptcha;
