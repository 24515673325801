import { faBan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * BanDetailModal allows the user to view the details of a ban
 * @param {{
 *      username: string;
 *      firstName: string;
 *      lastName: string;
 *      email: string;
 *      reason: string;
 *      startDate: string;
 *      endDate: string | undefined;
 *      comment: string | undefined;
 *      onCancel: () => void;
 * }} props
 */
function BanDetailModal({ username, firstName, lastName, email, reason, startDate, endDate, comment, onCancel }) {
    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex justify-center py-3">
                <FontAwesomeIcon icon={faBan} size="5x" className="text-smred-dark" />
            </div>
            <p className="text-center">
                <Title size="md">{i18next.t('ban-details')}</Title>
                <strong>{username}</strong>
            </p>
            <div className="w-full p-5 flex flex-col gap-5">
                <div className="flex flex-wrap">
                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6  items-center">
                            <Title color="blue" size="md">{i18next.t('name')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{firstName} {lastName}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6  items-center">
                            <Title color="green" size="md">{i18next.t('email')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{email}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6 items-center">
                            <Title color="yellow" size="md">{i18next.t('ban-reason')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{reason}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6  items-center">
                            <Title color="gray" size="sm">{i18next.t('start-date')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{startDate}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6">
                            <Title color="red" size="sm">{i18next.t('end-date')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{endDate || i18next.t('unlimited')}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6">
                            <Title color="gray" size="sm">{i18next.t('description')}</Title>
                        </div>
                        <div className="w-4/6">
                            <span>{comment || i18next.t('description-not-found')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faXmark} onClick={onCancel}>
                    { i18next.t('close') }
                </Button>
            </div>
        </div>
    );
}

BanDetailModal.propTypes = {
    username: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    comment: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
};

BanDetailModal.defaultProps = {
    firstName: '',
    lastName: '',
    endDate: '',
    comment: '',
};

export default BanDetailModal;