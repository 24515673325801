/**
 * googleRegister creates an user using Google
 * @method PUT
 * @param {string} googleToken
 * @param {string} recaptchaToken
 * @param {string} language
 * @returns {Promise<{ jwt: string; }>}
 */
export default async function googleRegister(googleToken, recaptchaToken, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/sso`;
        const method = 'PUT';
        const body = { googleToken, recaptchaToken };
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
        };

        const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
