/**
 * getCategories gets all the categories of Smart Ranks
 * @method GET
 * @param {string} language
 * @returns {Promise<{
 *      categories:{
 *          id: number;
 *          name: string;
 *      }[];
 * }>}
 */
export default async function getCategories(language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/challenges/categories`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
