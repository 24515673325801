// UserViewModal.jsx

import { faCircle, faClose } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';

/**
 * UserViewModal allows the user to view an specific user and its details.
 * @param {{
 *      data: object;
 *      onCancel: () => void;
 * }} props
 */
function UserViewModal({
    data,
    onCancel
}) { 

    const sexTranslations = {
        _MALE: i18next.t('male'),
        _FEMALE: i18next.t('female'),
        _UNKNOWN: i18next.t('other'),
    };  
    
    const banTranslations = {
        SPAM: "SPAM",
        MISBEHAVIOR: i18next.t('misbehavior'),
        CHEATING: i18next.t('cheating'),
        DUPLICATED: i18next.t('duplicated'),
        UNKNOWN: i18next.t('unknown'),
    };

    return (
        <div>
            <div className="w-full p-5 flex flex-col gap-5">
                <div className="flex items-center">
                    {(() => {
                        switch (data.status) {
                            case "ACTIVE":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('active')}>
                                        <FontAwesomeIcon color="green" icon={faCircle} />
                                    </div>
                                );
                            case "INACTIVE":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('inactive')}>
                                        <FontAwesomeIcon color="orange" icon={faCircle} />
                                    </div>
                                );
                            case "DELETED":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('deleted')}>
                                        <FontAwesomeIcon color="red" icon={faCircle} />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })()}
                    <div className="flex w-4/12 justify-center truncate" title={i18next.t('role')}>
                        <div className="w-fit">
                            <Tag size="lg">
                                {data.roles[0]}
                            </Tag>
                        </div>
                    </div>
                    <div className="w-7/12 text-center truncate" title={i18next.t('username')}>
                        {data.banned !== null ? (
                            <Banner borderColor="green">
                                <span style={{ textDecoration: 'line-through' }}>{data.username}</span>
                            </Banner>
                        ) : (
                            <Banner borderColor="green">{data.username}</Banner>
                        )}
                    </div>
                </div>
                {data.banned !== null && (
                    <div className="w-full flex flex-col items-center text-center p-2 border-2 border-smred-dark gap-2">
                        <div className="w-1/3">
                            <Tag size="md">{i18next.t('user-banned-message')}</Tag>
                        </div>
                        <Title size="sm">{i18next.t('ban-reason')}</Title>
                        <p>{banTranslations[data.banned.reason]}</p>
                        <p>{data.banned.startDate} / {data.banned.endDate || i18next.t('unlimited')}</p>
                        {data.banned !== null && (
                            <div>
                                <Title size="sm">{i18next.t('description')}</Title>
                                <p>{data.banned.comment}</p>
                            </div>
                        )} 
                    </div>
                )}
                <div className="flex flex-wrap">
                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                        <div className="w-2/6">
                            <Tag size="sm">{i18next.t('email')}</Tag>
                        </div>
                        <div className="w-4/6">
                            <span>{data.email}</span>
                        </div>
                    </div>
                    {data.firstName && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('name')}</Tag>
                            </div>
                            <div className="w-4/6">
                                <span>{data.firstName ? data.firstName : ''} {data.lastName ? data.lastName : ''}</span>
                            </div>
                        </div>
                    )}
                    {data.biography && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('biography')}</Tag>
                            </div>
                            <div className="w-4/6">
                                {data.biography}
                            </div>
                        </div>
                    )}
                    {data.sex && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('gender')}</Tag>
                            </div>
                            <div className="w-4/6">
                                {sexTranslations[data.sex] || data.sex}
                            </div>
                        </div>
                    )}
                    {data.birthday && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('birthday')}</Tag>
                            </div>
                            <div className="w-4/6">
                                {data.birthday}
                            </div>
                        </div>
                    )}
                    {data.origin && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('origin')}</Tag>
                            </div>
                            <div className="w-4/6">
                                {data.origin}
                            </div>
                        </div>
                    )}
                    {data.web && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('web')}</Tag>
                            </div>
                            <div className="w-4/6">
                                {data.web}
                            </div>
                        </div>
                    )}
                    {data.linkedin && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('linkedin')}</Tag>
                            </div>
                            <div className="w-4/6">
                                <FontAwesomeIcon color="blue" icon={faLinkedin} size="lg" />
                                <span className="ml-2">{data.linkedin}</span>
                            </div>
                        </div>
                    )}
                    {data.github && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('github')}</Tag>
                            </div>
                            <div className="w-4/6">
                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                <span className="ml-2">{data.github}</span>
                            </div>
                        </div>
                    )}
                    {data.twitter && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('twitter-username')}</Tag>
                            </div>
                            <div className="w-4/6 truncate">
                                <FontAwesomeIcon icon={faXTwitter} size="lg" />
                                <span className="ml-2">{data.twitter}</span>
                            </div>
                        </div>
                    )}
                    {data.facebook && (
                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                            <div className="w-2/6">
                                <Tag size="sm">{i18next.t('facebook-username')}</Tag>
                            </div>
                            <div className="w-4/6">
                                <FontAwesomeIcon color="blue" icon={faFacebook} size="lg" />
                                <span className="ml-2">{data.facebook}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-center" title={i18next.t('close')}>
                <div className="w-1/3 m-3">
                    <Button color="red" icon={faClose} onClick={onCancel}>
                        {i18next.t('close')}
                    </Button>
                </div>
            </div>
        </div>
        
    );
}

UserViewModal.propTypes = {
    data: PropTypes.shape().isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserViewModal;