import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';

function Markdown({ children }) {
    // Replace the <pre><code> with their highlighted content
    const handleHighlight = ({ children: child }) => {
        const language = child.props.className?.replace('language-', '');
        return (
            <SyntaxHighlighter
                language={language}
                style={atomOneLight}
                customStyle={{ backgroundColor: '#f4f4f5', borderRadius: '6px' }}
                showLineNumbers
                lineNumberStyle={{ color: '#a1a1aa' }}
            >
                {String(child.props.children).slice(0, -1)}
            </SyntaxHighlighter>
        );
    };

    return (
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ pre: handleHighlight }} className="prose">
            {children.replace('@markdown', '').trim()}
        </ReactMarkdown>
    );
}

Markdown.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Markdown;
