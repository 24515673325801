import {
    faAngleRight,
    faBars,
    faEye,
    faPowerOff,
    faRightToBracket,
    faUserPlus,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import logoSR from '../../images/logoSR.png';
import getStadistics from '../../services/getStadistics';
import getUser from '../../services/getUser';
import Button from '../atoms/Button';
import Language from '../atoms/Language';

/**
 * Navbar
 * @param {{
 *      color: 'purple' | 'green' | 'orange';
 *      routes: {
 *          isLoading: boolean | undefined;
 *          link: string | undefined;
 *          text: string;
 *      }[];
 * }} props
 */
function Navbar({ color, routes }) {
    const location = useLocation();
    const { visibility } = useParams();
    const { session, removeSession } = useSession();
    const [display, setDisplay] = useState(false);
    const navigate = useNavigate();

    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    // Get public stadistics
    const statsQuery = useQuery({
        queryKey: ['stadistics'],
        queryFn: () => getStadistics(i18next.language),
        refetchInterval: 60000,
    });

    // Build profile
    const profileElement = userQuery.data ? (
        <div className="flex gap-5 items-center">
            <NavLink to="/profile" title={i18next.t('your-profile')} className="text-white font-semibold pl-2">
                {userQuery.data.username}
            </NavLink>

            <Button
                color="white"
                circle
                icon={faPowerOff}
                onClick={() => {
                    removeSession();
                    navigate('/');
                }}
            />
        </div>
    ) : (
        <div className="flex gap-3 items-center">
            <p className="text-sm text-white">{i18next.t('sign-in')}</p>
            <Button color="white" circle icon={faRightToBracket} onClick={() => navigate('/auth/sign-in')} />
            <p className="text-sm pl-1 text-white">{i18next.t('sign-up')}</p>
            <Button color="white" circle icon={faUserPlus} onClick={() => navigate('/auth/sign-up')} />
        </div>
    );

    // Build links
    const linksElements = [];
    let i = -1;

    // Talent menu
    if (userQuery.data?.roles.includes('talent')) {
        i += 1;
        // To public competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/public"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'public' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'public' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('public-competitions')}</p>
            </NavLink>,
        );
        i += 1;
        // To private competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/private"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'private' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'private' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('private-competitions')}</p>
            </NavLink>,
        );
        i += 1;
        // To finished competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/history"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'history' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'history' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('finished-competitions')}</p>
            </NavLink>,
        );
    }

    if (!userQuery.data) {
        i += 1;
        // To public competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/public"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'public' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'public' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('public-competitions')}</p>
            </NavLink>,
        );
    }

    const isAdminPanelRoute = location.pathname === '/admin/panel';

    // Admin menu
    if (userQuery.data?.roles.includes('admin')) {
        i += 1;
        // To public competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/public"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'public' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'public' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('public-competitions')}</p>
            </NavLink>,
        );
        i += 1;
        // To private competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/private"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'private' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'private' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('private-competitions')}</p>
            </NavLink>,
        );
        i += 1;
        // To finished competitions
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/competitions/history"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'history' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'history' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('finished-competitions')}</p>
            </NavLink>,
        );
        i += 1;
        // To admin panel
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/admin/panel"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': color === 'green' && isAdminPanelRoute,
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': color === 'purple' && isAdminPanelRoute,
                })}
            >
                <p className="text-sm text-white">{i18next.t('control-panel')}</p>
            </NavLink>,
        );
    }

    // Production menu
    if (userQuery.data?.roles.includes('production')) {
        i += 1;
        // To recruiter searching
        linksElements.push(
            <NavLink
                key={`navlink:${i}`}
                to="/recruiter"
                className={classNames('min-w-fit w-min', {
                    'px-5 py-2 rounded-full bg-srorange-darker': visibility === 'public' && color === 'green',
                    'px-5 py-2 rounded-full bg-[#1b0a1b]': visibility === 'public' && color === 'purple',
                })}
            >
                <p className="text-sm text-white">{i18next.t('recruiter-view')}</p>
            </NavLink>,
        );
    }

    return (
        <div className="w-full shadow-md select-none">
            <div
                className={classNames('w-full divide-y', {
                    'bg-srorange-dark divide-srorange-darker': color === 'green',
                    'bg-[#381538] divide-[#1b0a1b]': color === 'purple',
                })}
            >
                <div className="w-full lg:h-[75px] h-16 lg:px-10 px-5 flex sm:gap-10 gap-5 items-center">
                    <div className="w-full flex gap-10 items-center">
                        <NavLink to="/" className="min-w-fit lg:mr-0 mr-auto">
                            <img alt="Smart Logo" src={logoSR} className="h-28" />
                        </NavLink>
                        <div className="w-full lg:flex hidden gap-5 items-center">{linksElements}</div>
                    </div>

                    <div className="min-w-fit w-min flex gap-5 items-center">
                        <div
                            className="min-w-fit lg:mr-0 mr-auto flex items-center"
                            title={i18next.t('active-session')}
                        >
                            <FontAwesomeIcon icon={faEye} size="lg" color="white" />
                            <p className="font-semibold text-base text-white pl-2">
                                {statsQuery.data?.activeSessions.toLocaleString()}
                            </p>
                        </div>

                        <Language color="white" />
                    </div>

                    <div className="min-w-fit lg:block hidden">{profileElement}</div>

                    <button
                        type="button"
                        onClick={() => setDisplay(!display)}
                        className="text-white outline-none select-none lg:hidden block"
                    >
                        <FontAwesomeIcon icon={display ? faXmark : faBars} size="lg" className="aspect-square" />
                    </button>
                </div>

                {display && (
                    <div
                        className={classNames('w-full flex flex-col lg:hidden divide-y', {
                            'divide-smgreen-darker': color === 'green',
                            'divide-[#1b0a1b]': color === 'purple',
                        })}
                    >
                        <div className="w-full p-5 grid grid-cols-2 gap-2 items-center">{linksElements}</div>
                        <div className="w-full h-16 px-5 flex justify-end">{profileElement}</div>
                    </div>
                )}
            </div>

            {routes && (
                <div
                    className={classNames(
                        'w-full flex lg:px-10 px-5 py-3 gap-3 text-xs text-white items-center overflow-x-auto scrollbar-hidden',
                        {
                            'bg-srorange-darker': color === 'green',
                            'bg-[#1b0a1b]': color === 'purple',
                        },
                    )}
                >
                    {routes.map((route, index) => {
                        let content;
                        if (route.isLoading) {
                            content = (
                                <Skeleton baseColor="#ff5b05" highlightColor="#ffff" borderRadius={9999} width={100} />
                            );
                        } else if (route.link) {
                            content = (
                                <NavLink to={route.link} className="min-w-fit w-min hover:underline">
                                    {route.text}
                                </NavLink>
                            );
                        } else {
                            content = <p className="min-w-fit w-min">{route.text}</p>;
                        }

                        return (
                            <Fragment key={`route:${route.text + index.toString()} $`}>
                                {content}
                                <FontAwesomeIcon icon={faAngleRight} className="last:hidden" />
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

Navbar.propTypes = {
    color: PropTypes.oneOf(['purple', 'green', 'orange']),
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            isLoading: PropTypes.bool,
            link: PropTypes.string,
            text: PropTypes.string.isRequired,
        }),
    ),
};

Navbar.defaultProps = {
    color: 'green',
    routes: undefined,
};

export default Navbar;
