import PropTypes from 'prop-types';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

/**
 * Form template positions any content in the center of the screen
 * @param {{ children: React.ReactNode }} props
 */
function FormTemplate({ children }) {
    return (
        <>
            <div className="min-h-screen flex flex-col">
                <Navbar />

                <div className="max-w-[400px] w-full m-auto flex flex-col gap-2">{children}</div>
            </div>

            <Footer />
        </>
    );
}

FormTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FormTemplate;
