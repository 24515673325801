import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import getRankings from '../../services/getRankings';
import getUser from '../../services/getUser';
import formatDate from '../../utils/formatDate';
import Container from '../atoms/Container';
import Repeat from '../atoms/Repeat';
import Title from '../atoms/Title';
import Pager from '../molecules/Pager';

function Ranking({ competition }) {
    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    };
    const { session } = useSession();
    const [page, setPage] = useState(0);

    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    // Get rank list
    const ranksPerPage = parseInt(process.env.REACT_APP_RANKS_PER_PAGE, 10);
    const rankingsQuery = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [session, 'competitions', competition, 'ranks', page],
        queryFn: () => getRankings(page, ranksPerPage, competition, session, i18next.language),
        enabled: !!session,
    });

    if (!rankingsQuery.isLoading && !rankingsQuery.data) {
        return null;
    }

    return (
        <div className="w-full flex flex-col gap-5">
            <Title size="xl">{i18next.t('scoreboard')}</Title>

            <Container>
                <div className="w-full divide-y">
                    <div className="w-full flex p-4 gap-4">
                        <div className="w-16 text-center font-semibold flex-shrink-0">{i18next.t('top')}</div>
                        <div className="w-full font-semibold">{i18next.t('user')}</div>
                        <div className="w-28 text-center font-semibold flex-shrink-0">{i18next.t('score')}</div>
                        <div className="w-44 text-center font-semibold flex-shrink-0">{i18next.t('date')}</div>
                    </div>

                    {rankingsQuery.isLoading ? (
                        <Repeat count={ranksPerPage}>
                            <div className="w-full flex p-4 gap-4">
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-16 h-5 flex-shrink-0"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-full h-5"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-28 h-5 flex-shrink-0"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-44 h-5 flex-shrink-0"
                                />
                            </div>
                        </Repeat>
                    ) : (
                        rankingsQuery.data.scoreboard.map(({ position, username, score, date, guid }) => (
                            <div key={`rank:${username}`} className="w-full flex p-4 gap-4">
                                <div className="w-16 text-center font-semibold flex-shrink-0">
                                    {position > 3 ? (
                                        <p className="font-semibold">{position}</p>
                                    ) : (
                                        <FontAwesomeIcon
                                            size="xl"
                                            icon={faMedal}
                                            className={classNames({
                                                'text-yellow-400': position === 1,
                                                'text-slate-400': position === 2,
                                                'text-amber-700': position === 3,
                                            })}
                                        />
                                    )}
                                </div>
                                <div className="w-full flex gap-2 items-center">
                                    <button
                                        type="submit"
                                        className="w-full"
                                        onClick={() => navigateTo(`/profile/details/${guid}`)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                navigateTo(`/profile/details/${guid}`);
                                            }
                                        }}
                                        role="link"
                                        tabIndex={0}
                                    >
                                        <p>{username}</p>
                                        {username === userQuery.data?.username && (
                                            <div className="w-min bg-smgreen-dark text-white text-xs rounded-full px-3">
                                                {i18next.t('you')}
                                            </div>
                                        )}
                                        {/* {userQuery.data.username} */}
                                    </button>
                                </div>
                                <div className="w-28 text-center flex-shrink-0">{score} pts</div>
                                <div className="w-44 text-center flex-shrink-0">{formatDate(date)}</div>
                            </div>
                        ))
                    )}

                    {rankingsQuery.data?.personal && (
                        <>
                            <div className="p-1" />
                            <div className="w-full flex p-4 gap-4">
                                <div className="w-16 text-center font-semibold flex-shrink-0">
                                    {rankingsQuery.data.personal.position}
                                </div>
                                <div className="w-full flex gap-2 items-center">
                                    <p>{rankingsQuery.data.personal.username}</p>
                                    <div className="w-min bg-smgreen-dark text-white text-xs rounded-full px-3">
                                        {i18next.t('you')}
                                    </div>
                                </div>
                                <div className="w-28 text-center flex-shrink-0">
                                    {rankingsQuery.data.personal.score} pts
                                </div>
                                <div className="w-44 text-center flex-shrink-0">
                                    {formatDate(rankingsQuery.data.personal.date)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Container>

            {rankingsQuery.data && (
                <div className="w-full flex justify-center">
                    <Pager pages={rankingsQuery.data.pages} page={page} setPage={setPage} />
                </div>
            )}
        </div>
    );
}

Ranking.propTypes = {
    competition: PropTypes.string.isRequired,
};

export default Ranking;
