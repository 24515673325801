import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Button from '../atoms/Button';
import Feedback from '../atoms/Feedback';

const roleOptions = {
    Roles: {
        talent: 'Talent',
        company: 'Company',
        admin: 'Admin',
        production: 'Production',
    },
};

const statusOptions = {
    Status: {
        ACTIVE: 'Active',
        PENDING: 'Pending',
        INACTIVE: 'Inactive',
        DISABLED: 'Disabled',
        DELETED: 'Deleted',
        ACTIVE_PASSWORDLESS: 'Active Passwordless',
    },
};

/**
 * UserAdminSearch component handles the search functionality for users
 * @param {{
 *      isLoading: boolean;
 *      onSearch: (criteria: { email: string, username: string, firstName: string, lastName: string, role: string, status: string }) => void;
 *      feedbackMessage: { type: string, message: string } | null;
 * }} props
 */
function UserAdminSearch({ isLoading, onSearch, feedbackMessage }) {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('');
    const [showClearButton, setShowClearButton] = useState(false);

    useEffect(() => {
        const hasContent = email || username || firstName || lastName || role || status;
        setShowClearButton(hasContent);
    }, [email, username, firstName, lastName, role, status]);

    const handleSearch = () => {
        onSearch({ email, username, firstName, lastName, role, status });
    };

    const handleClear = () => {
        setEmail('');
        setUsername('');
        setFirstName('');
        setLastName('');
        setRole('');
        setStatus('');
        onSearch({ email: '', username: '', firstName: '', lastName: '', role: '', status: '' });
    };

    return (
        <div className="bg-white p-4 rounded-md shadow-md space-y-4 sm:space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onType={setEmail}
                    color="white"
                    focus
                />
                <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onType={setUsername}
                    color="white"
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onType={setFirstName}
                    color="white"
                />
                <Input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onType={setLastName}
                    color="white"
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Select
                    placeholder="Select Role"
                    options={roleOptions}
                    selected={role}
                    onSelect={setRole}
                    color="white"
                />
                <Select
                    placeholder="Select Status"
                    options={statusOptions}
                    selected={status}
                    onSelect={setStatus}
                    color="white"
                />
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex justify-around space-x-4">
                    <Button
                        isLoading={isLoading}
                        color="blue"
                        onClick={handleSearch}
                        title={i18next.t('search')}
                        icon={faSearch}
                    >
                        {i18next.t('search')}
                    </Button>
                    {showClearButton && (
                        <Button
                            isLoading={isLoading}
                            color="red"
                            onClick={handleClear}
                            title={i18next.t('clear')}
                            icon={faEraser}
                        >
                            {i18next.t('clear')}
                        </Button>
                    )}
                </div>
            </div>
            {feedbackMessage && (
                <Feedback type={feedbackMessage.type}>
                    {feedbackMessage.message}
                </Feedback>
            )}
        </div>
    );
}

UserAdminSearch.propTypes = {
    isLoading: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    feedbackMessage: PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }),
};

UserAdminSearch.defaultProps = {
    isLoading: false,
    feedbackMessage: null,
};

export default UserAdminSearch;