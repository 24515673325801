// EducationUpdateModal.jsx

import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * EducationUpdateModal allows the user to update a education
 * @param {{
 *      institutionNameRef: React.RefObject<HTMLInputElement>;
 *      degreeRef: React.RefObject<HTMLInputElement>;
 *      areaRef: React.RefObject<HTMLInputElement>;
 *      startDateRef: React.RefObject<HTMLInputElement>;
 *      endDateRef: React.RefObject<HTMLInputElement>;
 *      descriptionRef: React.RefObject<HTMLTextAreaElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function EducationUpdateModal({ 
    institutionNameRef,
    degreeRef,
    areaRef, 
    startDateRef, 
    endDateRef, 
    descriptionRef,
    educationInfoObj,
    onAccept, 
    onCancel 
}) { 

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const validate = () => {

        const validationErrors = [];

        if (!institutionNameRef.current.value) {
            validationErrors.push(i18next.t('education-institution'));
        }
        if (!degreeRef.current.value) {
            validationErrors.push(i18next.t('education-degree'));
        }
        if (!areaRef.current.value) {
            validationErrors.push(i18next.t('education-area'));
        }
        if (!startDateRef.current.value) {
            validationErrors.push(i18next.t('start-date'));
        }

        if (institutionNameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-institution'));
            return false;
        }

        if (degreeRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-degree'));
            return false;
        }

        if (areaRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-area'));
            return false;
        }

        if (descriptionRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
            <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('education-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('education-institution')}</Title>
                <Input
                    inputRef={institutionNameRef}
                    color="gray"
                    type="text"
                    defaultValue={educationInfoObj.institutionName}
                    placeholder={i18next.t('education-ex-institution')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('education-degree')}</Title>
                <Input
                    inputRef={degreeRef}
                    color="gray"
                    type="text"
                    defaultValue={educationInfoObj.degree}
                    placeholder={i18next.t('education-ex-degree')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('education-area')}</Title>
                <Input
                    inputRef={areaRef}
                    color="gray"
                    type="text"
                    defaultValue={educationInfoObj.area}
                    placeholder={i18next.t('education-ex-area')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('start-date')}</Title>
                <Input
                    inputRef={startDateRef}
                    color="gray"
                    type="month"
                    defaultValue={educationInfoObj.startDate}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('end-date')}</Title>
                <Input
                    inputRef={endDateRef}
                    color="gray"
                    type="month"
                    defaultValue={educationInfoObj.endDate}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <Input
                    inputRef={descriptionRef}
                    color="gray"
                    type="textarea"
                    defaultValue={educationInfoObj.description}
                    placeholder={i18next.t('education-ex-description')}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

EducationUpdateModal.propTypes = {
    institutionNameRef: PropTypes.shape().isRequired,
    degreeRef: PropTypes.shape().isRequired,
    areaRef: PropTypes.shape().isRequired,
    startDateRef: PropTypes.shape().isRequired,
    endDateRef: PropTypes.shape().isRequired,
    descriptionRef: PropTypes.shape().isRequired,
    educationInfoObj: PropTypes.shape({
        educationID: PropTypes.string.isRequired,
        institutionName: PropTypes.string.isRequired,
        degree: PropTypes.string.isRequired,
        area: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EducationUpdateModal;