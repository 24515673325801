import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**
 * Language atom creates a button to change the language of the app
 * @param {{ color: 'white' | 'black'; }} props
 */
function Language({ color }) {
    const [language, setLanguage] = useState('en');

    // Load languaje
    useEffect(() => {
        if (i18next.language) {
            setLanguage(i18next.language.split('_')[0]);
        }
    }, []);

    /**
     * Change the language between English and Spanish
     */
    const handleChangeLanguage = () => {
        const newLanguage = i18next.language === 'en_US' ? 'es_ES' : 'en_US';
        i18next.changeLanguage(newLanguage).then(() => {
            localStorage.setItem('language', newLanguage);
            window.location.reload();
        });
    };

    return (
        <button
            type="button"
            onClick={handleChangeLanguage}
            title={i18next.t('change-language')}
            className={classNames(
                'flex gap-2 items-center select-none',
                color === 'white' ? 'text-white' : 'text-black',
            )}
        >
            <FontAwesomeIcon icon={faGlobeAmericas} size="lg" />
            <p className="uppercase font-mono font-semibold">{language}</p>
        </button>
    );
}

Language.propTypes = {
    color: PropTypes.oneOf(['white', 'black']).isRequired,
};

export default Language;
