import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * SkeletonModal allows the user to see a loading state
 * @param {{
 * }} props
 */
function SkeletonModal() {
    return (
        <div className="w-full h-screen p-5 flex flex-col gap-10 justify-center items-center">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" className="text-smgreen-dark animate-spin" />
        </div>
    );
}

SkeletonModal.propTypes = {};

export default SkeletonModal;
