import i18next from 'i18next';

/**
 * formatTime converts a duration in seconds to readable text divided into sets
 * @param {number} seconds
 * @param {number} sets
 * @returns {string} Formated time
 */
export default function formatTime(seconds, sets = 6) {
    const times = {
        years: Math.floor(seconds / 31536000),
        months: Math.floor((seconds % 31536000) / 2628000),
        weeks: Math.floor((seconds % 2628000) / 604800),
        days: Math.floor((seconds % 604800) / 86400),
        hours: Math.floor((seconds % 86400) / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
        seconds: seconds % 60,
    };

    let result = '';
    let setsCount = 0;

    Object.keys(times).forEach((time) => {
        if (times[time] > 0 && setsCount < sets) {
            if (result.length > 0) result += ', ';
            result += `${times[time]} ${i18next.t(times[time] > 1 ? time : time.slice(0, -1))}`;
            setsCount += 1;
        }
    });

    return result;
}
