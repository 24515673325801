/**
 * getAdminChallenges gets all the challenges paginated
 * @method POST
 * @param {number} page
 * @param {number} perPage
 * @param {string} jwt
 * @param {string} language
 * @param {string} challengeName 
 * @param {Array<string>} categories 
 * @param {string} answerType
 * @returns {Promise<{
 *      challenges: {
 *          chaGUID: string;
 *          name: string;
 *          type: string | null;
 *          status: string;
 *          visibility: string;
 *          description: string;
 *          maximum: string;
 *          scoreType: string;
 *          score: string;
 *          category: number;
 *          categoryName: string;
 *          answers: {
 *              ansGUID: string;
 *              ansType: string;
 *              ansData: string;
 *              ansDesc: string;
 *              ansValid: boolean;
 *          }[];
 *      }[];
 *      pages: number;
 * }>}
 */
      export default async function getAdminChallenges(page, perPage, jwt, language, challengeName = '', categories = [], answerType = '') {

          try {
              const url = `${process.env.REACT_APP_APP_URL}/api/v1/challenges/list`;
              const method = 'POST';
              const body = {
                page,
                perPage,
                name: challengeName,
                category: categories,
                answer: answerType
            };
              const headers = {
                  'Content-Type': 'application/json',
                  'Accept-Language': language,
                  Authorization: jwt,
              };
      
              const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
              const data = await response.json();
      
              if (!response.ok) {
                  const errorData = { controlled: true, data };
                  throw errorData;
              }
              
              return data;
          } catch (error) {
              if (error.controlled) {
                  throw error.data;
              }
      
              const errorData = { status: 500, messages: [error.message], context: 'error' };
              throw errorData;
          }
      }
         