/**
 * getCompetitions gets all the competitions that match the search criteria
 * @method POST
 * @param { 'all' | 'public' | 'private' | 'history'} type
 * @param {string | undefined} availability
 * @param {number} page
 * @param {{ name: string; status: ('not-started' | 'in-progress' | 'finished')[] | []; categories: number[]; }} criteria
 * @param {string | undefined} jwt
 * @param {string} language
 * @returns {Promise<{
 *      pages: number;
 *      competitions: {
 *          guid: string;
 *          name: string;
 *          description: string;
 *          status: 'not-started' | 'in-progress' | 'finished' | null;
 *          challenges: number;
 *          categories: string[];
 *          duration: number | null;
 *          timeRemaining: number | null;
 *          startDate: string | null;
 *          endDate: string | null;
 *          availability: string | null;
 *          creationDate: string;
 *      }[];
 * }>}
 */
export default async function getCompetitions(type, page, criteria, jwt, language, availability) {
    const perPage = parseInt(process.env.REACT_APP_COMPETITIONS_PER_PAGE, 10);

    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${type}`;
        const method = 'POST';
        const body = { type, criteria, page, perPage, availability };
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
        };

        if (jwt) {
            headers.Authorization = jwt;
        }

        const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
