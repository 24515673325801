// UserUpdateModal.jsx

import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Select from '../atoms/Select';

/**
 * UserUpdateModal allows the user to update an user
 * @param {{
 *      emailRef: React.RefObject<HTMLInputElement>;
 *      countryRef: React.RefObject<HTMLSelectElement>;
 *      firstNameRef: React.RefObject<HTMLInputElement>;
 *      lastNameRef: React.RefObject<HTMLInputElement>;
 *      sexRef: React.RefObject<HTMLSelectElement>;
 *      birthdayRef: React.RefObject<HTMLInputElement>;
 *      webRef: React.RefObject<HTMLInputElement>;
 *      biographyRef: React.RefObject<HTMLTextAreaElement>;
 *      phoneRef: React.RefObject<HTMLInputElement>;
 *      twitterRef: React.RefObject<HTMLInputElement>;
 *      facebookRef: React.RefObject<HTMLInputElement>;
 *      linkedinRef: React.RefObject<HTMLInputElement>;
 *      githubRef: React.RefObject<HTMLInputElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function UserUpdateModal({
    emailRef,
    countryRef,
    firstNameRef,
    lastNameRef,
    sexRef,
    birthdayRef,
    webRef,
    biographyRef,
    phoneRef,
    twitterRef,
    facebookRef,
    linkedinRef,
    githubRef,
    countries,
    userInfoObj,
    onAccept, 
    onCancel 
}) { 

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedSex, setSelectedSex] = useState('_UNKNOWN');

    // Sex
    useEffect(() => {
        setSelectedSex(userInfoObj.sex);
    }, [userInfoObj.sex]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        sexRef.current = selectedSex;
    }, [selectedSex, sexRef]);

    // Country
    useEffect(() => {
        setSelectedCountry(userInfoObj.country);
    }, [userInfoObj.country]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        countryRef.current = selectedCountry;
    }, [selectedCountry, countryRef]);

    const validate = () => {

        const validationErrors = [];

        if (!emailRef.current.value) {
            validationErrors.push(i18next.t('email'));
        }

        if (!firstNameRef.current.value) {
            validationErrors.push(i18next.t('firstName'));
        }

        if (!lastNameRef.current.value) {
            validationErrors.push(i18next.t('lastName'));
        }

        if (emailRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-email'));
            return false;
        }

        if (firstNameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-firstname'));
            return false;
        }

        if (lastNameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-lastname'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);
            return false;
        }
        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
            <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('user-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('email')}</Title>
                <Input
                    focus
                    inputRef={emailRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.email}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('country')}</Title>
                <Select
                    color="gray"
                    isLoading={false}
                    selected={selectedCountry} 
                    onSelect={(selected) => { setSelectedCountry(selected); }}
                    options={countries}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('first-name')}</Title>
                <Input
                    inputRef={firstNameRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.firstName}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('last-name')}</Title>
                <Input
                    inputRef={lastNameRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.lastName}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="md">{i18next.t('gender')}</Title>
                <Select
                    color='gray'
                    selected={selectedSex || '_UNKNOWN'}
                    onSelect={(selected) => setSelectedSex(selected)}
                    options={{
                        [null]: {
                            _MALE: i18next.t('male'),
                            _FEMALE: i18next.t('female'),
                            _UNKNOWN: i18next.t('other'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="md">{i18next.t('birthday')}</Title>
                <Input
                    inputRef={birthdayRef}
                    color="gray"
                    type="date"
                    defaultValue={userInfoObj.birthday}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('website')}</Title>
                <Input
                    inputRef={webRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.website}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('biography')}</Title>
                <Input
                    inputRef={biographyRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.biography}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('phone-number')}</Title>
                <Input
                    inputRef={phoneRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.phone}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('twitter-username')}</Title>
                <Input
                    inputRef={twitterRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.twitter}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('facebook-username')}</Title>
                <Input
                    inputRef={facebookRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.facebook}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('linkedin')}</Title>
                <Input
                    inputRef={linkedinRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.linkedin}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('github')}</Title>
                <Input
                    inputRef={githubRef}
                    color="gray"
                    type="text"
                    defaultValue={userInfoObj.github}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

UserUpdateModal.propTypes = {
    emailRef: PropTypes.shape().isRequired,
    countryRef: PropTypes.shape().isRequired,
    firstNameRef: PropTypes.shape().isRequired,
    lastNameRef: PropTypes.shape().isRequired,
    sexRef: PropTypes.shape().isRequired,
    birthdayRef: PropTypes.shape().isRequired,
    webRef: PropTypes.shape().isRequired,
    biographyRef: PropTypes.shape().isRequired,
    phoneRef: PropTypes.shape().isRequired,
    twitterRef: PropTypes.shape().isRequired,
    facebookRef: PropTypes.shape().isRequired,
    linkedinRef: PropTypes.shape().isRequired,
    githubRef: PropTypes.shape().isRequired,
    countries: PropTypes.shape().isRequired,
    userInfoObj: PropTypes.shape({
        email: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        sex: PropTypes.string.isRequired,
        birthday: PropTypes.string.isRequired,
        website: PropTypes.string.isRequired,
        biography: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        twitter: PropTypes.string.isRequired,
        facebook: PropTypes.string.isRequired,
        linkedin: PropTypes.string.isRequired,
        github: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserUpdateModal;