/**
 * Check if the browser supports getUserMedia and request access to the camera
 * @returns {Promise<MediaStream>} stream
 */
export default async function accessCamera() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error("Camera doesn't exist");
    }

    return navigator.mediaDevices.getUserMedia({ video: true });
}
