import { cloneElement, isValidElement } from 'react';

/**
 * Repeat an item the number of times indicated in "times" param
 * @param {number} times
 * @param {any} element
 * @returns {any[]} Elements list
 */
export default function repeat(times, element) {
    const elements = [];
    if (isValidElement(element)) {
        for (let i = 0; i < times; i += 1) {
            elements.push(cloneElement(element, { key: `element:${i}` }));
        }
    } else {
        for (let i = 0; i < times; i += 1) {
            elements.push(element);
        }
    }

    return elements;
}
