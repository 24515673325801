/**
 * getAdminUsers gets all the users paginated
 * @method POST
 * @param {number} page
 * @param {number} perPage
 * @param {string} jwt
 * @param {string} language
 * @param {string|null} [email]
 * @param {string|null} [username]
 * @param {string|null} [status]
 * @param {string|null} [firstName]
 * @param {string|null} [lastName]
 * @param {string|null} [role]
 * @returns {Promise<{
 *      users:{
 *          guid: string;
 *          email: string;
 *          pending: string;
 *          country: string;
 *          firstName: string;
 *          lastName: string;
 *          sex: string;
 *          birthday: string;
 *          website: string;
 *          biography: string;
 *          phone: string;
 *          twitter: string;
 *          facebook: string;
 *          linkedin: string;
 *          github: string;
 *      }[];
 *          username: string;
 *      pages: number;
 * }>}
 */      
    export default async function getAdminUsers(page, perPage, jwt, language, email, username, status, firstName, lastName, role) {
          
        try {
            const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/panel/list`;
            const method = 'POST';
            const body = {
                page,
                perPage,
                email: email || null,
                username: username || null,
                status: status || null,
                firstName: firstName || null,
                lastName: lastName || null,
                role: role || null,
            };
            const headers = {
                'Content-Type': 'application/json',
                'Accept-Language': language,
                Authorization: jwt,
            };
    
            const response = await fetch(url, { method, headers, body: JSON.stringify(body) });
            const data = await response.json();
    
            if (!response.ok) {
                const errorData = { controlled: true, data };
                throw errorData;
            }
            return data;
        } catch (error) {
            if (error.controlled) {
                throw error.data;
            }
    
            const errorData = { status: 500, messages: [error.message], context: 'error' };
            throw errorData;
        }
    }
         