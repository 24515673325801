import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

/**
 * useGoogleauth hook initializes the GSI in the calling component, optionally rendering a button
 * @param {{
 *      context: 'signin' | 'signup' | 'use';
 *      width: number;
 *      onSuccess: (credential: string) => void;
 * }} props
 *
 * @returns {React.MutableRefObject} Container target ref
 */
function useGoogleauth({ context, width, onSuccess }) {
    const buttonRef = useRef();

    // Run success on credential
    const handleResponse = useCallback(({ credential }) => {
        onSuccess(credential);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load GSI
    useEffect(() => {
        window.google?.accounts?.id?.initialize({
            client_id: process.env.REACT_APP_GSI_CLIENT_ID,
            ux_mode: 'popup',
            itp_support: true,
            hl: i18next.language,
            callback: handleResponse,
            context,
        });

        window.google?.accounts?.id?.prompt();

        return () => {
            window.google?.accounts?.id?.cancel();
        };
    }, [context, handleResponse]);

    // If a container is specified renders the auth button
    useEffect(() => {
        if (buttonRef.current) {
            window.google?.accounts?.id?.renderButton(buttonRef.current, {
                type: 'standard',
                theme: 'outline',
                size: 'large',
                text: 'continue_with',
                shape: 'circle',
                locale: i18next.language,
                width,
            });
        }
    }, [context, width, buttonRef]);

    return buttonRef;
}

useGoogleauth.propTypes = {
    context: PropTypes.oneOf(['signin', 'signup', 'use']).isRequired,
    width: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default useGoogleauth;
