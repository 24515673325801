// CertificationUpdateModal.jsx

import { faBan, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * CertificationUpdateModal allows the user to update a certification
 * @param {{
 *      nameRef: React.RefObject<HTMLInputElement>;
 *      institutionNameRef: React.RefObject<HTMLInputElement>;
 *      issueDateRef: React.RefObject<HTMLInputElement>;
 *      credentialIDRef: React.RefObject<HTMLInputElement>;
 *      credentialURLRef: React.RefObject<HTMLInputElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function CertificationUpdateModal({ 
    nameRef,
    institutionNameRef,
    issueDateRef, 
    credentialIDRef, 
    credentialURLRef, 
    certiInfoObj,
    onAccept, 
    onCancel 
}) { 

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const validate = () => {

        const validationErrors = [];

        if (!nameRef.current.value) {
            validationErrors.push(i18next.t('certification-name'));
        }
        if (!institutionNameRef.current.value) {
            validationErrors.push(i18next.t('certification-institution'));
        }
        if (!issueDateRef.current.value) {
            validationErrors.push(i18next.t('certification-issueDate'));
        }
        if (!credentialURLRef.current.value) {
            validationErrors.push(i18next.t('certification-credential-url'));
        }

        if (nameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-certification-name'));
            return false;
        }

        if (institutionNameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-institution'));
            return false;
        }

        if (credentialIDRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-credentialID'));
            return false;
        }

        if (credentialURLRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-credentialURL'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('certification-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('certification-name')}</Title>
                <Input
                    inputRef={nameRef}
                    color="gray"
                    type="text"
                    defaultValue={certiInfoObj.name}
                    placeholder={i18next.t('certification-ex-name')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('certification-institution')}</Title>
                <Input
                    inputRef={institutionNameRef}
                    color="gray"
                    type="text"
                    defaultValue={certiInfoObj.institutionName}
                    placeholder={i18next.t('certification-ex-institution')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('certification-issueDate')}</Title>
                <Input
                    inputRef={issueDateRef}
                    color="gray"
                    type="month"
                    defaultValue={certiInfoObj.issueDate}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('certification-credentialID')}</Title>
                <Input
                    inputRef={credentialIDRef}
                    color="gray"
                    type="text"
                    defaultValue={certiInfoObj.credentialID}
                    placeholder={i18next.t('certification-ex-credentialID')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('certification-credentialURL')}</Title>
                <Input
                    inputRef={credentialURLRef}
                    color="gray"
                    type="text"
                    defaultValue={certiInfoObj.credentialURL}
                    placeholder={i18next.t('certification-ex-credentialURL')}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

CertificationUpdateModal.propTypes = {
    nameRef: PropTypes.shape().isRequired,
    institutionNameRef: PropTypes.shape().isRequired,
    issueDateRef: PropTypes.shape().isRequired,
    credentialIDRef: PropTypes.shape().isRequired,
    credentialURLRef: PropTypes.shape().isRequired,
    certiInfoObj: PropTypes.shape({
        certificationID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        institutionName: PropTypes.string.isRequired,
        issueDate: PropTypes.string.isRequired,
        credentialID: PropTypes.string.isRequired,
        credentialURL: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CertificationUpdateModal;