import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import Button from '../atoms/Button';
import Input from '../atoms/Input';

/**
 * SimpleSearch molecule creates a simple search input with a button to trigger search
 * @param {{
 *      isLoading: boolean;
 *      placeholder: string;
 *      onSearch: (input: string) => void;
 * }} props
 */
function SimpleSearch({ isLoading, placeholder, onSearch }) {
    const inputRef = useRef();

    /**
     * Run onSearch and focus input on click search
     */
    const handleSearch = () => {
        onSearch(inputRef.current.value);
        inputRef.current?.focus();
    };

    return (
        <div className="flex gap-3 justify-end items-center">
            <Input
                isLoading={isLoading}
                inputRef={inputRef}
                color="white"
                type="text"
                focus
                placeholder={placeholder}
            />
            <Button isLoading={isLoading} color="green" circle icon={faMagnifyingGlass} onClick={handleSearch} />
        </div>
    );
}

SimpleSearch.propTypes = {
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
};

SimpleSearch.defaultProps = {
    isLoading: false,
};

export default SimpleSearch;