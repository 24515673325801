/**
 * createAdminChallenge creates a new Challenge.
 * @method POST
 * @param {string} name
 * @param {string} type
 * @param {string} status
 * @param {string} visibility
 * @param {string} description
 * @param {string} clarifications
 * @param {number} maximum - The maximum attempts per Challenge
 * @param {string} scoreType
 * @param {number} score
 * @param {string} category
 * @param {Array} answers
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function createAdminChallenge(name, type, status, visibility, description, clarifications, maximum, scoreType, score, category, answers, jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/challenges`;
       const method = 'POST';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'User-Agent': window.navigator.userAgent,
           Authorization: jwt,
       };

       const body = JSON.stringify({ challenge: { name, type, status, visibility, description, clarifications, maximum, scoreType, score, category }, answers });

       const response = await fetch(url, { method, headers, body });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}
