import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import useDisplay from '../../hooks/useDisplay';

function SelectMultiple({ isLoading, placeholder, options, selected, onSelect, order, color = 'white' }) {
    const { targetRef, display, setDisplay } = useDisplay({ displayed: false });
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleSelect = (key) => {
        const updatedSelection = selected.includes(key)
            ? selected.filter(item => item !== key)
            : [...selected, key];
        onSelect(updatedSelection);
        setInputValue('');
    };

    const handleRemove = (key, e) => {
        e.stopPropagation();
        const updatedSelection = selected.filter(item => item !== key);
        onSelect(updatedSelection);
    };

    const filteredOptions = Object.entries(options).reduce((acc, [section, sectionOptions]) => {
        const filteredSectionOptions = Object.entries(sectionOptions).filter(([key, value]) =>
            typeof value === 'string' && value.toLowerCase().includes(inputValue.toLowerCase())
        );
        if (filteredSectionOptions.length > 0) {
            acc[section] = Object.fromEntries(filteredSectionOptions);
        }
        return acc;
    }, {});

    const handleContainerKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setDisplay(true);
            inputRef.current?.focus();
        }
    };

    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                borderRadius={6}
                containerClassName="w-full flex h-10"
            />
        );
    }

    return (
        <div ref={targetRef} className="relative select-none">
            <div
                role="combobox"
                aria-expanded={display}
                aria-haspopup="listbox"
                aria-controls="options-listbox"
                tabIndex={0}
                onClick={() => setDisplay(true)}
                onKeyDown={handleContainerKeyDown}
                className={classNames(
                    "w-full px-2 min-h-[40px] flex flex-wrap items-center rounded-md shadow-md outline-none cursor-text",
                    color === 'gray' ? 'bg-smgray shadow-inner' : 'bg-white shadow-md',
                )}
            >
                {selected.length > 0 ? (
                    selected.map(key => (
                        <div key={`selected:${key}`} className="flex items-center bg-smgreen-light text-smgreen-dark rounded m-1 px-2 py-1">
                            <span className="text-sm mr-1">{Object.assign({}, ...Object.values(options))[key]}</span>
                            <button 
                                onClick={(e) => handleRemove(key, e)} 
                                className="text-smgreen-dark hover:text-smgreen-darker"
                                aria-label={`Remove ${Object.assign({}, ...Object.values(options))[key]}`}
                                type="button"
                            >
                                <FontAwesomeIcon icon={faTimes} className="h-3 w-3" />
                            </button>
                        </div>
                    ))
                ) : (
                    <p className="text-sm text-gray-400">{placeholder}&nbsp;</p>
                )}
                <input
                    ref={inputRef}
                    type="text"
                    className="flex-grow outline-none bg-transparent text-sm min-w-[20px]"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onFocus={() => setDisplay(true)}
                    aria-autocomplete="list"
                />
                <button
                    type="button"
                    onClick={() => setDisplay((prevDisplay) => !prevDisplay)}
                    className="ml-auto"
                    aria-label={display ? "Close options" : "Open options"}
                >
                    <FontAwesomeIcon icon={display ? faCaretUp : faCaretDown} />
                </button>
            </div>

            {display && (
                <div 
                    id="options-listbox"
                    role="listbox"
                    className="absolute top-full z-30 w-full max-h-64 mt-2 flex flex-col bg-white rounded-md shadow-md divide-y overflow-x-hidden overflow-y-auto"
                >
                    {Object.keys(filteredOptions).map((section) => (
                        <Fragment key={`select:section:${section}`}>
                            {section !== 'null' && <p className="text-sm font-bold px-5 py-3">{section}</p>}
                            {(order
                                ? order.filter(key => filteredOptions[section]?.[key])
                                : Object.keys(filteredOptions[section] || {}).sort((a, b) => 
                                    filteredOptions[section][a].localeCompare(filteredOptions[section][b])
                                )
                            ).map((key) => (
                                <button
                                    key={`select:option:${key}`}
                                    role="option"
                                    aria-selected={selected.includes(key)}
                                    type="button"
                                    onClick={() => handleSelect(key)}
                                    className={classNames(
                                        'w-full py-2 flex items-center outline-none text-left',
                                        section !== 'null' ? 'px-10' : 'px-5',
                                        { 'bg-smgreen-light text-smgreen-dark': selected.includes(key) },
                                    )}
                                >
                                    <p className="text-sm">{filteredOptions[section][key]}</p>
                                </button>
                            ))}
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}

SelectMultiple.propTypes = {
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    order: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.oneOf(['gray', 'white'])
};

SelectMultiple.defaultProps = {
    isLoading: false,
    placeholder: '',
    order: null,
    color: 'white'
};

export default SelectMultiple;