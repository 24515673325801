/* eslint-disable no-param-reassign */

import { faAngleDown, faAngleUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MonacoEditor from '@monaco-editor/react';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * CodeEditor atom creates a code editor with integrated terminal
 * @param {{
 *      codeRef: React.RefObject<HTMLInputElement>;
 *      height: number;
 *      language: string;
 *      defaultValue: string | undefined;
 *      defaultLines: {
 *          type: 'text' | 'success' | 'message' | 'warn' | 'error';
 *          text: string;
 *      }[];
 * }} props
 */
function CodeEditor({ codeRef, height, language, defaultValue, defaultLines }) {
    const ref = useRef();
    const [lines, setLines] = useState(defaultLines);
    const [display, setDisplay] = useState(defaultLines.length > 0);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            ref.current?.dispose();
        };
    }, []);

    /**
     * Create a new line
     * @param {'text' | 'success' | 'message' | 'warn' | 'error'} type
     * @param {string} text
     */
    const handleCreateLine = useCallback((type, text) => {
        setDisplay(true);
        setLines((prev) => [...prev, { type, text }]);
    }, []);

    return (
        <div className="w-full flex flex-col rounded-md shadow-md overflow-hidden">
            <MonacoEditor
                height={height}
                language={language}
                value={defaultValue}
                options={{ minimap: { enabled: false } }}
                onMount={(editorRef) => {
                    editorRef.createLine = handleCreateLine;
                    ref.current = editorRef;
                    codeRef.current = editorRef;
                }}
            />

            <div className="w-full flex items-center select-none">
                <div className="w-full h-8 flex px-5 items-center bg-smgray text-sm font-semibold">
                    {i18next.t('terminal')}
                </div>
                <button
                    type="button"
                    title={i18next.t(display ? 'hide-terminal' : 'show-terminal')}
                    onClick={() => setDisplay(!display)}
                    className="w-10 h-8 flex justify-center items-center bg-smgreen-dark outline-none"
                >
                    <FontAwesomeIcon icon={display ? faAngleDown : faAngleUp} className="text-white aspect-square" />
                </button>
                <button
                    type="button"
                    title={i18next.t('clear-terminal')}
                    onClick={() => setLines([])}
                    className="w-10 h-8 flex justify-center items-center bg-smred-dark outline-none"
                >
                    <FontAwesomeIcon icon={faTrash} size="sm" className="text-white aspect-square" />
                </button>
            </div>

            {display && (
                <div className="w-full min-h-[200px] h-[200px] bg-white resize-y overflow-auto">
                    <div className="w-full h-min flex flex-col-reverse">
                        {lines.map((line, index) => (
                            <p
                                key={`codeline:${line.text + index.toString()}`}
                                className={classNames(
                                    'w-full p-5 text-sm font-mono border-t-2 border-smgray whitespace-pre-wrap',
                                    {
                                        'text-smgreen-dark font-semibold': line.type === 'success',
                                        'text-smblue-dark font-semibold': line.type === 'message',
                                        'text-smorange-dark font-semibold': line.type === 'warn',
                                        'text-smred-dark font-semibold': line.type === 'error',
                                        'text-smgray-dark': line.type === 'text',
                                    },
                                )}
                            >
                                {line.text}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

CodeEditor.propTypes = {
    codeRef: PropTypes.shape().isRequired,
    height: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    defaultLines: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['text', 'success', 'message', 'warn', 'error']).isRequired,
            text: PropTypes.string.isRequired,
        }),
    ),
};

CodeEditor.defaultProps = {
    defaultValue: undefined,
    defaultLines: [],
};

export default CodeEditor;
