import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import prefixNumber from '../../utils/prefixNumber';

/**
 * Countdown atom creates a timer that will execute a callback when it reaches 0
 * @param {{
 *      size: 'lg' | 'md' | 'sm';
 *      time: number;
 *      autoStart: boolean;
 *      onStart: () => void;
 *      onFinish: () => void;
 * }} props
 */
function Countdown({ size, time, autoStart, onStart, onFinish }) {
    const [timeRemaining, setTimeRemaining] = useState(time);
    const finishDate = useRef(new Date(Date.now() + time * 1000));

    // Calculate and save the remaining time to reach the end date every 500 ms
    useEffect(() => {
        if (!autoStart) {
            return () => {};
        }

        onStart();
        const interval = setInterval(() => {
            const msRemaining = finishDate.current - new Date();
            if (msRemaining > 0) {
                setTimeRemaining(Math.ceil(msRemaining / 1000));
                return;
            }

            onFinish();
            setTimeRemaining(0);
            clearInterval(interval);
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [time, autoStart, onStart, onFinish]);

    const timevalues = {
        [i18next.t('days')]: prefixNumber(Math.floor(timeRemaining / (60 * 60 * 24))),
        [i18next.t('hours')]: prefixNumber(Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60))),
        [i18next.t('minutes')]: prefixNumber(Math.floor((timeRemaining % (60 * 60)) / 60)),
        [i18next.t('seconds')]: prefixNumber(Math.floor(timeRemaining % 60)),
    };

    return (
        <div className="flex gap-4 font-mono select-none">
            {Object.keys(timevalues).map((key) => (
                <Fragment key={`countdown:${key}`}>
                    <div className="flex flex-col items-center">
                        <p
                            className={classNames({
                                'text-4xl': size === 'lg',
                                'text-3xl': size === 'md',
                                'text-2xl': size === 'sm',
                            })}
                        >
                            {timevalues[key]}
                        </p>
                        <p
                            className={classNames({
                                'text-base': size === 'lg',
                                'text-sm': size === 'md',
                                'text-xs': size === 'sm',
                            })}
                        >
                            {key}
                        </p>
                    </div>
                    <p
                        className={classNames('my-auto last:hidden', {
                            'text-lg': size === 'lg',
                            'text-base': size === 'md',
                            'text-sm': size === 'sm',
                        })}
                    >
                        :
                    </p>
                </Fragment>
            ))}
        </div>
    );
}

Countdown.propTypes = {
    size: PropTypes.oneOf(['lg', 'md', 'sm']).isRequired,
    time: PropTypes.number.isRequired,
    autoStart: PropTypes.bool,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
};

Countdown.defaultProps = {
    autoStart: false,
    onStart: () => {},
    onFinish: () => {},
};

export default Countdown;
