/**
 * getChallenges gets the challenge list
 * @method GET
 * @param {string} competition
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      guid: string;
 *      name: string;
 *      category: string;
 *      description: string;
 *      solved: boolean | null;
 *      sent: boolean;
 *      attempts: number;
 *      maxAttempts: number;
 *      score: number | null;
 *      difficulty: 'low' | 'medium' | 'high' | 'max' | null;
 *      author: string;
 *      answerType: 'unique' | 'text' | 'multiple' | 'code' | 'open';
 * }[]>}
 */
export default async function getChallenges(competition, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${competition}/challenges`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
