import { faBan, faQuestion, faUserCheck, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * UserStatusModal allows the user to delete/activate an user
 * @param {{
 *      email: string;
 *      status: string; // Add status as a prop
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function UserStatusModal({ email, status, onAccept, onCancel }) {
    const confirmationMessage = status === 'DELETED'
        ? i18next.t('user-confirm-activate')
        : i18next.t('user-confirm-delete');

    const icon = status === 'DELETED' ? faUserCheck : faUserLock;
    const buttonColor = status === 'DELETED' ? 'green' : 'red';

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex justify-center py-3">
                <FontAwesomeIcon icon={faQuestion} size="5x" className={`text-${buttonColor}-dark`} />
            </div>
            <p className="text-center">
                <Title size="md">{confirmationMessage}</Title>
                <strong>{email}</strong>
            </p>
            <div className="w-full flex gap-5">
                <Button color="white" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color={buttonColor} icon={icon} onClick={onAccept}>
                    { status === 'DELETED' ? i18next.t('activate') : i18next.t('delete') }
                </Button>
            </div>
        </div>
    );
}

UserStatusModal.propTypes = {
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserStatusModal;
