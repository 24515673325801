import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useGoogleauth from '../../hooks/useGoogleauth';
import useRecaptcha from '../../hooks/useRecaptcha';
import useSession from '../../hooks/useSession';
import googleRegister from '../../services/googleRegister';
import register from '../../services/register';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Title from '../atoms/Title';

/**
 * Register organism creates a form to create an user with username/email/password or Google
 */
function Register() {
    const { createSession } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const recaptcha = useRecaptcha({ mode: 'login' });
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();

    // Create user with email and password
    const registerMutation = useMutation({
        mutationFn: ({ email, password, recaptchaToken }) =>
            register(email, password, recaptchaToken, i18next.language),

        onMutate: () => {
            removeFeedback();
        },
        onSuccess: (data) => {
            createFeedback(data.context, data.messages);
        },
        onError: (error) => {
            if (error.status === 403) {
                createFeedback('error', i18next.t('user-already-registered'));
                passwordRef.current.value = '';
                passwordRef.current.focus();
                return;
            }

            createFeedback('error', i18next.t('something-went-wrong'));
            emailRef.current.value = '';
            passwordRef.current.value = '';
            emailRef.current.focus();
        },
    });

    // Create user with Google
    const googleRegisterMutation = useMutation({
        mutationFn: ({ googleToken, recaptchaToken }) => googleRegister(googleToken, recaptchaToken, i18next.language),
        onMutate: () => {
            removeFeedback();
        },
        onSuccess: (data) => {
            createSession(data.jwt);
            navigate('/competitions/public');
        },
        onError: (error) => {
            if (error.status === 403) {
                createFeedback('error', i18next.t('user-already-registered'));
                passwordRef.current.value = '';
                passwordRef.current.focus();
                return;
            }

            createFeedback('error', i18next.t('something-went-wrong'));
            emailRef.current.value = '';
            passwordRef.current.value = '';
            emailRef.current.focus();
        },
    });

    /**
     * Run recaptcha for register
     * @param {React.FormEvent | null} event
     * @param {string | undefined} googleToken
     */
    const handleRegister = async (event, googleToken) => {
        event?.preventDefault();

        const recaptchaToken = await recaptcha();

        // If google register
        if (googleToken) {
            googleRegisterMutation.mutate({ googleToken, recaptchaToken });
            return;
        }

        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;

        // Validate empty data
        if (!email || !password) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        // Validate password
        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        registerMutation.mutate({ email, password, recaptchaToken });
    };

    // Create register with Google button
    const buttonRef = useGoogleauth({
        context: 'signup',
        width: 280,
        onSuccess: (googleToken) => handleRegister(null, googleToken),
    });

    return (
        <div className="w-full flex flex-col gap-5">
            <Container>
                <div className="w-full px-5 py-7 flex flex-col gap-7 items-center">
                    <div className="w-full flex flex-col gap-2 items-center">
                        <Title size="xl">{i18next.t('sign-up')}</Title>
                        <Title size="md">{i18next.t('your-journey-start')}</Title>
                    </div>

                    <p className="w-full text-center text-sm">
                        {i18next.t('policy-paragraph-1')}{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://smartalentit.com/politica-de-privacidad-y-tratamiento-de-datos-personales/"
                            className="text-blue-600 hover:underline"
                        >
                            {i18next.t('policy-paragraph-2')}
                        </a>
                    </p>

                    <div className="w-full flex flex-col gap-5">
                        <div className="w-full flex flex-col gap-3">
                            <div ref={buttonRef} className="w-full flex justify-center" />
                        </div>

                        <div className="w-full flex items-center select-none">
                            <hr className="w-full border border-zinc-300" />
                            <p className="mx-4 text-zinc-400 font-semibold uppercase">{i18next.t('or')}</p>
                            <hr className="w-full border border-zinc-300" />
                        </div>

                        <form onSubmit={handleRegister} className="w-full flex flex-col gap-3">
                            <Input
                                inputRef={emailRef}
                                color="gray"
                                type="email"
                                complete="username"
                                placeholder={i18next.t('email')}
                            />
                            <Input
                                inputRef={passwordRef}
                                color="gray"
                                type="password"
                                complete="current-password"
                                placeholder={i18next.t('password')}
                            />

                            <Button
                                isLoading={registerMutation.isLoading || googleRegisterMutation.isLoading}
                                submit
                                color="green"
                                icon={faUserPlus}
                            >
                                {i18next.t('sign-up')}
                            </Button>
                        </form>
                    </div>

                    <div className="w-full flex justify-center pb-1">
                        <Link color="" to="/auth/sign-in">
                            {i18next.t('already-in-smart-ranks')}
                        </Link>
                    </div>
                </div>
            </Container>

            {feedbackElement}
        </div>
    );
}

export default Register;
