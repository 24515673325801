/**
 * deleteJob removes a job from the user.
 * @method DELETE
 * @param {string} jobID
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function deleteJob(jobID, jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/job/${jobID}`;
       const method = 'DELETE';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'User-Agent': window.navigator.userAgent,
           Authorization: jwt,
       };

       const response = await fetch(url, { method, headers });

       if (!response.ok) {
           const errorData = { controlled: true, data: await response.json() };
           throw errorData;
       }

       const data = await response.json();
       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}