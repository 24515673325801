import { useContext } from 'react';
import { SessionContext } from '../contexts/SessionProvider';

/**
 * useSession context creates a global context for the user session
 * @returns {{
 *      session: string | null;
 *      createSession: (token: string) => void;
 *      removeSession: () => void;
 * }}
 */
function useSession() {
    return useContext(SessionContext);
}

export default useSession;
