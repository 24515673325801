import { faCheck, faTimes, faTriangleExclamation, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const icons = {
    success: faCheck,
    message: faVoicemail,
    warn: faTriangleExclamation,
    error: faTimes,
};

/**
 * Feedback atom creates a user message using the type prop
 * @param {{
 *      type: 'success' | 'message' | 'warn' | 'error';
 *      children: string;
 * }} props
 */
function Feedback({ type, children }) {
    return (
        <div
            className={classNames(
                'w-full px-5 py-2.5 flex gap-5 items-center rounded-md shadow-md border select-none',
                {
                    'border-smgreen-dark bg-smgreen text-smgreen-dark': type === 'success',
                    'border-smblue-dark bg-smblue text-smblue-dark': type === 'message',
                    'border-smorange-dark bg-smorange text-smorange-dark': type === 'warn',
                    'border-smred-dark bg-smred text-smred-dark': type === 'error',
                },
            )}
        >
            <FontAwesomeIcon icon={icons[type]} />
            <p className="text-sm">{children}</p>
        </div>
    );
}

Feedback.propTypes = {
    type: PropTypes.oneOf(['success', 'message', 'warn', 'error']).isRequired,
    children: PropTypes.string.isRequired,
};

export default Feedback;
