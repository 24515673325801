import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import RecoverAccount from '../organisms/RecoverAccount';
import FormTemplate from '../templates/FormTemplate';

/**
 * RecoverAccount page shows a form to recover an account
 */
function RecoverAccountPage() {
    const { session } = useSession();

    // Handle session
    if (session) {
        return <Navigate to="/competitions/private" />;
    }

    return (
        <FormTemplate>
            <RecoverAccount />
        </FormTemplate>
    );
}

export default RecoverAccountPage;
