// ChallengeViewModal.jsx

import { faClose} from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import isMarkdown from '../../utils/isMarkdown';
import Highlight from '../atoms/Highlight';
import Markdown from '../atoms/Markdown';
import Button from '../atoms/Button';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';
import CardToggle from '../molecules/CardToggle';

/**
 * ChallengeViewModal allows the user to view an specific challenge and its details.
 * @param {{
 *      data: {
 *          name: string;
 *          author: string;
 *          categoryName: string;
 *          answers: Array<{
 *              ansGUID: string;
 *              ansData: string;
 *              ansValid: boolean;
 *              ansType: string;
 *          }>;
 *          score: number;
 *          description: string;
 *          clarifications: string;
 *      }; 
 *      onCancel: () => void;
 * }} props
 */
function ChallengeViewModal({
    data,
    onCancel
}) { 
    function getColorForType(type) {
        switch (type) {
            case 'HASH':
                return 'yellow';
            case 'REGEXP':
                return 'red';
            case 'DYNAMIC':
                return 'green';
            case 'PLAIN':
                return 'blue';
            case 'CODE':
                return 'gray';
            case 'OPEN':
                return 'orange';
            default:
                return 'gray';
        }
    }

    function getColorForScore(score) {
        if (score >= 75) {
            return 'green';
        }
        if (score >= 50) {
            return 'yellow';
        }
        if (score >= 25) {
            return 'orange';
        }
        return 'red';
    }

    return (
        <div>
            <div className="w-full p-5 flex flex-col gap-x-5  gap-y-2">
                <div className="flex items-center">
                    <Banner color="green" borderColor="green">
                        {data.name}
                    </Banner>
                </div>
                <div className="flex items-center gap-x-3">
                    <div className="w-4/12 px-5 truncate" title={i18next.t('author')}>
                        <Title size="md">{i18next.t('author')}</Title>
                    </div>
                    <div className="w-3/12 text-center" title={i18next.t('category')}>
                        <Title size="md">{i18next.t('category-name')}</Title>
                    </div>
                    <div className="flex w-3/12 justify-center truncate" title={i18next.t('type')}>
                        <div className="w-fit">
                            <Title size="md">{i18next.t('type')}</Title>
                        </div>
                    </div>
                    <div className="w-2/12 text-center" title={i18next.t('score')}>
                        <Title size="md">{i18next.t('score')}</Title>
                    </div>
                </div>
                <div className="flex items-center gap-x-3">
                    <div className="w-4/12 text-center px-2 truncate" title={i18next.t('author')}>
                        <Tag size="md" color="yellow">{data.author}</Tag>
                    </div>
                    <div className="flex w-3/12 justify-center" title={data.categoryName}>
                        <div className="w-fit">
                            <Tag size="sm" color="blue">
                                {data.categoryName}
                            </Tag>
                        </div>
                    </div>
                    <div className="flex w-3/12 justify-center truncate" title={i18next.t('type')}>
                        <div className="w-fit">
                            {["HASH", "REGEXP", "DYNAMIC", "PLAIN", "CODE", "OPEN"].includes(data.answers[0].ansType) && (
                                <Tag size="sm" color={getColorForType(data.answers[0].ansType)}>
                                    {data.answers[0].ansType}
                                </Tag>
                            )}
                        </div>
                    </div>
                    <div className="flex w-2/12 justify-center" title={i18next.t('score')}>
                        <div className="w-fit">
                            <Tag
                                size="sm"
                                color={getColorForScore(data.score)}
                                centerText
                            >
                                {data.score}
                            </Tag>
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="w-full flex flex-col mt-5 p-2 gap-2">
                        <Title size="md">
                            {i18next.t('description')}
                        </Title>
                        {isMarkdown(data.description) ? (
                            <Markdown>{data.description}</Markdown>
                        ) : (
                            <Highlight>{data.description}</Highlight>
                        )}
                    </div>
                </div>
                {data.clarifications !== null && data.clarifications.trim() && (
                    <div className='mt-5'>
                        <Tag color="green" size="lg">{i18next.t('clarifications')}</Tag> 
                        <div className="mt-2 p-2 bg-lime-50 rounded">
                            <Markdown>{data.clarifications}</Markdown>
                        </div>
                    </div>
                )}
            </div>
            {data.answers && Object.keys(data.answers).length > 0 && (
                <div className="flex justify-center m-5">
                    <Banner borderColor="white">{i18next.t('answers')}</Banner>
                </div>
            )}
            {data.answers && Object.keys(data.answers).length > 0 ? (
                <div>
                    {data.answers.map((answer) => (
                        <div key={answer.ansGUID} className="mt-1 px-5">
                            <CardToggle
                                border={answer.ansValid ? "green" : "red"}
                                size="sm"
                                key={answer.ansGUID}
                                title={`${answer.ansValid ? '✅' : '❌'} ${answer.ansData}`}
                            >
                                <div className="flex flex-wrap">
                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                        <div className="w-1/6">
                                            <Tag color="blue" size="sm">GUID</Tag>
                                        </div>
                                        <div className="w-5/6">
                                            <span>{answer.ansGUID}</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                        <div className="w-1/6">
                                            <Tag color="orange" size="sm">
                                                {i18next.t('description')}
                                            </Tag>
                                        </div>
                                        <div className="w-5/6">
                                            <span>
                                                {answer.ansData}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                        <div className="w-1/6">
                                            <Tag color="orange" size="sm">
                                                {i18next.t('validity')}
                                            </Tag>
                                        </div>
                                        <div className="w-5/6">
                                            <span>
                                                {answer.ansValid ? i18next.t('valid') : i18next.t('invalid')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </CardToggle>
                        </div>
                    ))}
                </div>
            ) : (
                <Banner borderColor='red'>
                    {i18next.t('challenges-not-found')}
                </Banner>
            )}
            <div className="flex justify-center" title={i18next.t('close')}>
                <div className="w-1/3 m-3">
                    <Button color="red" icon={faClose} onClick={onCancel}>
                        {i18next.t('close')}
                    </Button>
                </div>
            </div>
        </div>
        
    );
}

ChallengeViewModal.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        answers: PropTypes.arrayOf(PropTypes.shape({
            ansGUID: PropTypes.string.isRequired,
            ansData: PropTypes.string.isRequired,
            ansValid: PropTypes.bool.isRequired,
            ansType: PropTypes.oneOf(['HASH', 'REGEXP', 'DYNAMIC', 'PLAIN', 'CODE', 'OPEN']).isRequired,
        })).isRequired,
        score: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        clarifications: PropTypes.string,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ChallengeViewModal;