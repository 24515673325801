import { faBan, faPlay, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';

/**
 * FinishCompetition modal warns about the irreversibility of finishing the test
 * @param {{
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function FinishCompetition({ onAccept, onCancel }) {
    return (
        <div className="w-full p-10 flex flex-col gap-10 items-center">
            <FontAwesomeIcon icon={faTriangleExclamation} className="sm:h-20 h-16 text-smorange-dark" />
            <p>{i18next.t('confirm-finish-competition')}</p>
            <div className="w-full flex gap-5">
                <Button color="green" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="white" icon={faPlay} onClick={onAccept}>
                    {i18next.t('finish')}
                </Button>
            </div>
        </div>
    );
}

FinishCompetition.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default FinishCompetition;
