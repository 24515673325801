import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import ResetPassword from '../organisms/ResetPassword';
import FormTemplate from '../templates/FormTemplate';

/**
 * ResetPassword page shows a way to reset the password with newPassword/and confirm newPassword
 */
function ResetPasswordPage() {
    const { session } = useSession();

    // Handle session
    if (session) {
        return <Navigate to="/competitions/private" />;
    }

    return (
        <FormTemplate>
            <ResetPassword />
        </FormTemplate>
    );
}

export default ResetPasswordPage;
