import {
    faCheck,
    faCheckDouble,
    faEye,
    faFire,
    faForward,
    faHeart,
    faInfinity,
    faPaperPlane,
    faPenToSquare,
    faPlay,
    faStar,
    faTrash,
    faUser,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useModal from '../../hooks/useModal';
import useSession from '../../hooks/useSession';
import compileAnswer from '../../services/compileAnswer';
import getChallenge from '../../services/getChallenge';
import getChallenges from '../../services/getChallenges';
import getDomain from '../../services/getDomain';
import getInteractions from '../../services/getInteractions';
import likeChallenge from '../../services/likeChallenge';
import likeSolution from '../../services/likeSolution';
import sendFeedback from '../../services/sendFeedback';
import deleteSolution from '../../services/deleteSolution';
import sendSolution from '../../services/sendSolution';
import updateSolution from '../../services/updateSolution';
import validateAnswer from '../../services/validateAnswer';
import accessCamera from '../../utils/accessCamera';
import formatTime from '../../utils/formatTime';
import isMarkdown from '../../utils/isMarkdown';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import CodeEditor from '../atoms/CodeEditor';
import Container from '../atoms/Container';
import Highlight from '../atoms/Highlight';
import Image from '../atoms/Image';
import Input from '../atoms/Input';
import Like from '../atoms/Like';
import Link from '../atoms/Link';
import Markdown from '../atoms/Markdown';
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Spinner from '../atoms/Spinner';
import AccessCamera from '../modals/AccessCamera';
import SendFeedback from '../modals/SendFeedback';
import SolutionDeleteModal from '../modals/SolutionDeleteModal';
import SolutionUpdateModal from '../modals/SolutionUpdateModal';
import ConfirmationModal from '../modals/ConfirmationModal';

/**
 * Challenge organism creates the view to solve a challenge
 */
function Challenge() {
    const { visibility, competition, challenge } = useParams();
    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const firstLoad = useRef(true);
    const queryClient = useQueryClient();
    const feedbackRef = useRef();
    const solutionRef = useRef();
    const solutionUpdatedRef = useRef();
    const codeAnswersRef = useRef(null);
    const [inputAnswers, setInputAnswers] = useState('');
    const [checkAnswers, setCheckAnswers] = useState([]);
    const [isSending, setIsSending] = useState(false);

    // Get competition domain
    const domainQuery = useQuery({
        queryKey: [session, 'domain', competition],
        queryFn: () => getDomain(competition, session, i18next.language),
        enabled: !!session,
    });

    // Get challenge view
    const challengeQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges', challenge],
        queryFn: () => getChallenge(competition, challenge, session, i18next.language),
    });

    // Get challenge list
    const challengesQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges'],
        queryFn: () => getChallenges(competition, session, i18next.language),
    });

    // Get challenge interactions
    const interactionsQuery = useQuery({
        queryKey: [session, 'competitions', competition, 'challenges', challenge, 'interactions'],
        queryFn: () => getInteractions(competition, challenge, session, i18next.language),
    });

    // Validate user answer
    const validateMutation = useMutation({
        mutationFn: (answer) => validateAnswer(competition, challenge, answer, session, i18next.language),
        onMutate: () => {
            setIsSending(true);
            removeFeedback();
        },
        onError: (error) => {
            setIsSending(false);
            createFeedback('error', error.messages);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries([session, 'competitions', competition, 'challenges']).then(() => {
                setIsSending(false);
                const { attempts, maxAttempts } = challengeQuery.data;
                if (data.valid === 0) {
                    createFeedback('error', i18next.t('sorry-wrong-answer'));
                } else if (data.valid === 1) {
                    createFeedback('success', i18next.t('challenge-solved'));
                } else if (maxAttempts !== -1 && attempts + 1 >= maxAttempts) {
                    createFeedback('message', `${i18next.t('answer-sent')}. ${i18next.t('no-attempts-left')}`);
                } else {
                    createFeedback('message', i18next.t('answer-sent'));
                }
            });
        },
    });

    // Compile user code answer
    const compileMutation = useMutation({
        mutationFn: (answer) => compileAnswer(competition, challenge, answer, session, i18next.language),
        onMutate: () => {
            removeFeedback();
            codeAnswersRef.current.createLine('text', `${i18next.t('running-code')}...`);
        },
        onError: (error) => {
            createFeedback('error', error.messages);
        },
        onSuccess: (data) => {
            codeAnswersRef.current.createLine(data.context, data.messages);
        },
    });

    // Make a like/dislike for a challenge
    const challengeLikeMutation = useMutation({
        mutationFn: (mode) => likeChallenge(competition, challenge, mode, session, i18next.language),
        onError: (error) => {
            createFeedback('error', error.messages);
        },
        onSuccess: () => {
            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
    });

    // Send a feedback comment
    const feedbackMutation = useMutation({
        mutationFn: (feedback) => sendFeedback(competition, challenge, feedback, session, i18next.language),
        onError: (error) => {
            createFeedback('error', error.messages);
        },
        onSuccess: () => {
            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
    });

    // Send a solution comment
    const solutionMutation = useMutation({
        mutationFn: (solution) => sendSolution(competition, challenge, solution, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('solution-sent-successfully'));

            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
            createFeedback('error', i18next.t('solution-sent-error'));
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    // update a solution
    const solutionUpdateMutation = useMutation({
        mutationFn: ({ guid, updatedSolution }) => updateSolution(competition, challenge, guid, updatedSolution, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('solution-updated-successfully'));

            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
            createFeedback('error', i18next.t('solution-updated-error'));
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    // delete a solution
    const solutionDeleteMutation = useMutation({
        mutationFn: ({ guid }) => deleteSolution(competition, challenge, guid, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('solution-deleted-successfully'));

            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
            createFeedback("error", i18next.t('solution-deleted-error'));
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    // Make a like/dislike for a challenge solution
    const solutionLikeMutation = useMutation({
        mutationFn: ({ solution, mode }) =>
            likeSolution(competition, challenge, solution, mode, session, i18next.language),
        onError: (error) => {
            createFeedback('error', error.messages);
        },
        onSuccess: () => {
            const queryKey = [session, 'competitions', competition, 'challenges', challenge, 'interactions'];
            queryClient.invalidateQueries(queryKey);
        },
    });

    // Validate domain rules
    useEffect(() => {
        if (domainQuery.data) {
            // Force CAM
            (async () => {
                if (domainQuery.data.setting.includes('FORCECAM')) {
                    try {
                        await accessCamera();
                    } catch (_) {
                        createModal(<AccessCamera onCancel={() => removeModal()} />);
                        navigate(-1);
                    }
                }
            })();

            // Disable COPY
            if (domainQuery.data.setting.includes('NOCOPY')) {
                document.addEventListener('copy', (e) => e.preventDefault());
                document.addEventListener('cut', (e) => e.preventDefault());
            }

            // Disable PASTE
            if (domainQuery.data.setting.includes('NOPASTE')) {
                document.addEventListener('paste', (e) => e.preventDefault());
            }

            // Disable SELECTION
            if (domainQuery.data.setting.includes('NOSELECTION')) {
                document.body.style.userSelect = 'none';
            }
        }

        // Clean disablers
        return () => {
            if (domainQuery.data) {
                // Enable COPY
                if (domainQuery.data.setting.includes('NOCOPY')) {
                    document.removeEventListener('copy', (e) => e.preventDefault());
                    document.removeEventListener('cut', (e) => e.preventDefault());
                }

                // Enable PASTE
                if (domainQuery.data.setting.includes('NOPASTE')) {
                    document.removeEventListener('paste', (e) => e.preventDefault());
                }

                // Enable SELECTION
                if (domainQuery.data.setting.includes('NOSELECTION')) {
                    document.body.style.userSelect = 'auto';
                }
            }
        };
    }, [domainQuery.data, createModal, removeModal, navigate]);

    // Load answers and status only on first load
    useEffect(() => {
        if (challengeQuery.data?.answerType === 'text' || challengeQuery.data?.answerType === 'open') {
            setInputAnswers(challengeQuery.data.answers[0].data || '');
        } else if (challengeQuery.data?.answerType === 'unique' || challengeQuery.data?.answerType === 'multiple') {
            setCheckAnswers(
                challengeQuery.data.answers.map((answer) => ({
                    key: answer.guid,
                    checked: answer.checked,
                    value: isMarkdown(answer.data) ? (
                        <Markdown>{answer.data}</Markdown>
                    ) : (
                        <Highlight>{answer.data}</Highlight>
                    ),
                })),
            );
        }

        if (firstLoad.current && challengeQuery.data) {
            const { solved, attempts, maxAttempts } = challengeQuery.data;
            if (solved) {
                createFeedback('message', i18next.t('challenge-already-solved'));
            } else if (maxAttempts !== -1 && attempts >= maxAttempts) {
                createFeedback('message', i18next.t('no-more-attempts'));
            }

            firstLoad.current = false;
        }
    }, [challengeQuery.data, createFeedback]);

    // Reset values on change challenge
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            removeFeedback();
            firstLoad.current = true;
            codeAnswersRef.current = null;
            setInputAnswers('');
            setCheckAnswers([]);
        };
    }, [removeFeedback, competition, challenge]);

    /**
     * Run validate with the structed answer
     * @param {React.FormEvent} e
     */
    const handleSubmitAnswer = (e) => {
        e.preventDefault();

        const type = challengeQuery.data.answerType;
        const answers = [...challengeQuery.data.answers];

        if (type === 'text' || type === 'open') {
            if (!inputAnswers) {
                createFeedback('warn', i18next.t('not-defined-an-answer'));
                return;
            }

            answers[0].data = inputAnswers;
        } else if (type === 'code') {
            const value = codeAnswersRef.current.getValue();
            if (!value) {
                createFeedback('warn', i18next.t('not-defined-an-answer'));
                return;
            }

            createModal(
                <ConfirmationModal
                    message={i18next.t('send-confirmation')}
                    onAccept={() => {
                        answers[0].data = value;
                        validateMutation.mutate(answers);
                        removeModal();
                    }}
                    onCancel={() => {
                        removeModal();
                    }}
                />
            );

            return;
        } else if (challengeQuery.data.answerType === 'unique' || challengeQuery.data.answerType === 'multiple') {
            if (checkAnswers.filter((checkbox) => checkbox.checked).length === 0) {
                createFeedback('warn', i18next.t('not-defined-an-answer'));
                return;
            }

            checkAnswers.forEach((checkbox) => {
                const index = answers.findIndex((answer) => answer.guid === checkbox.key);
                answers[index].checked = checkbox.checked;
            });
        }

        validateMutation.mutate(answers);
    };

    /**
     * Run complie answer for validate the user code
     */
    const handleCompileAnswer = () => {
        const answers = [...challengeQuery.data.answers];
        answers[0].data = codeAnswersRef.current.getValue();
        compileMutation.mutate(answers);
    };

    /**
     * Go to the next challenge
     */
    const handleNextChallenge = () => {
        
        const currentIndex = challengesQuery.data.findIndex((ichallenge) => ichallenge.guid === challenge);
        const nextIndex = (currentIndex + 1) % challengesQuery.data.length;
        navigate(`/competitions/${visibility}/${competition}/challenges/${challengesQuery.data[nextIndex].guid}`);
    };

    /**
     * Activate/deactivate challenge like
     * @param {boolean | null} value
     */
    const handleChallengeLike = (value) => {
        const prev = interactionsQuery.data.like;
        interactionsQuery.data.like = value;
        interactionsQuery.data.likes += prev !== true ? 1 : -1;

        challengeLikeMutation.mutate('like');
    };

    /**
     * Activate/deactivate challenge dislike
     * @param {boolean | null} value
     */
    const handleChallengeDislike = (value) => {
        const prev = interactionsQuery.data.like;
        interactionsQuery.data.like = value;
        if (prev === true) interactionsQuery.data.likes -= 1;

        challengeLikeMutation.mutate('dislike');

        // Send a feedback
        if (value === null) return;
        const onAccept = () => {
            if (!feedbackRef.current.value) return;
            feedbackMutation.mutate(feedbackRef.current.value);
            createFeedback('success', i18next.t('we-receive-suggestion'));
            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(<SendFeedback inputRef={feedbackRef} onAccept={onAccept} onCancel={onCancel} />);
    };

    /**
     *  Send a solution comment
     */
    const handleSolution = () => {
        if (solutionRef.current.value) {
            solutionMutation.mutate(solutionRef.current.value);
        }
    };

    /**
     *  Send a solution that has been updated
     */
    const handleSolutionUpdate = (guid, prevSolution) => {
        const onAccept = () => {
            if (!solutionUpdatedRef.current.value) {    
                return;
            }

            solutionUpdateMutation.mutate({
                guid,
                updatedSolution: solutionUpdatedRef.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <SolutionUpdateModal
                prevSolution={prevSolution}
                solutionUpdatedRef={solutionUpdatedRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    /**
     *  Send a solution that has been updated
     */
    const handleSolutionDelete = (guid, solution) => {
        const onAccept = () => {

            solutionDeleteMutation.mutate({
                guid
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <SolutionDeleteModal
                solution={solution}
                onAccept={onAccept}
                onCancel={onCancel}
            />
        );
    };

    /**
     * Activate/deactivate challenge solution like
     * @param {boolean | null} value
     * @param {string} index
     * @param {string} solution
     */
    const handleSolutionLike = (value, index, solution) => {
        const prev = interactionsQuery.data.solutions[index].like;
        interactionsQuery.data.solutions[index].like = value;
        interactionsQuery.data.solutions[index].likes += prev !== true ? 1 : -1;
        if (prev === false) interactionsQuery.data.solutions[index].dislikes -= 1;

        solutionLikeMutation.mutate({ solution, mode: 'like' });
    };

    /**
     * Activate/deactivate challenge solution dislike
     * @param {boolean | null} value
     * @param {string} index
     * @param {string} solution
     */
    const handleSolutionDislike = (value, index, solution) => {
        const prev = interactionsQuery.data.solutions[index].like;
        interactionsQuery.data.solutions[index].like = value;
        interactionsQuery.data.solutions[index].dislikes += prev !== false ? 1 : -1;
        if (prev === true) interactionsQuery.data.solutions[index].likes -= 1;

        solutionLikeMutation.mutate({ solution, mode: 'dislike' });
    };

    // Challenge error
    if (challengeQuery.error) {
        createFeedback(challengeQuery.error.context, challengeQuery.error.messages, true);
        return <Navigate to={`/competitions/${visibility}`} />;
    }

    // Choose answer type
    let nextChallenge = challengesQuery.isLoading;
    if (challengeQuery.data) {
        if (challengeQuery.data.solved === null) {
            nextChallenge = challengeQuery.data.sent;
        } else {
            nextChallenge = challengeQuery.data.solved;
        }
    }

    // Choose answer form
    let answerForm;
    if (challengeQuery.isLoading) {
        answerForm = (
            <Container>
                <div className="w-full p-5">
                    <Checkbox isLoading color="green" unique values={[]} onCheck={() => {}} />
                </div>
            </Container>
        );
    } else if (
        challengeQuery.data?.solved ||
        (challengeQuery.data?.maxAttempts !== -1 && challengeQuery.data?.attempts >= challengeQuery.data?.maxAttempts)
    ) {
        answerForm = null;
    } else if (challengeQuery.data?.answerType === 'text') {
        answerForm = (
            <Input
                color="white"
                type="text"
                focus
                placeholder={i18next.t('type-your-answer')}
                value={inputAnswers}
                onType={(value) => setInputAnswers(value)}
            />
        );
    } else if (challengeQuery.data?.answerType === 'open') {
        answerForm = (
            <Input
                color="white"
                type="textarea"
                focus
                placeholder={i18next.t('type-your-answer')}
                value={inputAnswers}
                onType={(value) => setInputAnswers(value)}
            />
        );
    } else if (challengeQuery.data?.answerType === 'code') {
        answerForm = (
            <CodeEditor
                codeRef={codeAnswersRef}
                height={500}
                language={challengeQuery.data.answers[0].language.editor}
                defaultValue={challengeQuery.data.answers[0].data || challengeQuery.data.answers[0].language.template}
                defaultLines={[{ type: 'text', text: i18next.t('terminal-purpose') }]}
            />
        );
    } else if (challengeQuery.data?.answerType === 'unique' || challengeQuery.data?.answerType === 'multiple') {
        answerForm = (
            <Container>
                <div className="w-full p-5">
                    <Checkbox
                        useKeys
                        color="green"
                        unique={challengeQuery.data.answerType === 'unique'}
                        values={checkAnswers}
                        onCheck={(values) => setCheckAnswers(values)}
                    />
                </div>
            </Container>
        );
    }

    return (
        <div className="w-full flex flex-col gap-10">
            <Spinner isLoading={isSending} />
            <div className="w-full flex flex-col gap-5">
                <div className="w-full flex gap-5 items-center">
                    <Title size="xl" isLoading={challengeQuery.isLoading}>
                        {challengeQuery.data?.name || ''}
                    </Title>
                    <Tag size="md" isLoading={challengeQuery.isLoading}>
                        {challengeQuery.data?.category || ''}
                    </Tag>
                </div>

                {challengeQuery.data && (
                    <div className="w-full flex flex-wrap gap-5 items-center select-none">
                        {challengeQuery.data.solved && (
                            <div className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faCheck} className="text-smgreen-dark" />
                                <p className="font-semibold">{i18next.t('solved')}</p>
                            </div>
                        )}

                        {challengeQuery.data.sent && (
                            <div className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faPaperPlane} className="text-smblue-dark" />
                                <p className="font-semibold">{i18next.t('sent')}</p>
                            </div>
                        )}

                        <div
                            title={i18next.t('attempts-remaining')}
                            className="min-w-fit w-min flex gap-2 items-center"
                        >
                            <FontAwesomeIcon icon={faHeart} className="text-smred-dark" />
                            {challengeQuery.data.maxAttempts === -1 ? (
                                <FontAwesomeIcon icon={faInfinity} />
                            ) : (
                                <p className="font-semibold">
                                    {challengeQuery.data.attempts} / {challengeQuery.data.maxAttempts}
                                </p>
                            )}
                        </div>

                        {challengeQuery.data.score && (
                            <div title={i18next.t('score')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faStar} className="text-smyellow-dark" />
                                <p className="font-semibold">{challengeQuery.data.score} pts</p>
                            </div>
                        )}

                        {challengeQuery.data.difficulty && (
                            <div title={i18next.t('difficulty')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faFire} className="text-smorange-dark" />
                                <p className="font-semibold">{i18next.t(challengeQuery.data.difficulty)}</p>
                            </div>
                        )}

                        {challengeQuery.data.views !== null && (
                            <div title={i18next.t('views')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faEye} className="text-smgray-dark" />
                                <p className="font-semibold">{challengeQuery.data.views}</p>
                            </div>
                        )}

                        {challengeQuery.data.successes !== null && (
                            <div title={i18next.t('successes')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faCheckDouble} className="text-smgreen-dark" />
                                <p className="font-semibold">{i18next.t(challengeQuery.data.successes)}</p>
                            </div>
                        )}

                        {challengeQuery.data.failures !== null && (
                            <div title={i18next.t('failures')} className="min-w-fit w-min flex gap-2 items-center">
                                <FontAwesomeIcon icon={faXmark} className="text-smred-dark" size="lg" />
                                <p className="font-semibold">{i18next.t(challengeQuery.data.failures)}</p>
                            </div>
                        )}

                        <div title={i18next.t('author')} className="min-w-fit w-min flex gap-2 items-center">
                            <FontAwesomeIcon icon={faUser} className="text-smgray-dark" />
                            <p className="font-semibold">{challengeQuery.data.author}</p>
                        </div>
                    </div>
                )}
                <Container>
                    {challengeQuery.isLoading ? (
                        <div className="w-full p-5">
                            <Skeleton width={300} className="mb-5" />
                            <Skeleton count={3} className="mb-2" />
                            <Skeleton width={500} />
                        </div>
                    ) : (
                        <div className="w-full p-5 flex flex-col gap-5">
                            {isMarkdown(challengeQuery.data.description) ? (
                                <Markdown>{challengeQuery.data.description}</Markdown>
                            ) : (
                                <Highlight>{challengeQuery.data.description}</Highlight>
                            )}
                            {challengeQuery.data.clarifications !== null && challengeQuery.data.clarifications.trim() && (
                                <div className='mt-5'>
                                    <Tag color="green" size="lg">{i18next.t('clarifications')}</Tag>
                                    <div className="mt-2 p-2 bg-lime-50 rounded">
                                        {isMarkdown(challengeQuery.data.clarifications) ? (
                                            <Markdown>{challengeQuery.data.clarifications}</Markdown>
                                        ) : (
                                            <Highlight>{challengeQuery.data.clarifications}</Highlight>
                                        )}
                                    </div>
                                </div>
                            )}
                            {challengeQuery.data.attachments.length > 0 && (
                                <div className="w-1/3 flex flex-col gap-2">
                                    {challengeQuery.data.attachments.map((attachment) => {
                                        const key = `attachment:${attachment.name}`;
                                        if (/(png|jpg|jpeg|gif)/.test(attachment.extension)) {
                                            return <Image key={key} alt={attachment.name} src={attachment.path} />;
                                        }

                                        return (
                                            <Link key={key} color="black" to={attachment.path}>
                                                {`${attachment.name}.${attachment.extension}`}
                                            </Link>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </Container>
                <div className="w-full flex flex-col gap-5">
                    {answerForm}
                    {feedbackElement}

                    <div className="w-full flex justify-between items-center">
                        <Like
                            isLoading={interactionsQuery.isLoading}
                            value={interactionsQuery.data?.like}
                            disabled={challengeLikeMutation.isLoading || interactionsQuery.isFetching}
                            likes={interactionsQuery.data?.likes}
                            onLike={handleChallengeLike}
                            onDislike={handleChallengeDislike}
                        />

                        <div className="flex gap-3 items-center">
                            {answerForm && challengeQuery.data?.answerType === 'code' && (
                                <Button
                                    isLoading={compileMutation.isLoading}
                                    color="white"
                                    icon={faPlay}
                                    onClick={handleCompileAnswer}
                                >
                                    {i18next.t('debug-code')}
                                </Button>
                            )}

                            {answerForm && (
                                <Button
                                    isLoading={
                                        challengeQuery.isFetching ||
                                        challengesQuery.isFetching ||
                                        validateMutation.isLoading
                                    }
                                    color={nextChallenge ? 'white' : 'green'}
                                    icon={faPaperPlane}
                                    onClick={handleSubmitAnswer}
                                >
                                    {i18next.t('send-answer')}
                                </Button>
                            )}

                            {nextChallenge && (
                                <Button
                                    isLoading={challengesQuery.isLoading}
                                    color="green"
                                    icon={faForward}
                                    onClick={handleNextChallenge}
                                >
                                    {i18next.t('next-challenge')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {challengeQuery.data?.solved && interactionsQuery.data?.commented === false && (
                    <div className="w-full flex flex-col gap-3">
                        <Input
                            inputRef={solutionRef}
                            color="white"
                            type="textarea"
                            focus
                            placeholder={i18next.t('make-sure-comment-appropriate')}
                        />

                        <Button
                            isLoading={solutionMutation.isLoading || interactionsQuery.isFetching}
                            color="green"
                            icon={faPaperPlane}
                            onClick={handleSolution}
                        >
                            {i18next.t('send-solution')}
                        </Button>
                    </div>
                )}
            </div>

            {challengeQuery.data?.solved && interactionsQuery.data?.solutions.length > 0 && (
                <div className="w-full flex flex-col gap-5">
                    <Title size="xl">{i18next.t('community-solutions')}</Title>
                    {interactionsQuery.data.solutions.map((solution, index) => (
                        <div key={`solution:${solution.guid}`} className="w-full flex gap-3">
                            <img
                                alt={solution.user.name}
                                src={`https://www.gravatar.com/avatar/${solution.user.hash}?d=retro`}
                                className="h-10 rounded-full"
                            />
                            <div className="w-full p-4 border rounded-lg border-zinc-300">
                                <div className="w-full flex items-center">
                                    <div className="w-9/12 md:w-10/12 flex gap-3 items-center">
                                        {solution.banned ? (
                                            <>
                                                <s>
                                                    <Title size="md">{solution.user.name}</Title>
                                                </s>
                                                <s className="text-sm line-through">
                                                    {i18next.t('x-ago').replace('x', formatTime(solution.postedAgo, 1))}
                                                </s>
                                            </>
                                        ) : (
                                            <>
                                                <Title size="md">{solution.user.name}</Title>
                                                <i className="text-sm">
                                                    {i18next.t('x-ago').replace('x', formatTime(solution.postedAgo, 1))}
                                                </i>
                                            </>
                                        )}
                                    </div>
                                    {solution.personal ? (
                                        <div className="w-3/12 md:w-2/12 flex gap-x-3 items-center">
                                            <Button 
                                                isLoading={interactionsQuery.isLoading}
                                                color="blue"
                                                icon={faPenToSquare}
                                                title={i18next.t('edit')}
                                                onClick={() => handleSolutionUpdate(solution.guid, solution.solution)}
                                            />  
                                            <Button 
                                                isLoading={interactionsQuery.isLoading}
                                                color="red"
                                                icon={faTrash}
                                                title={i18next.t('delete')}
                                                onClick={() => handleSolutionDelete(solution.guid, solution.solution)}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <p className="mt-3 mb-5 whitespace-pre-wrap">{solution.solution}</p>

                                <Like
                                    size="sm"
                                    value={solution.like}
                                    disabled={solutionLikeMutation.isLoading || interactionsQuery.isFetching}
                                    likes={solution.likes}
                                    onLike={(value) => handleSolutionLike(value, index, solution.guid)}
                                    onDislike={(value) => handleSolutionDislike(value, index, solution.guid)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Challenge;
