import { faBan, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import FormTemplate from '../templates/FormTemplate';

/**
 * NotFound page shows an alert that explain that the page that the user is trying to search doesn´t exist.
 */
function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <FormTemplate>
            <div className="flex flex-col gap-7 items-center">
                <div className="flex flex-col gap-3">
                    <FontAwesomeIcon icon={faBan} size="6x" className="text-smred-dark" />
                    <p className="sm:text-2xl text-lg text-center">{i18next.t('page-not-found')}</p>
                </div>
                <button type="button" onClick={() => navigate(-1)} className="flex gap-2 items-center outline-none">
                    <FontAwesomeIcon icon={faRotateLeft} size="lg" />
                    <p>{i18next.t('go-back')}</p>
                </button>
            </div>
        </FormTemplate>
    );
}

export default NotFoundPage;
