import { faStar as FaStar } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark, faStar, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Add this import
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * FinishedCompetitionDetailsModal allows the user to see the finished competition results
 * @param {{
 *      session: string,
 *      competitionTitle:  string;
 *      competitionScore: number;
 *      competitionDate: string;
 *      competitionCategories: {
 *              "name": string,
 *              "score": number,
 *              "total": number
 *      }[]
 *      onCancel: () => void;
 * }} props
 */
function FinishedCompetitionDetailsModal({
    competitionTitle,
    competitionScore,
    competitionDate,
    competitionCategories,
    onCancel,
}) {
    const starsCalc = (categoryScore) => {
        const solidStars = Math.floor(categoryScore / 20);
        const remainder = categoryScore % 20;
        const halfStars = remainder >= 10 ? 1 : 0;
        const emptyStars = 5 - solidStars - halfStars;

        const stars = [];

        for (let i = 0; i < solidStars; i += 1) {
            stars.push(<FontAwesomeIcon key={`solid-star-${i}`} size="2x" icon={faStar} />);
        }

        if (halfStars === 1) {
            stars.push(<FontAwesomeIcon key="half-star" size="2x" icon={faStarHalfStroke} />);
        }

        for (let i = 0; i < emptyStars; i += 1) {
            stars.push(<FontAwesomeIcon key={`empty-star-${i}`} size="2x" icon={FaStar} />);
        }

        return stars;
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center justify-center text-center gap-x-2">
                <Title size="2xl">{competitionTitle}</Title>
            </div>
            <div className="w-full flex flex-row justify-around items-center">
                <div className="flex flex-col justify-center text-center items-center">
                    <p className="text-xl">{i18next.t('presentation-date')}</p>
                    <Title size="2xl">{competitionDate}</Title>
                </div>
                <div className="flex flex-col justify-center text-center items-center">
                    <Title size="2xl">{competitionScore}%</Title>
                    <p>{i18next.t('total-score')}</p>
                </div>
            </div>
            <div className="flex w-full flex-col justify-center items-center">
                {competitionCategories.map((category, index) => (
                    <div key={category.name} className="flex w-full flex-col justify-center items-center border-b-2 ">
                        <p className="text-3xl my-5">{category.name}</p>
                        <div className="flex flex-row w-full justify-around items-center mb-5">
                            <p className="text-2xl">
                                {category.score}/{category.total.toFixed(2)}
                            </p>
                            <div className="flex">
                                {starsCalc(Math.floor((category.score / category.total.toFixed(2)) * 100))}
                            </div>
                            <p className="text-2xl">
                                {Math.floor((category.score / category.total.toFixed(2)) * 100)}%
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faCircleXmark} onClick={onCancel}>
                    {i18next.t('close')}
                </Button>
            </div>
        </div>
    );
}

FinishedCompetitionDetailsModal.propTypes = {
    competitionTitle: PropTypes.string.isRequired,
    competitionScore: PropTypes.number.isRequired,
    competitionDate: PropTypes.string.isRequired,
    competitionCategories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            score: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default FinishedCompetitionDetailsModal;
