/**
 * getProfessionalProfile gets all the information of the logged-in user
 * @method GET
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      salary:  number | null;
 *      englishLevel: 'BASIC' | 'MEDIUM' | 'HIGH' | 'NATIVE' | 'UNKNOWN' | null;
 *      currency: 'COP' | 'USD' | 'EUR' | 'ARS' | 'CLP' | 'PEN' | 'BRL' | 'GBP' | 'CAD' | null;
 *      city: string | null;
 * }>}
 */ 
export default async function getUser(jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/viewProfessionalProfile`;
       const method = 'GET';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           Authorization: jwt,
       };

       const response = await fetch(url, { method, headers });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}
