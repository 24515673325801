import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import Register from '../organisms/Register';
import FormTemplate from '../templates/FormTemplate';

/**
 * Register page shows a form to create an user with username/email/password or Google GSI
 */
function RegisterPage() {
    const { session } = useSession();

    // Handle session
    if (session) {
        return <Navigate to="/competitions/private" />;
    }

    return (
        <FormTemplate>
            <Register />
        </FormTemplate>
    );
}

export default RegisterPage;
