/**
 * getUser gets all the information of the logged in user
 * @method GET
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      pending: boolean;
 *      guid: string;
 *      username: string;
 *      phone: string | null;
 *      email: string;
 *      firstName: string | null;
 *      lastName: string | null;
 *      birthday: string | null;
 *      sex: '_MALE' | '_FEMALE' | '_UNKNOWN' | null;
 *      country: number;
 *      biography: string | null;
 *      web: string | null;
 *      twitter: string | null;
 *      facebook: string | null;
 *      linkedin: string | null;
 *      github:  string | null;
 *      roles: string[];
 * }>}
 */
export default async function getUser(jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/view`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
