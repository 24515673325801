import PropTypes from 'prop-types';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

/**
 * Navbar template encapsulates any content next to a navbar
 * @param {{
 *      routes: {
 *          isLoading: boolean | undefined;
 *          link: string | undefined;
 *          text: string;
 *      }[];
 *      children: React.ReactNode;
 * }} props
 */
function NavbarTemplate({ routes, children }) {
    return (
        <>
            <div className="min-h-screen flex flex-col">
                <Navbar routes={routes} />
                <div className="sm:p-10 p-5">{children}</div>
            </div>

            <Footer />
        </>
    );
}

NavbarTemplate.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            isLoading: PropTypes.bool,
            link: PropTypes.string,
            text: PropTypes.string.isRequired,
        }),
    ),
    children: PropTypes.node.isRequired,
};

NavbarTemplate.defaultProps = {
    routes: undefined,
};

export default NavbarTemplate;
