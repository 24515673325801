import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import Container from '../atoms/Container';
import Title from '../atoms/Title';

/**
 * Filters molecule creates a filter system filtering by checkboxes
 * @param {{
 *      isLoading: boolean;
 *      startOpen: string;
 *      filters: {
 *          key: string;
 *          title: string;
 *          unique: boolean;
 *          checkboxes: {
 *              key: string;
 *              checked: boolean;
 *              value: React.ReactNode;
 *          }[];
 *      }[];
 *      onFilter: (filters: {
 *          key: string;
 *          title: string;
 *          unique: boolean;
 *          checkboxes: {
 *              key: string;
 *              checked: boolean;
 *              value: React.ReactNode;
 *          }[];
 *      }[]) => void;
 * }} props
 */
function Filters({ isLoading, startOpen, filters, onFilter }) {
    const [displayFilters, setDisplayFilters] = useState(startOpen);

    /**
     * Set all filter checks to false
     */
    const handleResetFilters = () => {
        const resetedFilters = [...filters];
        for (let i = 0; i < resetedFilters.length; i += 1) {
            for (let j = 0; j < resetedFilters[i].checkboxes.length; j += 1) {
                resetedFilters[i].checkboxes[j].checked = false;
            }
        }

        onFilter(resetedFilters);
    };

    return (
        <div className="flex flex-col gap-3">
            <div className="2xl:hidden flex flex-row gap-6 items-center">
                <Title size="xl">{i18next.t('filter')}</Title>
                <Button color="blue" circle icon={faFilter} onClick={() => setDisplayFilters(!displayFilters)} />
            </div>
            <div className={classNames(displayFilters ? 'block' : '2xl:block hidden')}>
                <Container>
                    <div className="relative w-full">
                        {!isLoading && (
                            <button
                                type="button"
                                onClick={handleResetFilters}
                                className="absolute right-0 p-5 outline-none select-none"
                            >
                                <FontAwesomeIcon icon={faRefresh} size="lg" />
                            </button>
                        )}

                        <div className="flex flex-col divide-y">
                            {filters.map((filter, index) => (
                                <div key={`filter:${filter.key || index}`} className="flex flex-col gap-4 p-5 text-sm">
                                    <Title isLoading={isLoading} size="sm">
                                        {filter.title || ''}
                                    </Title>

                                    <Checkbox
                                        isLoading={isLoading}
                                        color="green"
                                        unique={filter.unique || false}
                                        grid={filter.checkboxes?.length > 3}
                                        values={filter.checkboxes || []}
                                        onCheck={(values) => {
                                            const newFilters = [...filters];
                                            newFilters[index].checkboxes = values;
                                            onFilter(newFilters);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

Filters.propTypes = {
    isLoading: PropTypes.bool,
    startOpen: PropTypes.bool,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            unique: PropTypes.bool,
            checkboxes: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    value: PropTypes.node.isRequired,
                    checked: PropTypes.bool.isRequired,
                }),
            ),
        }),
    ),
    onFilter: PropTypes.func.isRequired,
};

Filters.defaultProps = {
    isLoading: false,
    startOpen: false,
    filters: [{}, {}, {}],
};

export default Filters;
