import classNames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/**
 * Title atom creates a title with a loading state
 * @param {{
 *      isLoading: boolean;
 *      size: '2xl' | 'xl' | 'lg' | 'md' | 'sm';
 *      children: string;
 * }} props
 */
function Title({ isLoading, size, children }) {
    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                borderRadius={6}
                containerClassName={classNames('w-full flex', {
                    'sm:h-9 h-8': size === '2xl',
                    'sm:h-8 h-7': size === 'xl',
                    'sm:h-7 h-6': size === 'lg',
                    'sm:h-6 h-5': size === 'md',
                    'sm:h-5 h-4': size === 'sm',
                })}
            />
        );
    }

    return (
        <h1
            className={classNames('min-w-fit h-', {
                'sm:text-3xl text-2xl font-bold': size === '2xl',
                'sm:text-2xl text-xl font-bold': size === 'xl',
                'sm:text-lg text-base font-semibold': size === 'lg',
                'sm:text-base text-sm font-semibold': size === 'md',
                'sm:text-sm text-xs font-semibold': size === 'sm',
            })}
        >
            {children}
        </h1>
    );
}

Title.propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf(['2xl', 'xl', 'lg', 'md', 'sm']).isRequired,
    children: PropTypes.string.isRequired,
};

Title.defaultProps = {
    isLoading: false,
};

export default Title;
