/**
 * updateEducation updates a user's education.
 * @method PUT
 * @param {string} educationID
 * @param {string} institutionName
 * @param {string} degree
 * @param {string} area
 * @param {string} startDate
 * @param {string|null} endDate
 * @param {string|null} description
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function updateEducation(educationID, institutionName, degree, area, startDate, endDate, description, jwt, language) {
   try {
    const url = `${process.env.REACT_APP_APP_URL}/api/v1/users/education/${educationID}`;
       const method = 'PUT';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'User-Agent': window.navigator.userAgent,
           Authorization: jwt,
       };

       const body = JSON.stringify({ institutionName, degree, area, startDate, endDate, description });

       const response = await fetch(url, { method, headers, body });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}