import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const colors = ['green', 'blue', 'orange', 'red', 'yellow', 'gray'];

/**
 * Tag atom creates a text bubble with a loading state
 * @param {{
 *      isLoading: boolean;
 *      size: 'lg' | 'md' | 'sm';
 *      color: 'green' | 'blue' | 'orange' | 'red' | 'yellow' | 'gray' | undefined;
 *      shadow: boolean;
 *      onClick: (() => void) | undefined;
 *      children: React.ReactNode;
 * }} props
 */
function Tag({ isLoading, size, color, shadow, onClick, children }) {
    const [background, setBackground] = useState();
    const tagRef = useRef();

    useEffect(() => {
        if (!color) {
            setBackground(colors[Math.floor(Math.random() * colors.length)]);
            return;
        }

        setBackground(color);
    }, [color]);

    /**
     * Run onClick when user clicks the Card
     * @type {(event: MouseEvent) => void}
     */
    const handleClick = useCallback(
        (event) => {
            if (tagRef.current && tagRef.current.contains(event.target) && onClick) {
                onClick();
            }
        },
        [onClick],
    );

    // Add click listeners
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                borderRadius={9999}
                containerClassName={classNames('flex flex-shrink-0', {
                    'sm:w-40 w-36 sm:h-10 h-9': size === 'lg',
                    'sm:w-32 w-28 sm:h-9 h-8': size === 'md',
                    'sm:w-28 w-24 sm:h-8 h-7': size === 'sm',
                })}
            />
        );
    }

    return (
        <div
            ref={tagRef}
            className={classNames('min-w-fit w-min h-min py-2 font-bold rounded-full select-none', {
                'cursor-pointer': !!onClick,
                'shadow-md': shadow,
                'bg-smgreen text-smgreen-dark': background === 'green',
                'bg-smblue text-smblue-dark': background === 'blue',
                'bg-smorange text-smorange-dark': background === 'orange',
                'bg-smred text-smred-dark': background === 'red',
                'bg-smyellow text-smyellow-darker ': background === 'yellow',
                'bg-smgray text-smgray-dark': background === 'gray',
                'px-8 sm:text-base text-sm': size === 'lg',
                'px-6 sm:text-sm text-xs': size === 'md',
                'px-5 sm:text-xs text-[10px]': size === 'sm',
            })}
        >
            {children}
        </div>
    );
}

Tag.propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md', 'sm']).isRequired,
    color: PropTypes.oneOf(['green', 'blue', 'orange', 'red', 'yellow', 'gray']),
    shadow: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Tag.defaultProps = {
    isLoading: false,
    color: undefined,
    shadow: false,
    onClick: undefined,
};

export default Tag;
