// BanModal.jsx

import { faBan, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Select from '../atoms/Select';

/**
 * BanModal display the options to ban a user.
 * @param {{
 *      banReasonRef: React.RefObject<HTMLSelectElement>;
 *      banEndRef: React.RefObject<HTMLInputElement>;
 *      banCommRef: React.RefObject<HTMLTextAreaElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function BanModal({ 
    banReasonRef,
    banEndRef,
    banCommRef,
    onAccept, 
    onCancel 
}) { 

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();


    const [selectedBanReason, setSelectedBanReason] = useState('SPAM');

    // Ban Reason
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        banReasonRef.current = selectedBanReason;
    }, [selectedBanReason, banReasonRef]);


    const validate = () => {

        const validationErrors = [];

        if (!banReasonRef.current) {
            validationErrors.push(i18next.t('ban-reason'));
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept(banEndRef.current.value, banCommRef.current.value, );
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
            <FontAwesomeIcon icon={faBan} size="xl" className="text-smred-dark"  />
                <Title size="lg">{i18next.t('ban-user')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('ban-reason')}</Title>
                <Select
                    color='gray'
                    selected={selectedBanReason || 'SPAM'}
                    // eslint-disable-next-line no-param-reassign
                    onSelect={(selected) => { setSelectedBanReason(selected); }}
                    options={{
                        [null]: {
                            SPAM: "SPAM",
                            MISBEHAVIOR: i18next.t('misbehavior'),
                            CHEATING: i18next.t('cheating'),
                            DUPLICATED: i18next.t('duplicated'),
                            UNKNOWN: i18next.t('unknown'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('end-date')}</Title>
                <Input
                    inputRef={banEndRef}
                    color="gray"
                    type="datetime-local"
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <Input
                    inputRef={banCommRef}
                    color="gray"
                    type="textarea"
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

BanModal.propTypes = {
    banReasonRef: PropTypes.shape().isRequired,
    banEndRef: PropTypes.shape().isRequired,
    banCommRef: PropTypes.shape().isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default BanModal;