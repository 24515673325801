/**
 * Update user's password
 * @method PUT
 * @param {string} password
 * @param {string} newPassword
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      messages: string;
 *      context: 'success' | 'message' | 'warn' | 'error';
 * }>}
 */
export default async function updatePassword(password, newPassword, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/change/password`;
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            'User-Agent': window.navigator.userAgent,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers, body: JSON.stringify({ password, newPassword }) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
