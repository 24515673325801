import { faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { useRef } from 'react';
import useFeedback from '../../hooks/useFeedback';
import useRecaptcha from '../../hooks/useRecaptcha';
import recoverAccount from '../../services/recoverAccount';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Link from '../atoms/Link';
import Title from '../atoms/Title';

/**
 * RecoverAccount organism creates a form to recover an account
 */
function RecoverAccount() {
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const recaptcha = useRecaptcha({ mode: 'password_reset' });
    const emailRef = useRef();

    // Send email for recover account
    const recoverMutation = useMutation({
        mutationFn: ({ email, recaptchaToken }) => recoverAccount(email, recaptchaToken, i18next.language),
        onMutate: () => {
            removeFeedback();
        },
        onSuccess: (data) => {
            createFeedback(data.context, data.messages);
        },
        onError: () => {
            createFeedback('error', i18next.t('something-went-wrong'));
            emailRef.current.value = '';
            emailRef.current.focus();
        },
    });

    /**
     * Run recaptcha for recover
     * @param {React.FormEvent} e
     */
    const handleRecover = async (e) => {
        e.preventDefault();

        const email = emailRef.current?.value;

        // Validate empty email
        if (!email) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        const token = await recaptcha();
        recoverMutation.mutate({ email, recaptchaToken: token });
        createFeedback('error', i18next.t('something-went-wrong'));
    };

    return (
        <div className="w-full flex flex-col gap-5">
            <Container>
                <div className="w-full px-5 py-7 flex flex-col gap-7 items-center">
                    <div className="w-full flex flex-col gap-2 items-center">
                        <Title size="xl">{i18next.t('recover-account')}</Title>
                        <Title size="sm">{i18next.t('you-will-recover-your-account')}</Title>
                    </div>

                    <form onSubmit={handleRecover} className="w-full flex flex-col gap-3">
                        <Input
                            inputRef={emailRef}
                            color="gray"
                            type="email"
                            complete="username"
                            focus
                            placeholder={i18next.t('email')}
                        />

                        <Button isLoading={recoverMutation.isLoading} submit color="green" icon={faUnlockKeyhole}>
                            {i18next.t('recover-account')}
                        </Button>
                    </form>

                    <div className="w-full flex justify-center">
                        <Link color="black" to="/auth/sign-in">
                            {i18next.t('sign-in')}
                        </Link>
                    </div>
                </div>
            </Container>

            {feedbackElement}
        </div>
    );
}

export default RecoverAccount;
