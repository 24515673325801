// CategoryCreateModal.jsx

import { faBan, faPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

/**
 * CategoryCreateModal allows the user to create a new study
 * @param {{
 *      nameRef: React.RefObject<HTMLInputElement>;
 *      descriptionRef: React.RefObject<HTMLTextAreaElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function CategoryCreateModal({
    nameRef,
    descriptionRef,
    onAccept,
    onCancel,
}) {

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const validate = () => {

        const validationErrors = [];

        if (!nameRef.current.value) {
            validationErrors.push(i18next.t('category-name'));
        }

        if (nameRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        if (descriptionRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);

            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept();
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPlus} size="xl" className="text-smgreen-dark" />
                <Title size="lg">{i18next.t('category-create')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('category-name')}</Title>
                <Input
                    focus
                    inputRef={nameRef}
                    color="gray"
                    type="text"
                    placeholder={i18next.t('category-ex-name')}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <Input
                    inputRef={descriptionRef}
                    color="gray"
                    type="textarea"
                    placeholder={i18next.t('category-ex-description')}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="green" icon={faPaperPlane} onClick={handleAccept}>
                    {i18next.t('save')}
                </Button>
            </div>
        </div>
    );
}

CategoryCreateModal.propTypes = {
    nameRef: PropTypes.shape().isRequired,
    descriptionRef: PropTypes.shape().isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CategoryCreateModal;