/**
 * viewAdminCompetition gets the competition view
 * @method GET
 * @param {string} competitionGUID}
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      guid: string;
*      name: string;
*      score: number;
*      description: string | null;
*      status: string;
*      answers: number | null;
*      typeChallenge: string | null;
*      typeAnswer: string | null;
*      categories: {
*          challenges: {
*              name: string;
*              guid: string;
*              score: string;
*              description: string | null;
*              status: string;
*          }[] | null;
*      }[] | null;
* }>}
*/
export default async function viewAdminCompetition(competitionGUID, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competition/${competitionGUID}`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
