import { faBan, faEnvelopeOpenText, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Input from '../atoms/Input';

/**
 * SendFeedback modal allows the user to submit a suggestion
 * @param {{
 *      inputRef: React.RefObject<HTMLTextAreaElement>;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function SendFeedback({ inputRef, onAccept, onCancel }) {
    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex justify-center py-3">
                <FontAwesomeIcon icon={faEnvelopeOpenText} size="5x" className="text-smgreen-dark" />
            </div>
            <Input
                inputRef={inputRef}
                color="gray"
                type="textarea"
                focus
                placeholder={i18next.t('sorry-bad-experience')}
            />
            <div className="w-full flex gap-5">
                <Button color="white" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="green" icon={faPaperPlane} onClick={onAccept}>
                    {i18next.t('send-feedback')}
                </Button>
            </div>
        </div>
    );
}

SendFeedback.propTypes = {
    inputRef: PropTypes.shape().isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SendFeedback;
