import { faArrowsRotate, faBan, faCircle, faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import getAdminChallenges from '../../services/getAdminChallenges';
import createAdminCompetition from '../../services/createAdminCompetition';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Title from '../atoms/Title';
import NavbarTemplate from '../templates/NavbarTemplate';
import datetimeToUTC from '../../utils/datetimeToUTC';
import Banner from '../atoms/Banner';
import Container from '../atoms/Container';
import Repeat from '../atoms/Repeat';
import Tag from '../atoms/Tag';
import Pager from '../molecules/Pager';
import ChallengeAdminSearch from '../organisms/ChallengeAdminSearch';
import getUser from '../../services/getUser';
import CardToggle from '../molecules/CardToggle';
import useModal from '../../hooks/useModal';
import ChallengeViewModal from '../modals/ChallengeViewModal';
import Toast from '../atoms/Toast';
import Redirect from '../molecules/Redirect';
import Spinner from '../atoms/Spinner';


function CompetitionCreatePage() {

    function getColorForStatus(status) {
        switch (status) {
            case 'PRODUCTION':
                return 'green';
            case 'DELETED':
                return 'red';
            case 'QUEUE':
                return 'blue';
            case 'NOT_APPROVED':
                return 'orange';
            case 'DISABLED':
                return 'gray';
            case 'STAGING':
                return 'yellow';
            default:
                return 'gray';
        }
    }

    function getColorForType(type) {
        switch (type) {
            case 'HASH':
                return 'yellow';
            case 'REGEXP':
                return 'red';
            case 'DYNAMIC':
                return 'green';
            case 'PLAIN':
                return 'blue';
            case 'CODE':
                return 'gray';
            case 'OPEN':
                return 'orange';
            default:
                return 'gray';
        }
    }

    function getColorForScore(score) {
        if (score >= 75) {
            return 'green';
        }
        if (score >= 50) {
            return 'yellow';
        }
        if (score >= 25) {
            return 'orange';
        }
        return 'red';
    }

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const { session } = useSession();
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const nameCompRef = useRef();
    const statusRef = useRef();
    const visibilityRef = useRef();
    const descriptionCompRef = useRef();
    const durationRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();

    const [selectedStatus, setSelectedStatus] = useState('ACTIVE');
    const [selectedVisibility, setSelectedVisibility] = useState('PUBLIC');

    const [errorChallengesLoading, setErrorChallengesLoading] = useState(false);

    const [challenges, setChallenges] = useState([]);
    const [challengeFeedbackMessage, setChallengeFeedbackMessage] = useState(null);
    const [challengePage, setChallengePage] = useState(0);    
    const perPage  = 10;
    
    const [selectedChallenges, setSelectedChallenges] = useState([]);
    
    const [challengeFilters, setChallengeFilters] = useState({
        challengeName: '',
        categories: [],
        answerType: '',
    });
    
    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    const challengeQueryKey = [session, 'admin', 'challenges', challengePage, perPage, challengeFilters];

    // Get challenges
    const challengesQuery = useQuery({
        queryKey: challengeQueryKey,
        queryFn: () => getAdminChallenges(
            challengePage, 
            perPage, 
            session, 
            i18next.language,
            challengeFilters.challengeName || null, 
            challengeFilters.categories || null, 
            challengeFilters.answerType || null),
        enabled:!!session,
        retry: 3,
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
        onError: (error, variables, context) => {
            removeFeedback();
            if (context?.failureCount >= 3) {
                setErrorChallengesLoading(true);
            }
        },
        onSuccess: (data) => {
            setErrorChallengesLoading(false);
            if (data.challenges.length > 0) {
                setChallengeFeedbackMessage({ type: 'success', message: i18next.t('challenges-found') });
            } else {
                setChallengeFeedbackMessage({ type: 'warn', message: i18next.t('no-challenges-found') });
            }
        },
        onSettled: () => {
        }
    });

    const handleChallengesReload = () => {
        setErrorChallengesLoading(false);
        challengesQuery.refetch();
    };

    const handleChallengeSelect = (challenge) => {
        setSelectedChallenges((prevSelected) => {
            if (prevSelected.some((c) => c.chaGUID === challenge.chaGUID)) {
                setToastMessage(i18next.t('challenge-already-selected'));
                setToastVisible(true);
                return prevSelected;
            }
            return [...prevSelected, challenge];
        });
    };

    const handleChallengeView = (chaInfoObj) => {
        
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <ChallengeViewModal
                data={chaInfoObj}
                onCancel={onCancel}
            />, true, true
        );
    };

    const groupChallengesByCategory = (challengesList) => 
    challengesList.reduce((acc, { categoryName, ...rest }) => {
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push({ categoryName, ...rest });
        return acc;
    }, {});

    const groupedChallenges = groupChallengesByCategory(selectedChallenges);

    const handleChallengeRemove = (chaGUID) => {
        setSelectedChallenges((prevSelected) =>
            prevSelected.filter((challenge) => challenge.chaGUID !== chaGUID)
        );
    };

    const handleChallengeSearch = ({ challengeName, selectedCategories, selectedType }) => {
        setChallengeFeedbackMessage(null);
        setChallengeFilters({
            challengeName,
            categories: selectedCategories,
            answerType: selectedType,
        });
    };

    const handleCloseToast = () => {
        setToastVisible(false);
    };

    // Redirect if user is not an admin
    useEffect(() => {
        if (userQuery.isSuccess && userQuery.data &&!userQuery.data?.roles.includes('admin')) {
            navigate('/');
        }
    }, [userQuery.isSuccess, userQuery.data, navigate]);

    useEffect(() => {
        if (challengesQuery.isSuccess && challengesQuery.data) {
            setChallenges(challengesQuery.data.challenges);
        }
    }, [challengesQuery.isSuccess, challengesQuery.data]);

    // Status
    useEffect(() => {
        statusRef.current = selectedStatus;
    }, [selectedStatus]);

    // Visibility
    useEffect(() => {
        visibilityRef.current = selectedVisibility;
    }, [selectedVisibility]);

    const competitionCreateMutation = useMutation({
        mutationFn: (comp) =>
            createAdminCompetition(
                comp.name,
                comp.status,
                comp.visibility,
                comp.description,
                comp.duration,
                comp.challenges,
                comp.startDate,
                comp.endDate,
                session,
                i18next.language
            ),
        onMutate: () => {
            setIsSending(true);
        },
        onError: (error) => {
            setIsSending(false);
            createFeedback('error', error.messages.join(', '));
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setIsSending(false);
            setIsRedirecting(true);
        }
    });

    const validate = () => {
        const validationErrors = [];

        if (!nameCompRef.current.value) {
            validationErrors.push(i18next.t('competition-name'));
        }

        if (!statusRef.current) {
            validationErrors.push(i18next.t('status'));
        }

        if (!visibilityRef.current) {
            validationErrors.push(i18next.t('visibility'));
        }

        if (nameCompRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        if (descriptionCompRef.current.value.length > 255) {
            createFeedback('warn', i18next.t('invalid-length-description'));
            return false;
        }

        if (selectedChallenges.length === 0) {
            createFeedback('warn', i18next.t('select-at-least-one-challenge'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            createFeedback('warn', errorMessage);
            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            const formattedStartDate = startDateRef.current.value
                ? datetimeToUTC(startDateRef.current.value)
                : null;

            const formattedEndDate = endDateRef.current.value
                ? datetimeToUTC(endDateRef.current.value)
                : null;

            competitionCreateMutation.mutate({
                name: nameCompRef.current.value,
                status: statusRef.current,
                visibility: visibilityRef.current,
                description: descriptionCompRef.current.value,
                duration: durationRef.current.value,
                challenges: selectedChallenges.map((c) => c.chaGUID).join(','),
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });
        }
    };

    const handleCancel = () => {
        navigate('/admin/panel');
    };

    return (
        <NavbarTemplate>
            <div className="w-full p-5 flex flex-col gap-5">
                <Toast 
                    message={toastMessage} 
                    type="warning" 
                    isVisible={toastVisible} 
                    duration={3000} 
                    onClose={handleCloseToast} 
                />
                <div className="w-full flex items-center gap-x-2">
                    <Banner color="green" borderColor='green'>{i18next.t('competition-create')}</Banner>
                </div>
                <div className="w-full flex">
                    {feedbackElement}
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('competition-name')}</Title>
                        <Input
                            focus
                            inputRef={nameCompRef}
                            color="white"
                            type="text"
                            placeholder={i18next.t('competition-ex-name')}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('status')}</Title>
                        <Select
                            color='white'
                            selected={selectedStatus || 'ACTIVE'}
                            onSelect={(selected) => setSelectedStatus(selected)}
                            options={{
                                [null]: {
                                    ACTIVE: i18next.t('active'),
                                    INACTIVE: i18next.t('inactive'),
                                },
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('visibility')}</Title>
                        <Select
                            color='white'
                            selected={selectedVisibility || 'PUBLIC'}
                            onSelect={(selected) => setSelectedVisibility(selected)}
                            options={{
                                [null]: {
                                    PUBLIC: i18next.t('competition-public'),
                                    PRIVATE: i18next.t('competition-private'),
                                },
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('competition-duration')}</Title>
                        <Input
                            inputRef={durationRef}
                            color="white"
                            type="number"
                            placeholder={String(3600)}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('start-date')}</Title>
                        <Input
                            inputRef={startDateRef}
                            color="white"
                            type="datetime-local"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Title size="sm">{i18next.t('end-date')}</Title>
                        <Input
                            inputRef={endDateRef}
                            color="white"
                            type="datetime-local"
                        />
                    </div>
                    <div className="flex flex-col gap-2 col-span-1 md:col-span-3">
                        <Title size="sm">{i18next.t('description')}</Title>
                        <Input
                            inputRef={descriptionCompRef}
                            color="white"
                            type="textarea"
                            placeholder={i18next.t('competition-ex-description')}
                        />
                    </div>
                </div>
                <div className="flex w-full flex-col gap-y-2 mb-7">
                    {errorChallengesLoading ? (
                        <div className="w-1/6 flex self-center">
                            <Button
                                color='green'
                                icon={faArrowsRotate}
                                onClick={handleChallengesReload}
                            >
                                {i18next.t('reload')}
                            </Button>
                        </div>
                    ) : (
                        <div className="w-full flex flex-col mb-3">
                            {feedbackElement && (
                                <div className="w-full mb-4">
                                    {feedbackElement}
                                </div>
                            )}
                            <div className="w-full flex flex-row">
                                <div className="w-8/12">
                                    <div className="w-full flex items-center mb-4">
                                        <Tag color="green" size='lg'>{i18next.t('relate-challenges')}</Tag>
                                    </div>
                                    <ChallengeAdminSearch
                                        isLoading={challengesQuery.isLoading}
                                        onSearch={handleChallengeSearch} 
                                        feedbackMessage={challengeFeedbackMessage}
                                    />
                                    <Container>
                                        <div className="w-full divide-y">
                                            <div className="w-full flex items-center py-2 gap-x-3">
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('status')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('category')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('type')}</Title>
                                                </div>
                                                <div className="flex-grow font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('name')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('max-attempts')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score-type')}</Title>
                                                </div>
                                                <div className="flex-none w-[8%] text-center font-semibold">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('score')}</Title>
                                                </div>
                                                <div className="flex-none w-[20%] font-semibold text-center">
                                                    <Title isLoading={challengesQuery.isLoading} size="lg">{i18next.t('actions')}</Title>
                                                </div>
                                            </div>
                                            {challengesQuery.isLoading ? (
                                                <Repeat count={perPage}>
                                                    <div className="w-full flex p-4 gap-4">
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-16 h-5 flex-shrink-0"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-1/4 h-5"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-1/4 h-5"
                                                        />
                                                        <Skeleton
                                                            baseColor="#e4e4e7"
                                                            highlightColor="#f4f4f5"
                                                            height="100%"
                                                            containerClassName="w-2/4 h-5"
                                                        />
                                                    </div>
                                                </Repeat>
                                            ) : (
                                                challenges && challenges.map((chaInfoObj) => {

                                                    const isSelected = selectedChallenges.some(selected => selected.chaGUID === chaInfoObj.chaGUID);

                                                    return (
                                                        <div 
                                                            key={chaInfoObj.chaGUID} 
                                                            className={`flex w-full py-2 items-center gap-x-3 cursor-pointer select-none ${isSelected ? 'bg-slate-100' : ''}`}
                                                            onClick={() => handleChallengeSelect(chaInfoObj)}
                                                            onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleChallengeSelect(chaInfoObj); }}
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            <div className="flex-none w-[8%] text-center" title={chaInfoObj.status}>
                                                                <FontAwesomeIcon color={getColorForStatus(chaInfoObj.status)} icon={faCircle} />
                                                            </div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.categoryName}
                                                            </div>
                                                            <div className="flex-none w-[8%] flex justify-center">
                                                                {["HASH", "REGEXP", "DYNAMIC", "PLAIN", "CODE", "OPEN"].includes(chaInfoObj.type) ? (
                                                                    <Tag size="sm" color={getColorForType(chaInfoObj.type)}>
                                                                        {chaInfoObj.type}
                                                                    </Tag>
                                                                ) : (
                                                                    <Tag size="sm" color="gray">
                                                                        {i18next.t('unknown')}
                                                                    </Tag>
                                                                )}
                                                            </div>
                                                            <div className="flex-grow">{chaInfoObj.name}</div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.maximum}
                                                            </div>
                                                            <div className="flex-none w-[8%] text-center">
                                                                {chaInfoObj.scoreType === "VARIABLE" ? (
                                                                    "VARIABLE"
                                                                ) : (
                                                                    "FIXED"
                                                                )}
                                                            </div>
                                                            <div className="flex-none w-[8%] flex justify-center">
                                                                <Tag
                                                                    size="sm"
                                                                    color={getColorForScore(chaInfoObj.score)}
                                                                    centerText
                                                                >
                                                                    {chaInfoObj.score}
                                                                </Tag>
                                                            </div>
                                                            <div className="flex-none w-[20%] text-center font-semibold">
                                                                <div className="flex justify-evenly w-full flex-wrap gap-y-2">
                                                                    <div>
                                                                            <Button
                                                                                color="green"
                                                                                icon={faEye}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleChallengeView(chaInfoObj);
                                                                                }}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )}
                                            {!errorChallengesLoading && (
                                                !challengesQuery.isLoading && challengesQuery.data ? (
                                                    <div className="flex w-full py-2 justify-center gap-x-3">
                                                        <Pager pages={challengesQuery.data.pages} page={challengePage} setPage={setChallengePage} />
                                                    </div>
                                                ) : (
                                                    <div className="flex w-full py-2 justify-center px-4 gap-4">
                                                        <Repeat count={14}>
                                                            <Skeleton
                                                                circle
                                                                count={1}
                                                                baseColor="#e4e4e7"
                                                                highlightColor="#f4f4f5"
                                                                containerClassName="w-9"
                                                                className='h-9'
                                                            />
                                                        </Repeat>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Container>
                                </div>
                                <div className="w-4/12 ml-3">
                                    <div className="mb-5">
                                        <Tag size="md" color="orange" shadow>{i18next.t('selected-challenges')}</Tag>
                                    </div>
                                    {Object.keys(groupedChallenges).map((category) => (
                                        <div key={category} className="mt-3">
                                            <Tag size="md" shadow>{category}</Tag>
                                            {groupedChallenges[category].map((challenge) => (
                                                <div key={challenge.chaGUID} className="flex-row">
                                                    <CardToggle
                                                        border="green"
                                                        size="sm"
                                                        key={challenge.chaGUID}
                                                        title={`${challenge.type} - ${challenge.name}`}
                                                    >
                                                        <div className="flex flex-wrap p-2">
                                                            <div className="flex justify-evenly flex-row gap-5">
                                                                <div className="w-1/2 flex flex-col items-center">
                                                                    <span className="text-center">{i18next.t('score')}</span>
                                                                    <Tag size="sm" color={getColorForScore(challenge.score)}>
                                                                        {challenge.score}
                                                                    </Tag>
                                                                </div>
                                                                <div className="w-1/2 flex flex-col items-center">
                                                                    <span className="text-center">{i18next.t('type')}</span>
                                                                    <Tag size="sm" color={getColorForType(challenge.type)}>
                                                                        {challenge.type}
                                                                    </Tag>
                                                                </div>
                                                            </div>
                                                            <span>{challenge.description}</span>
                                                            <div className="flex mt-2">
                                                                <Button
                                                                    color="red"
                                                                    icon={faBan}
                                                                    onClick={() => handleChallengeRemove(challenge.chaGUID)}
                                                                >
                                                                    {i18next.t('remove')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </CardToggle>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>  
                    )}
                </div>
                <div className="w-full flex gap-5">
                    <Button color="red" icon={faBan} onClick={handleCancel}>
                        {i18next.t('cancel')}
                    </Button>
                    <Button color="green" icon={faPaperPlane} onClick={handleAccept} isLoading={challengesQuery.isLoading}>
                        {i18next.t('save')}
                    </Button>
                </div>
            </div>
            <Spinner isLoading={isSending} message={i18next.t('loading')} type="info" />
            {isRedirecting && (
                <Redirect
                    message={i18next.t('competition-created-successfully')}
                    redirectTo="/admin/panel"
                    type="success"
                />
            )}
        </NavbarTemplate>
    );
}

export default CompetitionCreatePage;