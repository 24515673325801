import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/**
 * Like atom creates a like toggle system
 * @param {{
 *      isLoading: boolean;
 *      size: 'sm' | 'md';
 *      value: boolean | null;
 *      disabled: boolean | undefined;
 *      likes: number | undefined;
 *      dislikes: number | undefined;
 *      onLike: (value: boolean) => void;
 *      onDislike: (value: boolean) => void;
 * }} props
 */
function Like({ isLoading, size, value, disabled, likes, dislikes, onLike, onDislike }) {
    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                width={size === 'sm' ? 144 : 160}
                height={size === 'sm' ? 32 : 36}
                borderRadius={9999}
            />
        );
    }

    /**
     * Activate/deactivate like
     */
    const handleLike = () => {
        onLike(value === true ? null : true);
    };

    /**
     * Activate/deactivate dislike
     */
    const handleDislike = () => {
        onDislike(value === false ? null : false);
    };

    return (
        <div
            className={classNames('flex divide-x divide-zinc-200 rounded-full shadow-md overflow-hidden', {
                'w-40 h-10': size === 'md',
                'w-36 h-8': size === 'sm',
            })}
        >
            <button
                type="button"
                title={i18next.t('like')}
                disabled={disabled}
                onClick={handleLike}
                className={classNames(
                    'w-full h-full flex gap-2 justify-center items-center transition-colors',
                    value === true ? 'bg-smgreen text-smgreen-dark' : 'bg-white text-zinc-400',
                    { 'hover:bg-smgreen hover:text-smgreen-dark': value !== true && !disabled },
                )}
            >
                <FontAwesomeIcon icon={faThumbsUp} />
                {likes !== undefined && <p className="text-xs">{likes}</p>}
            </button>

            <button
                type="button"
                title={i18next.t('dislike')}
                disabled={disabled}
                onClick={handleDislike}
                className={classNames(
                    'w-full h-full flex gap-2 justify-center items-center transition-colors',
                    value === false ? 'bg-smred text-smred-dark' : 'bg-white text-zinc-400',
                    { 'hover:bg-smred hover:text-smred-dark': value !== false && !disabled },
                )}
            >
                <FontAwesomeIcon icon={faThumbsDown} />
                {dislikes !== undefined && <p className="text-xs">{dislikes}</p>}
            </button>
        </div>
    );
}

Like.propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md']),
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
    onLike: PropTypes.func.isRequired,
    onDislike: PropTypes.func.isRequired,
};

Like.defaultProps = {
    isLoading: false,
    size: 'md',
    value: null,
    disabled: undefined,
    likes: undefined,
    dislikes: undefined,
};

export default Like;
