import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Feedback from '../atoms/Feedback';
import SelectMultiple from '../atoms/SelectMultiple';
import getCategories from '../../services/getCategories';

/**
 * CompetitionAdminSearch component handles the search functionality for competitions
 * @param {{
 *      isLoading: boolean;
 *      onSearch: (criteria: { name: string, status: [], categories: number[] }) => void;
 *      feedbackMessage: { type: string, message: string } | null;
 * }} props
 */
function CompetitionAdminSearch({ isLoading, onSearch, feedbackMessage }) {

    const [competitionName, setCompetitionName] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showClearButton, setShowClearButton] = useState(false);

    // Fetch categories
    const { data: categoryList, isLoading: isCategoryLoading, isError: isCategoryError } = useQuery({
        queryKey: ['categoryList'],
        queryFn: () => getCategories(i18next.language),
        refetchOnWindowFocus: false,
        staleTime: 2 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        retry: 3,
        retryDelay: (attempt) => Math.min(2000 * 2 ** attempt, 30000),
    });

    useEffect(() => {
        const hasContent = competitionName || selectedCategories.length > 0;
        setShowClearButton(hasContent);
    }, [competitionName, selectedCategories]);

    const handleCategorySelect = (values) => {
        setSelectedCategories(values);
    };

    const categoryOptions = useMemo(() => ({
        [i18next.t('categories')]: categoryList?.categories.reduce((acc, category) => {
            acc[category.id] = category.name;
            return acc;
        }, {}) || {}
    }), [categoryList]);

    const handleSearch = async () => {
        await onSearch({
            name: competitionName,
            categories: selectedCategories.map(category => parseInt(category, 10)),
            status: [],
        });
    };

    const handleClear = () => {
        setCompetitionName('');
        setSelectedCategories([]);
        onSearch({ name: '', status: [], categories: [] });
    };

    return (
        <div className="bg-white p-4 rounded-md shadow-md space-y-4">
            <Input
                type="text"
                placeholder={i18next.t('competition-name')}
                value={competitionName}
                onType={setCompetitionName}
                color="white"
                focus
            />
            <div>
                {isCategoryError ? (
                    <Feedback type="error">
                        {i18next.t('error-loading-categories')}
                    </Feedback>
                ) : (
                    <SelectMultiple
                        isLoading={isCategoryLoading}
                        options={categoryOptions}
                        selected={selectedCategories}
                        onSelect={handleCategorySelect}
                        placeholder={i18next.t('select-categories')}
                        color="gray"
                    />
                )}
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex justify-around space-x-4">
                    <Button
                        isLoading={isLoading || isCategoryLoading}
                        color="blue"
                        onClick={handleSearch}
                        title={i18next.t('search')}
                        icon={faSearch}
                    >
                        {i18next.t('search')}
                    </Button>
                    {showClearButton && (
                        <Button
                            isLoading={isLoading || isCategoryLoading}
                            color="red"
                            onClick={handleClear}
                            title={i18next.t('clear')}
                            icon={faEraser}
                        >
                            {i18next.t('clear')}
                        </Button>
                    )}
                </div>
            </div>
            {!isLoading && feedbackMessage && (
                <Feedback type={feedbackMessage.type}>
                    {feedbackMessage.message}
                </Feedback>
            )}
        </div>
    );
}

CompetitionAdminSearch.propTypes = {
    isLoading: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    feedbackMessage: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string
    }),
};

CompetitionAdminSearch.defaultProps = {
    isLoading: false,
    feedbackMessage: null,
};

export default CompetitionAdminSearch;