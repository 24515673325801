// ChallengeUpdateModal.jsx

import { faBan, faPlus, faPenToSquare, faPaperPlane, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import useFeedback from '../../hooks/useFeedback';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Select from '../atoms/Select';

/**
 * ChallengeUpdateModal allows the user to update a challenge
 * @param {{
 *      nameChaRef: React.RefObject<HTMLInputElement>;
 *      statusChaRef: React.RefObject<HTMLSelectElement>;
 *      visibilityChaRef: React.RefObject<HTMLSelectElement>;
 *      descriptionChaRef: React.RefObject<HTMLTextAreaElement>;
 *      claritificationsChaRef: React.RefObject<HTMLTextAreaElement>;
 *      maximumRef: React.RefObject<HTMLInputElement>;
 *      scoreTypeRef: React.RefObject<HTMLSelectElement>;
 *      scoreRef: React.RefObject<HTMLInputElement>;
 *      chaCatRef: React.RefObject<HTMLSelectElement>;
 *      categories: object;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function ChallengeUpdateModal({
    nameChaRef,
    statusChaRef,
    visibilityChaRef,
    descriptionChaRef,
    clarificationsChaRef,
    maximumRef,
    scoreTypeRef,
    scoreRef,
    chaCatRef,
    categories,
    challengeInfoObj,
    onAccept, 
    onCancel 
}) { 


    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();

    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedVisibility, setSelectedVisibility] = useState('');
    const [selectedScoreType, setSelectedScoreType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(challengeInfoObj.category);
    const [answerInputs, setAnswerInputs] = useState([{ guid: '', id: 1, data: '', valid: true }]);


    useEffect(() => {
        if (Object.keys(challengeInfoObj.answers).length > 0) {
            const newAnswerInputs = Object.values(challengeInfoObj.answers).map((answer, index) => ({
                guid: answer.ansGUID,
                id: index + 1,
                data: answer.ansData,
                valid: answer.ansValid
            }));
            setAnswerInputs(newAnswerInputs);
        }
    }, [challengeInfoObj.answers]);


    const categoryObject = {
        categories: categories.categories.reduce((acc, category) => {
            acc[category.id] = category.name;
            return acc;
        }, {})
    };

    const toggleAnswerValidity = (id) => {
        setAnswerInputs(prevInputs =>
            prevInputs.map(input => {
                if (input.id === id) {
                    return {
                        ...input,
                        valid: !input.valid || false
                    };
                }
                return input;
            })
        );
    };

    /* Logic for Challenges with multiple answers (CODE and PLAIN) */
    const addAnswerInput = () => {
        if (answerInputs.length < 10) {
            setAnswerInputs(prevInputs => [...prevInputs, { id: prevInputs.length + 1, data: '', valid: false }]);
        }
    };

    const removeAnswerInput = () => {
        if (answerInputs.length > 1) {
            setAnswerInputs(prevInputs => prevInputs.slice(0, -1));
        }
    };


    // Handler for multiple answers (PLAIN and CODE Challenges)
    const handleAnswersChange = (id, data) => {
        setAnswerInputs(prevInputs =>
            prevInputs.map(input => {
                if (input.id === id) {
                    return { ...input, data };
                }
                return input;
            })
        );
    };

    // Handler for single answer (REGEXP and OPEN Challenges)
    const handleAnswerChange = (newValue) => {
        setAnswerInputs([{ id: 1, data: newValue, valid: true }]);
    };

    
    // Status
    useEffect(() => {
        setSelectedStatus(challengeInfoObj.status);
    }, [challengeInfoObj.status]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        statusChaRef.current = selectedStatus;
    }, [selectedStatus, statusChaRef]);

    // Visibility
    useEffect(() => {
        setSelectedVisibility(challengeInfoObj.visibility);
    }, [challengeInfoObj.visibility]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        visibilityChaRef.current = selectedVisibility;
    }, [selectedVisibility, visibilityChaRef]);

    // ScoreType
    useEffect(() => {
        setSelectedScoreType(challengeInfoObj.scoreType);
    }, [challengeInfoObj.scoreType]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        scoreTypeRef.current = selectedScoreType;
    }, [selectedScoreType, scoreTypeRef]);

    // Category
    useEffect(() => {
        setSelectedCategory(challengeInfoObj.category);
    }, [challengeInfoObj.category]);

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign 
        chaCatRef.current = selectedCategory;
    }, [selectedCategory, chaCatRef]);


    // PLAIN & CODE Challenge Logic
    const renderPlainCodeInputs = () => (
        <div className="w-full flex flex-col gap-2">
            <div className="flex items-center gap-x-7 mb-2">
                <Title size="sm">{i18next.t('answers')}</Title>
                <div className="w-1/12">
                    {answerInputs.length < 10 && (
                        <Button color="green" rounded icon={faPlus} onClick={addAnswerInput} title={i18next.t('answer-add')} />
                    )}
                </div>
                <div className="w-1/12">
                    {answerInputs.length > 1 && (
                        <Button color="red" rounded icon={faMinus} onClick={removeAnswerInput} title={i18next.t('answer-remove')} />
                    )}
                </div>
            </div>
            {challengeInfoObj.type === 'CODE' && (
                <div className="w-full text-center bg-orange-300 text-orange-800 p-2">
                    <Title color="orange" size="md">
                        {i18next.t('code-answer-note')}
                    </Title>
                </div>
            )}
            {answerInputs.map(input => (
                <div key={input.id} className="flex w-full gap-3 items-center">
                    
                    <div className="w-1/12 text-center">
                        <Title size="lg">{`${input.id}`}</Title>
                    </div>
                    <div className="w-10/12">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <Input value={challengeInfoObj.type === 'CODE' ? (input.data === '' ? '{"input":[""],"output":[""]}' : input.data) : input.data}
                            onType={newValue => handleAnswersChange(input.id, newValue)}
                            color="gray"
                            type="text"
                        />
                    </div>
                    <div className="w-1/12">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="flex justify-center items-center gap-1">
                            <input
                                type="checkbox"
                                checked={input.valid}
                                onChange={() => toggleAnswerValidity(input.id)}
                                className="h-8 w-8"
                            />
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
    
    // REGEXP Challenge Logic
    const renderRegexpInput = () => (
        <div className="w-full flex flex-col gap-2">
            <Title size="sm">{i18next.t('answer')}</Title>
            <Input
                value={answerInputs[0].data}
                onType={newValue => handleAnswerChange(newValue)}
                color="gray"
                type="text"
            />
        </div>
    );
    
    // OPEN Challenge Logic
    const renderOpenInput = () => (
        <div className="w-fit flex flex-col p-2 border-2 border-smyellow-dark">
            <Title size="md">{i18next.t('open-answer-note')}</Title>
        </div>
    );
    
    const renderInputs = () => {
        switch (challengeInfoObj.type) {
            case 'PLAIN':
                return renderPlainCodeInputs();
            case 'REGEXP':
                return renderRegexpInput();
            case 'OPEN':
                return renderOpenInput();
            case 'CODE':
                return renderPlainCodeInputs();
            default:
                return null;
        }
    };

    const validate = () => {

        const validationErrors = [];

        if (!nameChaRef.current.value) {
            validationErrors.push(i18next.t('name'));
        }

        if (!statusChaRef.current) {
            validationErrors.push(i18next.t('status'));
        }

        if (!visibilityChaRef.current) {
            validationErrors.push(i18next.t('visibility'));
        }

        if (!descriptionChaRef.current) {
            validationErrors.push(i18next.t('description'));
        }

        if (!maximumRef.current) {
            validationErrors.push(i18next.t('max-attempts'));
        }

        if (!scoreTypeRef.current) {
            validationErrors.push(i18next.t('score-type'));
        }

        if (!scoreRef.current) {
            validationErrors.push(i18next.t('score'));
        }

        if (nameChaRef.current.value.length > 100) {
            createFeedback('warn', i18next.t('invalid-length-name'));
            return false;
        }

        const scoreValue = parseFloat(scoreRef.current.value);

        if (isNaN(scoreValue) || scoreValue < 0 || scoreValue > 100) {
            createFeedback('warn', i18next.t('invalid-score-range'));
            return false;
        }

        if (validationErrors.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${validationErrors.join(', ')}`;
            
            createFeedback('warn', errorMessage);
            return false;
        }

        return true;
    };

    const handleAccept = () => {
        if (validate()) {
            removeFeedback();
            onAccept(answerInputs);
        }
    };

    return (
        <div className="w-full p-5 flex flex-col gap-5">
            <div className="w-full flex items-center gap-x-2">
            <FontAwesomeIcon icon={faPenToSquare} size="xl" className="text-smblue-dark" />
                <Title size="lg">{i18next.t('challenge-update')}</Title>
            </div>
            <div className="w-full flex">
                {feedbackElement}
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('category')}</Title>
                <Select
                    color='gray'
                    isLoading={false}
                    selected={selectedCategory.toString()} 
                    onSelect={(selected) => { setSelectedCategory(selected); }}
                    options={categoryObject}
                    enableSearch
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('name')}</Title>
                <Input
                    focus
                    inputRef={nameChaRef}
                    color="gray"
                    type="text"
                    defaultValue={challengeInfoObj.name}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('description')}</Title>
                <Input
                    inputRef={descriptionChaRef}
                    color="gray"
                    type="textarea"
                    defaultValue={challengeInfoObj.description}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('clarifications')}</Title>
                <Input
                    inputRef={clarificationsChaRef}
                    color="gray"
                    type="textarea"
                    defaultValue={challengeInfoObj.clarifications}
                />
            </div>
            {/* Answers Logic */}
            {renderInputs()}

            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('status')}</Title>
                <Select
                    color='gray'
                    selected={selectedStatus || 'PRODUCTION'}
                    // eslint-disable-next-line no-param-reassign
                    onSelect={(selected) => { setSelectedStatus(selected); }}
                    options={{
                        [null]: {
                            QUEUE: i18next.t('queued'),
                            NOT_APPROVED: i18next.t('not-approved'),
                            STAGING: i18next.t('staging'),
                            DISABLED: i18next.t('disabled'),
                            DELETED: i18next.t('deleted'),
                            PRODUCTION: i18next.t('production'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('visibility')}</Title>
                <Select
                    color='gray'
                    selected={selectedVisibility || 'PUBLIC'}
                    // eslint-disable-next-line no-param-reassign
                    onSelect={(selected) => { setSelectedVisibility(selected); }}
                    options={{
                        [null]: {
                            PUBLIC: i18next.t('pub'),
                            PRIVATE: i18next.t('priv'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('attempts')}</Title>
                <Input
                    inputRef={maximumRef}
                    color="gray"
                    type="number"
                    defaultValue={String(challengeInfoObj.maximum)}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('score-type')}</Title>
                <Select
                    color='gray'
                    selected={selectedScoreType || 'VARIABLE'}
                    // eslint-disable-next-line no-param-reassign
                    onSelect={(selected) => { setSelectedScoreType(selected); }}
                    options={{
                        [null]: {
                            VARIABLE: i18next.t('variable'),
                            FIXED: i18next.t('fixed'),
                        },
                    }}
                />
            </div>
            <div className="w-full flex flex-col gap-2">
                <Title size="sm">{i18next.t('score')}</Title>
                <Input
                    inputRef={scoreRef}
                    color="gray"
                    type="number"
                    defaultValue={String(challengeInfoObj.score)}
                />
            </div>
            <div className="w-full flex gap-5">
                <Button color="red" icon={faBan} onClick={onCancel}>
                    { i18next.t('cancel') }
                </Button>
                <Button color="blue" icon={faPaperPlane} onClick={handleAccept}>
                    { i18next.t('update') }
                </Button>
            </div>
        </div>
    );
}

ChallengeUpdateModal.propTypes = {
    nameChaRef: PropTypes.shape().isRequired,
    statusChaRef: PropTypes.shape().isRequired,
    visibilityChaRef: PropTypes.shape().isRequired,
    descriptionChaRef: PropTypes.shape().isRequired,
    clarificationsChaRef: PropTypes.shape().isRequired,
    maximumRef: PropTypes.shape().isRequired,
    scoreTypeRef: PropTypes.shape().isRequired,
    scoreRef: PropTypes.shape().isRequired,
    chaCatRef: PropTypes.shape().isRequired,
    categories: PropTypes.shape().isRequired,
    challengeInfoObj: PropTypes.shape({
        guid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        visibility: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        clarifications: PropTypes.string.isRequired,
        maximum: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
        scoreType: PropTypes.string.isRequired,
        category: PropTypes.number.isRequired,
        answers: PropTypes.shape({
            guid: PropTypes.string.isRequired,
            ansData: PropTypes.string.isRequired,
            ansValid: PropTypes.bool.isRequired,
        }),
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ChallengeUpdateModal;