/* eslint-disable no-nested-ternary */
import {
    faIdCard,
    faPaperPlane,
    faPenToSquare,
    faPlus,
    faShieldHalved,
    faTrash,
    faUser,
    faUserCircle,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useModal from '../../hooks/useModal';
import useSession from '../../hooks/useSession';
import UpdateProfessionalProfile from '../../services/UpdateProfesionalProfile';
import createCertification from '../../services/createCertification';
import createEducation from '../../services/createEducation';
import createJob from '../../services/createJob';
import deleteCertification from '../../services/deleteCertification';
import deleteEducation from '../../services/deleteEducation';
import deleteJob from '../../services/deleteJob';
import getCertifications from '../../services/getCertifications';
import getCountries from '../../services/getCountries';
import getEducation from '../../services/getEducation';
import getJobs from '../../services/getJobs';
import getProfessionalProfile from '../../services/getProfessionalProfile';
import getUser from '../../services/getUser';
import setPassword from '../../services/setPassword';
import updateCertification from '../../services/updateCertification';
import updateEducation from '../../services/updateEducation';
import updateJob from '../../services/updateJob';
import updatePassword from '../../services/updatePassword';
import updateUser from '../../services/updateUser';
import validateCity from '../../services/validateCity';
import formatDate from '../../utils/formatDate';
import Banner from '../atoms/Banner';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Title from '../atoms/Title';
import CertificationCreateModal from '../modals/CertificationCreateModal';
import CertificationDeleteModal from '../modals/CertificationDeleteModal';
import CertificationUpdateModal from '../modals/CertificationUpdateModal';
import EducationCreateModal from '../modals/EducationCreateModal';
import EducationDeleteModal from '../modals/EducationDeleteModal';
import EducationUpdateModal from '../modals/EducationUpdateModal';
import JobCreateModal from '../modals/JobCreateModal';
import JobDeleteModal from '../modals/JobDeleteModal';
import JobUpdateModal from '../modals/JobUpdateModal';
import CardToggle from '../molecules/CardToggle';
import NavbarTemplate from '../templates/NavbarTemplate';

function Profile() {
    const englishLevelOrder = ['BASIC', 'MEDIUM', 'HIGH', 'NATIVE', 'UNKNOWN'];
    const { competition } = useParams();
    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    // General Refs
    const institutionNameRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const descriptionRef = useRef();
    // Education Refs
    const titleRef = useRef();
    const companyRef = useRef();
    const selectedCurrencyRef = useRef();
    const salaryRef = useRef();
    const selectedJobTypeRef = useRef();
    const selectedCountryRef = useRef();
    // Education Refs
    const degreeRef = useRef();
    const areaRef = useRef();
    // Certification Refs
    const nameRef = useRef();
    const issueDateRef = useRef();
    const credentialIDRef = useRef();
    const credentialURLRef = useRef();

    const [indexPage, setIndexPage] = useState(competition ? 1 : 0);
    const [profile, setProfile] = useState({
        guid: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
        username: '',
        email: '',
        firstName: null,
        lastName: null,
        phone: null,
        birthday: null,
        sex: null,
        country: 0,
        biography: null,
        web: null,
        twitter: null,
        facebook: null,
        linkedin: null,
        github: null,
    });

    const [professionalProfile, setProfessionalProfile] = useState({
        salary: null,
        currency: null,
        englishLevel: null,
        city: null,
    });

    const [userJobs, setUserJobs] = useState(false);

    const [userEducation, setUserEducation] = useState(false);

    const [userCertifications, setUserCertifications] = useState(false);

    // Get user
    const userQuery = useQuery({
        queryKey: [session, 'user'],
        queryFn: () => getUser(session, i18next.language),
        enabled: !!session,
    });

    // Get ProfessionalProfile
    const professionalProfileQuery = useQuery({
        queryKey: [session, 'professionalProfile'],
        queryFn: () => getProfessionalProfile(session, i18next.language),
        enabled: !!session,
    });

    // Get userJobs
    const userJobsQuery = useQuery({
        queryKey: [session, 'userJobs'],
        queryFn: () => getJobs(session, i18next.language),
        enabled: !!session,
        refetchOnMount: true,
    });

    // Get userEducation
    const userEducationQuery = useQuery({
        queryKey: [session, 'userEducation'],
        queryFn: () => getEducation(session, i18next.language),
        enabled: !!session,
    });

    // Get userCertifications
    const userCertificationsQuery = useQuery({
        queryKey: [session, 'userCertifications'],
        queryFn: () => getCertifications(session, i18next.language),
        enabled: !!session,
    });

    // Get countries
    const countriesQuery = useQuery({
        queryKey: [session, 'countries'],
        queryFn: () => getCountries(session, i18next.language),
    });

    // Update user
    const updateUserMutation = useMutation({
        mutationFn: (user) => {
            updateUser(user, session, i18next.language);
        },
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('profile-updated'));
            queryClient.invalidateQueries([session, 'user']);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    // Update professional profile
    const updateProfessionalProfileMutation = useMutation({
        mutationFn: ({ salary, englishLevel, currency, city }) => {
            const user = { salary, englishLevel, currency, city };
            return UpdateProfessionalProfile(user, session, i18next.language);
        },
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('professional-profile-updated'));
            queryClient.invalidateQueries([session, 'professionalProfile']);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    // Job logic

    const jobCreateMutation = useMutation({
        mutationFn: (job) =>
            createJob(
                job.title,
                job.type,
                job.company,
                job.country,
                job.startDate,
                job.endDate,
                job.description,
                job.currency,
                job.salary,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('job-created-successfully'));

            const queryKey = [session, 'userJobs'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Create a new job
     */
    const handleJobCreate = () => {
        if (countriesQuery.isLoading) {
            return;
        }

        const onAccept = () => {
            if (
                !titleRef.current.value ||
                !selectedJobTypeRef.current ||
                !companyRef.current.value ||
                !selectedCountryRef.current ||
                !startDateRef.current.value ||
                !selectedCurrencyRef.current ||
                !salaryRef.current.value
            ) {
                return;
            }
            jobCreateMutation.mutate({
                title: titleRef.current.value,
                type: selectedJobTypeRef.current,
                company: companyRef.current.value,
                country: selectedCountryRef.current,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
                currency: selectedCurrencyRef.current,
                salary: salaryRef.current.value,
            });
            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobCreateModal
                titleRef={titleRef}
                selectedJobTypeRef={selectedJobTypeRef}
                companyRef={companyRef}
                selectedCountryRef={selectedCountryRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                selectedCurrencyRef={selectedCurrencyRef}
                salaryRef={salaryRef}
                actualCountry={profile.country?.toString()}
                countries={countriesQuery.data || {}}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true,
        );
    };

    const jobUpdateMutation = useMutation({
        mutationFn: (job) =>
            updateJob(
                job.jobID,
                job.title,
                job.type,
                job.company,
                job.country,
                job.startDate,
                job.endDate,
                job.description,
                job.currency,
                job.salary,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('job-updated-successfully'));

            const queryKey = [session, 'userJobs'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Update Jobs
     */
    const handleJobUpdate = (jobsInfoObj) => {
        const onAccept = () => {
            if (
                !titleRef.current.value ||
                !selectedJobTypeRef.current ||
                !companyRef.current.value ||
                !selectedCountryRef.current ||
                !startDateRef.current.value ||
                !selectedCurrencyRef.current ||
                !salaryRef.current.value
            )
                return;

            jobUpdateMutation.mutate({
                jobID: jobsInfoObj.jobID?.toString(),
                title: titleRef.current.value,
                type: selectedJobTypeRef.current,
                company: companyRef.current.value,
                country: selectedCountryRef.current,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
                currency: selectedCurrencyRef.current,
                salary: salaryRef.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobUpdateModal
                titleRef={titleRef}
                selectedJobTypeRef={selectedJobTypeRef}
                companyRef={companyRef}
                selectedCountryRef={selectedCountryRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                selectedCurrencyRef={selectedCurrencyRef}
                salaryRef={salaryRef}
                countries={countriesQuery.data || {}}
                onAccept={onAccept}
                onCancel={onCancel}
                jobsInfoObj={{
                    jobID: jobsInfoObj && jobsInfoObj.jobID ? jobsInfoObj.jobID?.toString() : '',
                    title: jobsInfoObj && jobsInfoObj.title ? jobsInfoObj.title : '',
                    type: jobsInfoObj && jobsInfoObj.type ? jobsInfoObj.type : 'FULL_TIME',
                    company: jobsInfoObj && jobsInfoObj.company ? jobsInfoObj.company : '',
                    country: jobsInfoObj && jobsInfoObj.countryID ? jobsInfoObj.countryID : 0,
                    startDate: jobsInfoObj && jobsInfoObj.startDate ? jobsInfoObj.startDate : '',
                    endDate: jobsInfoObj && jobsInfoObj.endDate ? jobsInfoObj.endDate : '',
                    description: jobsInfoObj && jobsInfoObj.description ? jobsInfoObj.description : '',
                    currency: jobsInfoObj && jobsInfoObj.currency ? jobsInfoObj.currency : 'COP',
                    salary: jobsInfoObj && jobsInfoObj.salary ? jobsInfoObj.salary?.toString() : '',
                }}
            />,
            true,
        );
    };

    const jobDeleteMutation = useMutation({
        mutationFn: (jobID) => {
            deleteJob(jobID, session, i18next.language);
        },
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('job-deleted-successfully'));
            const queryKey = [session, 'userJobs'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Delete Jobs
     */
    const handleJobDelete = (jobInfo) => {
        // Delete a job
        const onAccept = () => {
            removeModal();

            if (!jobInfo || !jobInfo.jobID) {
                return;
            }

            jobDeleteMutation.mutate(jobInfo.jobID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobDeleteModal title={jobInfo.title} company={jobInfo.company} onAccept={onAccept} onCancel={onCancel} />,
        );
    };

    // Education logic

    const educationCreateMutation = useMutation({
        mutationFn: (edu) =>
            createEducation(
                edu.institutionName,
                edu.degree,
                edu.area,
                edu.startDate,
                edu.endDate,
                edu.description,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('education-created-successfully'));

            const queryKey = [session, 'userEducation'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Create a new education
     */
    const handleEducationCreate = () => {
        const onAccept = () => {
            if (
                !institutionNameRef.current.value ||
                !degreeRef.current.value ||
                !areaRef.current.value ||
                !startDateRef.current.value
            ) {
                return;
            }
            educationCreateMutation.mutate({
                institutionName: institutionNameRef.current.value,
                degree: degreeRef.current.value,
                area: areaRef.current.value,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
            });

            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationCreateModal
                institutionNameRef={institutionNameRef}
                degreeRef={degreeRef}
                areaRef={areaRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true,
        );
    };

    const educationUpdateMutation = useMutation({
        mutationFn: (edu) =>
            updateEducation(
                edu.educationID,
                edu.institutionName,
                edu.degree,
                edu.area,
                edu.startDate,
                edu.endDate,
                edu.description,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('education-updated-successfully'));

            const queryKey = [session, 'userEducation'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Update Education
     */
    const handleEducationUpdate = (educationInfoObj) => {
        const onAccept = () => {
            if (
                !institutionNameRef.current.value ||
                !degreeRef.current.value ||
                !areaRef.current.value ||
                !startDateRef.current.value
            ) {
                return;
            }

            educationUpdateMutation.mutate({
                educationID: educationInfoObj.educationID?.toString(),
                institutionName: institutionNameRef.current.value,
                degree: degreeRef.current.value,
                area: areaRef.current.value,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationUpdateModal
                institutionNameRef={institutionNameRef}
                degreeRef={degreeRef}
                areaRef={areaRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                onAccept={onAccept}
                onCancel={onCancel}
                educationInfoObj={{
                    educationID: educationInfoObj && educationInfoObj.jobID ? educationInfoObj.jobID?.toString() : '',
                    institutionName:
                        educationInfoObj && educationInfoObj.institutionName ? educationInfoObj.institutionName : '',
                    degree: educationInfoObj && educationInfoObj.degree ? educationInfoObj.degree : '',
                    area: educationInfoObj && educationInfoObj.area ? educationInfoObj.area : '',
                    startDate: educationInfoObj && educationInfoObj.startDate ? educationInfoObj.startDate : '',
                    endDate: educationInfoObj && educationInfoObj.endDate ? educationInfoObj.endDate : '',
                    description: educationInfoObj && educationInfoObj.description ? educationInfoObj.description : '',
                }}
            />,
            true,
        );
    };

    const educationDeleteMutation = useMutation({
        mutationFn: (educationID) => deleteEducation(educationID, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('education-deleted-successfully'));
            const queryKey = [session, 'userEducation'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Delete education
     */
    const handleEducationDelete = (eduInfo) => {
        // Delete an education
        const onAccept = () => {
            removeModal();

            if (!eduInfo || !eduInfo.educationID) return;

            educationDeleteMutation.mutate(eduInfo.educationID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <EducationDeleteModal
                institutionName={eduInfo.institutionName}
                degree={eduInfo.degree}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
        );
    };

    // Certification logic

    const certificationCreateMutation = useMutation({
        mutationFn: (certi) =>
            createCertification(
                certi.name,
                certi.institutionName,
                certi.issueDate,
                certi.credentialID,
                certi.credentialURL,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('certification-created-successfully'));

            const queryKey = [session, 'userCertifications'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Create a new certification
     */
    const handleCertificationCreate = () => {
        const onAccept = () => {
            if (
                !nameRef.current.value ||
                !institutionNameRef.current.value ||
                !issueDateRef.current.value ||
                !credentialURLRef.current.value
            ) {
                return;
            }

            certificationCreateMutation.mutate({
                name: nameRef.current.value,
                institutionName: institutionNameRef.current.value,
                issueDate: issueDateRef.current.value,
                credentialID: credentialIDRef.current.value,
                credentialURL: credentialURLRef.current.value,
            });
            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationCreateModal
                nameRef={nameRef}
                institutionNameRef={institutionNameRef}
                issueDateRef={issueDateRef}
                credentialIDRef={credentialIDRef}
                credentialURLRef={credentialURLRef}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            true,
        );
    };

    const certificationUpdateMutation = useMutation({
        mutationFn: (certi) =>
            updateCertification(
                certi.certificationID,
                certi.name,
                certi.institutionName,
                certi.issueDate,
                certi.credentialID,
                certi.credentialURL,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('certification-updated-successfully'));

            const queryKey = [session, 'userCertifications'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Update certification
     */
    const handleCertificationUpdate = (certiInfoObj) => {
        const onAccept = () => {
            if (
                !nameRef.current.value ||
                !institutionNameRef.current.value ||
                !issueDateRef.current.value ||
                !credentialURLRef.current.value
            )
                return;

            certificationUpdateMutation.mutate({
                certificationID: certiInfoObj.certificationID?.toString(),
                name: nameRef.current.value,
                institutionName: institutionNameRef.current.value,
                issueDate: issueDateRef.current.value,
                credentialID: credentialIDRef.current.value,
                credentialURL: credentialURLRef.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationUpdateModal
                nameRef={nameRef}
                institutionNameRef={institutionNameRef}
                issueDateRef={issueDateRef}
                credentialIDRef={credentialIDRef}
                credentialURLRef={credentialURLRef}
                onAccept={onAccept}
                onCancel={onCancel}
                certiInfoObj={{
                    certificationID: certiInfoObj && certiInfoObj.jobID ? certiInfoObj.jobID?.toString() : '',
                    name: certiInfoObj && certiInfoObj.name ? certiInfoObj.name : '',
                    institutionName: certiInfoObj && certiInfoObj.institutionName ? certiInfoObj.institutionName : '',
                    issueDate: certiInfoObj && certiInfoObj.issueDate ? certiInfoObj.issueDate : '',
                    credentialID: certiInfoObj && certiInfoObj.credentialID ? certiInfoObj.credentialID : '',
                    credentialURL: certiInfoObj && certiInfoObj.credentialURL ? certiInfoObj.credentialURL : '',
                }}
            />,
            true,
        );
    };

    const certificationDeleteMutation = useMutation({
        mutationFn: (certificationID) => deleteCertification(certificationID, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },
        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('certification-deleted-successfully'));
            const queryKey = [session, 'userCertifications'];
            queryClient.invalidateQueries(queryKey);
        },
        onSettled: () => {
            window.scrollTo(0, 0);
        },
    });

    /**
     * Delete Certifications
     */
    const handleCertificationDelete = (certiInfo) => {
        // Delete a certification
        const onAccept = () => {
            removeModal();

            if (!certiInfo || !certiInfo.certificationID) return;

            certificationDeleteMutation.mutate(certiInfo.certificationID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <CertificationDeleteModal
                name={certiInfo.name}
                institutionName={certiInfo.institutionName}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
        );
    };

    // Update password
    const updatePasswordMutation = useMutation({
        mutationFn: ({ password, newPassword }) => updatePassword(password, newPassword, session, i18next.language),
        onMutate: () => {
            createFeedback('message', i18next.t('loading'));
        },

        onError: (error) => {
            createFeedback('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('password-updated'));
        },
        onSettled: () => {
            window.scrollTo(0, 0);
            setProfile({ ...profile, password: '', newPassword: '', confirmPassword: '' });
        },
    });

    // Set password
    const setPasswordMutation = useMutation({
        mutationFn: (password) => setPassword(password, session, i18next.language),
        onSuccess: () => {
            if (competition) {
                navigate('/competitions/private');
            }
        },
    });

    // Load profile data
    useEffect(() => {
        if (userQuery.data) {
            setProfile((prev) => ({ ...prev, ...userQuery.data }));
        }
    }, [userQuery.data]);

    // Load Professional Profile data
    useEffect(() => {
        if (professionalProfileQuery.data) {
            setProfessionalProfile((prev) => ({ ...prev, ...professionalProfileQuery.data }));
        }
    }, [professionalProfileQuery.data]);

    // Load User Jobs data
    useEffect(() => {
        if (userJobsQuery.data) {
            setUserJobs(userJobsQuery.data || false);
        }
    }, [userJobsQuery.data]);

    // Load User Education data
    useEffect(() => {
        if (userEducationQuery.data) {
            setUserEducation(userEducationQuery.data || false);
        }
    }, [userEducationQuery.data]);

    // Load User Certifications data
    useEffect(() => {
        if (userCertificationsQuery.data) {
            setUserCertifications(userCertificationsQuery.data || false);
        }
    }, [userCertificationsQuery.data]);

    /**
     * Update user profile
     */
    const handleUpdateUser = () => {
        const newProfile = { ...profile };
        delete newProfile.password;
        delete newProfile.newPassword;
        delete newProfile.confirmPassword;

        const missingFields = [];

        if (!newProfile.firstName) {
            missingFields.push(i18next.t('first-name'));
        }

        if (!newProfile.lastName || !/^[a-zA-Z]+$/.test(newProfile.lastName)) {
            missingFields.push(i18next.t('last-name'));
        }

        if (
            !newProfile.phone ||
            !/^(\(\+?\d{2,3}\)[*|\s|\-|.]?(([\d][*|\s|\-|.]?){6})(([\d][\s|\-|.]?){2})?|(\+?[\d][\s|\-|.]?){8}(([\d][\s|\-|.]?){2}(([\d][\s|\-|.]?){2})?)?)$/.test(
                newProfile.phone,
            )
        ) {
            missingFields.push(i18next.t('phone-number'));
        }

        if (!newProfile.country) {
            missingFields.push(i18next.t('country'));
        }

        if (missingFields.length > 0) {
            window.scrollTo(0, 0);
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${missingFields.join(', ')}`;
            createFeedback('warn', errorMessage);
            return;
        }

        updateUserMutation.mutate(newProfile);
    };

    /**
     * Update professional profile
     */
    const handleUpdateProfessionalUser = async () => {
        const { salary, englishLevel, city } = professionalProfile;
        let { currency } = professionalProfile;

        currency = currency || 'COP';

        const missingFields = [];

        if (!englishLevel || !['BASIC', 'MEDIUM', 'HIGH', 'NATIVE', 'UNKNOWN'].includes(englishLevel)) {
            missingFields.push(i18next.t('english-level'));
        }

        if (!salary || isNaN(salary)) {
            missingFields.push(i18next.t('salary'));
        }

        if (!currency || !['COP', 'USD', 'EUR', 'ARS', 'CLP', 'PEN', 'BRL', 'GBP', 'CAD'].includes(currency)) {
            missingFields.push(i18next.t('currency'));
        }

        if (missingFields.length > 0) {
            const errorMessage = `${i18next.t('complete-the-form-data')}: ${missingFields.join(', ')}`;
            createFeedback('warn', errorMessage);
            return;
        }

        if (city !== '') {
            try {
                const cityValidationResult = await validateCity(professionalProfile.city);
                if (cityValidationResult.context === 'error') {
                    createFeedback('warn', i18next.t(`city-validation-error`));
                    return;
                }
            } catch (error) {
                createFeedback('error', i18next.t('city-validation-error'));
                return;
            }
        }

        updateProfessionalProfileMutation.mutate({ salary, englishLevel, currency, city });
    };

    /**
     * Update user password
     */
    const handleUpdatePassword = () => {
        const { password, newPassword } = profile;

        // Validate empty values
        if (!password || !newPassword) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        // Validate password
        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        updatePasswordMutation.mutate({ password, newPassword });
    };

    /**
     * Set user password for peding user
     */
    const handleSetPassword = () => {
        const { password, confirmPassword } = profile;

        // Validate empty values
        if (!password || !confirmPassword) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        // Validate password
        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        // Passwords don't match
        if (password !== confirmPassword) {
            createFeedback('warn', i18next.t('passwords-not-match'));
            return;
        }

        setPasswordMutation.mutate(password);
    };

    /**
     * Change the showed page
     * @param {number} page
     */
    const changePage = (page) => {
        setIndexPage(page);
        removeFeedback();
    };

    // Handle session
    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    return (
        <NavbarTemplate>
            <div className="w-full sm:px-20 flex divide-x divide-zinc-300">
                <div className="w-1/3 flex flex-col gap-7 pr-10">
                    <div className="flex flex-col gap-5 items-center">
                        <FontAwesomeIcon icon={faUserCircle} size="6x" className="text-smgray-dark" />
                        <div className="w-full flex flex-col items-center gap-1">
                            <Title isLoading={userQuery.isLoading} size="md">
                                {userQuery.data?.username || ''}
                            </Title>
                            <Title isLoading={userQuery.isLoading} size="sm">
                                {userQuery.data?.email || ''}
                            </Title>
                        </div>
                    </div>

                    <div className="w-full flex flex-col gap-3">
                        <Button
                            isLoading={userQuery.isLoading}
                            icon={faIdCard}
                            color="blue"
                            onClick={() => navigate(`/profile/details/${profile?.guid}`)}
                        >
                            {i18next.t('my-profile')}
                        </Button>
                        <Button
                            icon={faUser}
                            color={classNames(indexPage === 0 ? 'green' : 'white')}
                            onClick={() => changePage(0)}
                        >
                            {i18next.t('about-you')}
                        </Button>
                        <Button
                            icon={faShieldHalved}
                            color={classNames(indexPage === 1 ? 'green' : 'white')}
                            onClick={() => changePage(1)}
                        >
                            {i18next.t('security')}
                        </Button>
                        <Button
                            icon={faUserTie}
                            color={classNames(indexPage === 2 ? 'green' : 'white')}
                            onClick={() => changePage(2)}
                        >
                            {i18next.t('professional-profile')}
                        </Button>
                    </div>
                </div>

                <div className="w-2/3 flex flex-col gap-5 pl-10">
                    {feedbackElement}

                    {indexPage === 0 ? (
                        <div className="w-full flex flex-col gap-5">
                            <Title size="xl">{i18next.t('your-profile')}</Title>
                            <div className="w-full flex flex-col gap-3">
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('first-name')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.firstName || ''}
                                        onType={(value) => setProfile({ ...profile, firstName: value })}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('last-name')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.lastName || ''}
                                        onType={(value) => setProfile({ ...profile, lastName: value })}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('phone-number')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.phone || ''}
                                        onType={(value) => setProfile({ ...profile, phone: value })}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('origin')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.origin || ''}
                                        onType={(value) => setProfile({ ...profile, origin: value })}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('birthday')}</Title>
                                    <Input
                                        color="white"
                                        type="date"
                                        value={profile.birthday || ''}
                                        onType={(value) => setProfile({ ...profile, birthday: value })}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('country')}</Title>
                                    <Select
                                        isLoading={countriesQuery.isLoading}
                                        selected={profile.country?.toString()}
                                        onSelect={(selected) => setProfile({ ...profile, country: selected })}
                                        options={countriesQuery.data || {}}
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('gender')}</Title>
                                    <Select
                                        selected={profile.sex || '_UNKNOWN'}
                                        onSelect={(selected) => setProfile({ ...profile, sex: selected })}
                                        options={{
                                            [null]: {
                                                _MALE: i18next.t('male'),
                                                _FEMALE: i18next.t('female'),
                                                _UNKNOWN: i18next.t('other'),
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <Title size="xl">{i18next.t('about-you')}</Title>
                            <div className="w-full flex flex-col gap-2">
                                <Title size="md">{i18next.t('biography')}</Title>
                                <Input
                                    color="white"
                                    type="textarea"
                                    value={profile.biography || ''}
                                    onType={(value) => setProfile({ ...profile, biography: value })}
                                />
                            </div>

                            <Title size="xl">{i18next.t('social-networks')}</Title>
                            <div className="w-full flex flex-col gap-3">
                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('website')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.web || ''}
                                        onType={(value) => setProfile({ ...profile, web: value })}
                                    />
                                </div>

                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('linkedin-username')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.linkedin || ''}
                                        onType={(value) => setProfile({ ...profile, linkedin: value })}
                                    />
                                </div>

                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('github-username')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.github || ''}
                                        onType={(value) => setProfile({ ...profile, github: value })}
                                    />
                                </div>

                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('twitter-username')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.twitter || ''}
                                        onType={(value) => setProfile({ ...profile, twitter: value })}
                                    />
                                </div>

                                <div className="w-full flex flex-col gap-2">
                                    <Title size="md">{i18next.t('facebook-username')}</Title>
                                    <Input
                                        color="white"
                                        type="text"
                                        value={profile.facebook || ''}
                                        onType={(value) => setProfile({ ...profile, facebook: value })}
                                    />
                                </div>
                            </div>

                            <Button
                                isLoading={updateUserMutation.isLoading}
                                color="green"
                                icon={faPaperPlane}
                                onClick={handleUpdateUser}
                            >
                                {i18next.t('update-profile')}
                            </Button>
                        </div>
                    ) : indexPage === 1 ? (
                        userQuery.data?.pending ? (
                            <div className="w-full flex flex-col gap-5">
                                <Title size="xl">{i18next.t('set-password')}</Title>
                                <div className="w-full flex flex-col gap-3">
                                    <div className="w-full flex flex-col gap-2">
                                        <Title size="md">{i18next.t('password')}</Title>
                                        <Input
                                            color="white"
                                            type="password"
                                            complete="new-password"
                                            focus
                                            value={profile.password}
                                            onType={(value) => setProfile({ ...profile, password: value })}
                                        />
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <Title size="md">{i18next.t('confirm-password')}</Title>
                                        <Input
                                            color="white"
                                            type="password"
                                            complete="new-password"
                                            value={profile.confirmPassword}
                                            onType={(value) => setProfile({ ...profile, confirmPassword: value })}
                                        />
                                    </div>
                                </div>

                                <Button
                                    isLoading={setPasswordMutation.isLoading}
                                    color="green"
                                    icon={faPaperPlane}
                                    onClick={handleSetPassword}
                                >
                                    {i18next.t('set-password')}
                                </Button>
                            </div>
                        ) : (
                            <div className="w-full flex flex-col gap-5">
                                <Title size="xl">{i18next.t('update-password')}</Title>
                                <div className="w-full flex flex-col gap-3">
                                    <div className="w-full flex flex-col gap-2">
                                        <Title size="md">{i18next.t('current-password')}</Title>
                                        <Input
                                            color="white"
                                            type="password"
                                            complete="current-password"
                                            focus
                                            value={profile.password}
                                            onType={(value) => setProfile({ ...profile, password: value })}
                                        />
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <Title size="md">{i18next.t('new-password')}</Title>
                                        <Input
                                            color="white"
                                            type="password"
                                            complete="new-password"
                                            value={profile.newPassword}
                                            onType={(value) => setProfile({ ...profile, newPassword: value })}
                                        />
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <Title size="md">{i18next.t('confirm-password')}</Title>
                                        <Input
                                            color="white"
                                            type="password"
                                            complete="new-password"
                                            value={profile.confirmPassword}
                                            onType={(value) => setProfile({ ...profile, confirmPassword: value })}
                                        />
                                    </div>
                                </div>
                                <Button
                                    isLoading={updatePasswordMutation.isLoading}
                                    color="green"
                                    icon={faPaperPlane}
                                    onClick={handleUpdatePassword}
                                >
                                    {i18next.t('update-password')}
                                </Button>
                            </div>
                        )
                    ) : indexPage === 2 ? (
                        <div className="w-full flex flex-col gap-5">
                            <Title size="xl">{i18next.t('professional-profile')}</Title>
                            <div className="w-full flex flex-col gap-2">
                                <Title size="md">{i18next.t('english-level')}</Title>
                                <Select
                                    color="white"
                                    selected={professionalProfile.englishLevel || '_UNKNOWN'}
                                    onSelect={(selected) =>
                                        setProfessionalProfile({ ...professionalProfile, englishLevel: selected })
                                    }
                                    options={{
                                        [null]: {
                                            BASIC: i18next.t('basic'),
                                            MEDIUM: i18next.t('medium'),
                                            HIGH: i18next.t('high'),
                                            NATIVE: i18next.t('native'),
                                            UNKNOWN: i18next.t('none'),
                                        },
                                    }}
                                    order={englishLevelOrder}
                                />
                            </div>
                            <div className="w-full flex flex-col gap-2">
                                <Title size="md">{i18next.t('salary')}</Title>
                                <div className="w-full flex">
                                    <div className="w-1/3">
                                        <Input
                                            color="white"
                                            type="number"
                                            value={professionalProfile.salary?.toString() || ''}
                                            onType={(value) =>
                                                setProfessionalProfile({ ...professionalProfile, salary: value })
                                            }
                                        />
                                    </div>
                                    <div className="w-2/3 ml-2">
                                        <Select
                                            color="white"
                                            selected={professionalProfile.currency || 'COP'}
                                            onSelect={(selected) =>
                                                setProfessionalProfile({ ...professionalProfile, currency: selected })
                                            }
                                            options={{
                                                [null]: {
                                                    COP: i18next.t('currency-colombia'),
                                                    USD: i18next.t('currency-usa'),
                                                    EUR: '(EUR) Euro',
                                                    ARS: i18next.t('currency-argentina'),
                                                    CLP: i18next.t('currency-chile'),
                                                    PEN: i18next.t('currency-peru'),
                                                    BRL: i18next.t('currency-brazil'),
                                                    GBP: i18next.t('currency-uk'),
                                                    CAD: i18next.t('currency-canada'),
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-2">
                                <Title size="md">{i18next.t('city')}</Title>
                                <Input
                                    color="white"
                                    type="text"
                                    value={professionalProfile.city || ''}
                                    onType={(value) => setProfessionalProfile({ ...professionalProfile, city: value })}
                                />
                            </div>
                            <Button
                                isLoading={updateProfessionalProfileMutation.isLoading}
                                color="green"
                                icon={faPaperPlane}
                                onClick={handleUpdateProfessionalUser}
                            >
                                {i18next.t('update-professional-profile')}
                            </Button>
                            {(userJobs == null || userJobs === false) &&
                            (userEducation == null || userEducation === false) &&
                            (userCertifications == null || userCertifications === false) ? (
                                <div className="w-full flex flex-col gap-2">
                                    <div className="w-full flex items-center justify-center mt-8 mb-1">
                                        <Banner color="green" borderColor="green">
                                            {i18next.t('talent-history')}
                                        </Banner>
                                    </div>
                                    <div className="w-full flex items-center">
                                        <div className="w-1/6 flex items-center justify-center">
                                            <Button
                                                circle
                                                color="blue"
                                                icon={faPlus}
                                                title={i18next.t('create-new')}
                                                onClick={handleJobCreate}
                                            />
                                        </div>
                                        <div className="w-5/6">
                                            <Banner color="white" borderColor="green">
                                                {i18next.t('work-experience')}
                                            </Banner>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <div className="w-full flex items-center mt-4">
                                            <div className="w-1/6 flex items-center justify-center">
                                                <Button
                                                    circle
                                                    color="blue"
                                                    icon={faPlus}
                                                    title={i18next.t('create-new')}
                                                    onClick={handleEducationCreate}
                                                />
                                            </div>
                                            <div className="w-5/6">
                                                <Banner color="white" borderColor="green">
                                                    {i18next.t('education')}
                                                </Banner>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <div className="w-full flex items-center mt-4">
                                            <div className="w-1/6 flex items-center justify-center">
                                                <Button
                                                    circle
                                                    color="blue"
                                                    icon={faPlus}
                                                    title={i18next.t('create-new')}
                                                    onClick={handleCertificationCreate}
                                                />
                                            </div>
                                            <div className="w-5/6">
                                                <Banner color="white" borderColor="green">
                                                    {i18next.t('certifications')}
                                                </Banner>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full flex flex-col gap-2">
                                    <div className="w-full flex items-center justify-center mt-8 mb-1">
                                        <Banner color="green" borderColor="green">
                                            {i18next.t('talent-history')}
                                        </Banner>
                                    </div>
                                    <div className="w-full flex items-center">
                                        <div className="w-1/6 flex items-center justify-center">
                                            <Button
                                                circle
                                                color="blue"
                                                icon={faPlus}
                                                title={i18next.t('create-new')}
                                                onClick={handleJobCreate}
                                            />
                                        </div>
                                        <div className="w-5/6">
                                            <Banner color="white" borderColor="green">
                                                {i18next.t('work-experience')}
                                            </Banner>
                                        </div>
                                    </div>
                                    {userJobs != null && userJobs !== false && (
                                        <div className="w-full flex items-center justify-center mt-1 mb-1">
                                            {userJobsQuery.isLoading ? (
                                                <Skeleton
                                                    count={2}
                                                    baseColor="#e4e4e7"
                                                    highlightColor="#f4f4f5"
                                                    height="100%"
                                                    className="mb-5 h-8"
                                                    containerClassName="w-full h-5"
                                                />
                                            ) : (
                                                <div className="flex flex-col w-full gap-y-3">
                                                    {userJobs &&
                                                        userJobs.map((jobsInfoObj) => (
                                                            <div key={jobsInfoObj.jobID} className="mt-1">
                                                                <CardToggle
                                                                    size="lg"
                                                                    key={jobsInfoObj.jobID}
                                                                    title={`${jobsInfoObj?.title} ${i18next.t('at')} ${
                                                                        jobsInfoObj?.company
                                                                    }`}
                                                                >
                                                                    <div className="flex flex-wrap">
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('job-title')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>{jobsInfoObj.title}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('job-company')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>{jobsInfoObj.company}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('job-country')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>{jobsInfoObj.country}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('job-salary')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5 flex-wrap">
                                                                                <span>{jobsInfoObj.salary}</span>&nbsp;
                                                                                <span>{jobsInfoObj.currency}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('job-type')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                {jobsInfoObj.type === 'FULL_TIME' && (
                                                                                    <span>
                                                                                        {i18next.t('type-full-time')}
                                                                                    </span>
                                                                                )}
                                                                                {jobsInfoObj.type === 'PART_TIME' && (
                                                                                    <span>
                                                                                        {i18next.t('type-part-time')}
                                                                                    </span>
                                                                                )}
                                                                                {jobsInfoObj.type === 'FREELANCE' && (
                                                                                    <span>
                                                                                        {i18next.t('type-freelance')}
                                                                                    </span>
                                                                                )}
                                                                                {jobsInfoObj.type === 'INTERNSHIP' && (
                                                                                    <span>
                                                                                        {i18next.t('type-internship')}
                                                                                    </span>
                                                                                )}
                                                                                {jobsInfoObj.type ===
                                                                                    'APPRENTICESHIP' && (
                                                                                    <span>
                                                                                        {i18next.t(
                                                                                            'type-apprenticeship',
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('start-date')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>
                                                                                    {formatDate(
                                                                                        jobsInfoObj.startDate,
                                                                                        true,
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {jobsInfoObj.endDate != null && (
                                                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                                <div className="w-2/5">
                                                                                    <Title size="sm">
                                                                                        {i18next.t('end-date')}
                                                                                    </Title>
                                                                                </div>
                                                                                <div className="w-3/5">
                                                                                    <span>
                                                                                        {jobsInfoObj.endDate !== false
                                                                                            ? formatDate(
                                                                                                  jobsInfoObj.endDate,
                                                                                                  true,
                                                                                              )
                                                                                            : i18next.t('present')}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {jobsInfoObj.description != null &&
                                                                            jobsInfoObj.description !== false && (
                                                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                                    <div className="w-2/5">
                                                                                        <Title size="sm">
                                                                                            {i18next.t('description')}
                                                                                        </Title>
                                                                                    </div>
                                                                                    <div className="w-3/5">
                                                                                        <span>
                                                                                            {jobsInfoObj.description}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                    <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="blue"
                                                                                icon={faPenToSquare}
                                                                                onClick={() =>
                                                                                    handleJobUpdate(jobsInfoObj)
                                                                                }
                                                                            >
                                                                                {i18next.t('edit')}
                                                                            </Button>
                                                                        </div>
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="red"
                                                                                icon={faTrash}
                                                                                onClick={() =>
                                                                                    handleJobDelete({
                                                                                        jobID: jobsInfoObj.jobID,
                                                                                        title: jobsInfoObj.title,
                                                                                        company: jobsInfoObj.company,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {i18next.t('delete')}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </CardToggle>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="w-full flex items-center mt-4">
                                        <div className="w-1/6 flex items-center justify-center">
                                            <Button
                                                circle
                                                color="blue"
                                                icon={faPlus}
                                                title={i18next.t('create-new')}
                                                onClick={handleEducationCreate}
                                            />
                                        </div>
                                        <div className="w-5/6">
                                            <Banner color="white" borderColor="green">
                                                {i18next.t('education')}
                                            </Banner>
                                        </div>
                                    </div>
                                    {userEducation != null && userEducation !== false && (
                                        <div className="w-full flex items-center justify-center mt-1 mb-1">
                                            {userEducationQuery.isLoading ? (
                                                <Skeleton
                                                    count={2}
                                                    baseColor="#e4e4e7"
                                                    highlightColor="#f4f4f5"
                                                    height="100%"
                                                    className="mb-5 h-8"
                                                    containerClassName="w-full h-5"
                                                />
                                            ) : (
                                                <div className="flex flex-col w-full gap-y-3">
                                                    {userEducation &&
                                                        userEducation.map((educationInfoObj) => (
                                                            <div key={educationInfoObj.educationID} className="mt-1">
                                                                <CardToggle
                                                                    size="lg"
                                                                    key={educationInfoObj.educationID}
                                                                    title={educationInfoObj.institutionName}
                                                                >
                                                                    <div className="flex flex-wrap">
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('education-institution')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>
                                                                                    {educationInfoObj.institutionName}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('education-degree')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>{educationInfoObj.degree}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('education-area')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>{educationInfoObj.area}</span>
                                                                            </div>
                                                                        </div>
                                                                        {educationInfoObj.description != null &&
                                                                            educationInfoObj.description !== false && (
                                                                                <div className="flex itefms-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                                    <div className="w-2/5">
                                                                                        <Title size="sm">
                                                                                            {i18next.t('description')}
                                                                                        </Title>
                                                                                    </div>
                                                                                    <div className="w-3/5">
                                                                                        <span>
                                                                                            {
                                                                                                educationInfoObj.description
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('start-date')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>
                                                                                    {formatDate(
                                                                                        educationInfoObj.startDate,
                                                                                        true,
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-2/5">
                                                                                <Title size="sm">
                                                                                    {i18next.t('end-date')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-3/5">
                                                                                <span>
                                                                                    {educationInfoObj.endDate !== false
                                                                                        ? formatDate(
                                                                                              educationInfoObj.endDate,
                                                                                              true,
                                                                                          )
                                                                                        : i18next.t('present')}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="blue"
                                                                                icon={faPenToSquare}
                                                                                onClick={() =>
                                                                                    handleEducationUpdate(
                                                                                        educationInfoObj,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {i18next.t('edit')}
                                                                            </Button>
                                                                        </div>
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="red"
                                                                                icon={faTrash}
                                                                                onClick={() =>
                                                                                    handleEducationDelete({
                                                                                        educationID:
                                                                                            educationInfoObj.educationID,
                                                                                        institutionName:
                                                                                            educationInfoObj.institutionName,
                                                                                        degree: educationInfoObj.degree,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {i18next.t('delete')}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </CardToggle>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="w-full flex flex-col gap-2">
                                        <div className="w-full flex items-center mt-4">
                                            <div className="w-1/6 flex items-center justify-center">
                                                <Button
                                                    circle
                                                    color="blue"
                                                    icon={faPlus}
                                                    title={i18next.t('create-new')}
                                                    onClick={handleCertificationCreate}
                                                />
                                            </div>
                                            <div className="w-5/6">
                                                <Banner color="white" borderColor="green">
                                                    {i18next.t('certifications')}
                                                </Banner>
                                            </div>
                                        </div>
                                        {userCertifications != null && userCertifications !== false && (
                                            <div className="w-full flex items-center justify-center mt-1 mb-1">
                                                {userCertificationsQuery.isLoading ? (
                                                    <Skeleton
                                                        count={2}
                                                        baseColor="#e4e4e7"
                                                        highlightColor="#f4f4f5"
                                                        height="100%"
                                                        className="mb-5 h-8"
                                                        containerClassName="w-full h-5"
                                                    />
                                                ) : (
                                                    <div className="flex flex-col w-full gap-y-3">
                                                        {userCertifications &&
                                                            userCertifications.map((certiInfoObj) => (
                                                                <CardToggle
                                                                    size="lg"
                                                                    key={certiInfoObj.certificationID}
                                                                    title={certiInfoObj.name}
                                                                >
                                                                    <div className="flex flex-wrap">
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-1/3">
                                                                                <Title size="sm">
                                                                                    {i18next.t('certification-name')}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-2/3">
                                                                                <span>{certiInfoObj.name}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-1/3">
                                                                                <Title size="sm">
                                                                                    {i18next.t(
                                                                                        'certification-institution',
                                                                                    )}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-2/3">
                                                                                <span>
                                                                                    {certiInfoObj.institutionName}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-1/3">
                                                                                <Title size="sm">
                                                                                    {i18next.t(
                                                                                        'certification-issueDate',
                                                                                    )}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-2/3">
                                                                                <span>
                                                                                    {formatDate(
                                                                                        certiInfoObj.issueDate,
                                                                                        true,
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {certiInfoObj.credentialID != null &&
                                                                            certiInfoObj.credentialID !== false && (
                                                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                                    <div className="w-1/3">
                                                                                        <Title size="sm">
                                                                                            {i18next.t(
                                                                                                'certification-credentialID',
                                                                                            )}
                                                                                        </Title>
                                                                                    </div>
                                                                                    <div className="w-2/3">
                                                                                        <span>
                                                                                            {certiInfoObj.credentialID}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                                            <div className="w-1/3">
                                                                                <Title size="sm">
                                                                                    {i18next.t(
                                                                                        'certification-credentialURL',
                                                                                    )}
                                                                                </Title>
                                                                            </div>
                                                                            <div className="w-2/3">
                                                                                <span>
                                                                                    {certiInfoObj.credentialURL}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="blue"
                                                                                icon={faPenToSquare}
                                                                                onClick={() =>
                                                                                    handleCertificationUpdate(
                                                                                        certiInfoObj,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {i18next.t('edit')}
                                                                            </Button>
                                                                        </div>
                                                                        <div className="flex w-full lg:w-2/5">
                                                                            <Button
                                                                                color="red"
                                                                                icon={faTrash}
                                                                                onClick={() =>
                                                                                    handleCertificationDelete({
                                                                                        certificationID:
                                                                                            certiInfoObj.certificationID,
                                                                                        name: certiInfoObj.name,
                                                                                        institutionName:
                                                                                            certiInfoObj.institutionName,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {i18next.t('delete')}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </CardToggle>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default Profile;
