/* eslint-disable react/prop-types */

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

/**
 * Render a skeleton loader element
 * @param {{
 *      color: 'white' | 'gray' | 'green';
 *      shadow: boolean;
 *      circle: boolean;
 *      width: number | undefined;
 *      height: number | undefined;
 * }} props
 *
 * @returns {JSX.Element} Loader skeleton
 */
export default function Loader({ color = 'white', shadow = false, circle = false, width, height }) {
    return (
        <Skeleton
            width={width}
            height={height}
            circle={circle}
            className={classNames('rounded-md', { 'shadow-md': shadow })}
            baseColor={classNames({
                '#f3f4f6': color === 'white',
                '#e5e7eb': color === 'gray',
                '#166534': color === 'green',
            })}
            highlightColor={classNames({
                '#e5e7eb': color === 'white',
                '#d1d5db': color === 'gray',
                '#15803d': color === 'green',
            })}
        />
    );
}
