import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import useSession from '../../hooks/useSession';
import getRecruiterCategories from '../../services/getRecruiterCategories';
import Filters from '../molecules/Filters';
import UserTopScore from '../organisms/UserTopScore';
import NavbarTemplate from '../templates/NavbarTemplate';

/**
 * Recruiter page shows the list of talents
 */
function RecruiterPage() {
    const { session } = useSession();
    const [categoriesList, setCategoriesList] = useState([]);
    const [filters, setFilters] = useState();

    // Get categories and update filters
    const categoriesQuery = useQuery({
        queryKey: [session, 'categoriesList'],
        queryFn: () => getRecruiterCategories(session, i18next.language),
        enabled: !!session,
    });

    // Load categories for filters
    useEffect(() => {
        if (categoriesQuery.data) {
            const { categories } = categoriesQuery.data;
            const categoriesFilter = {
                key: 'categories',
                unique: false,
                title: i18next.t('categories'),
                checkboxes: categories.map(({ name }) => ({ key: `${name}`, value: name, checked: false })),
            };

            setFilters([categoriesFilter]);
        }
    }, [categoriesQuery.data]);

    // Update categoriesList on change filters
    useEffect(() => {
        if (filters) {
            const categories = filters
                .find((filter) => filter.key === 'categories')
                .checkboxes.filter((checkbox) => checkbox.checked)
                .map((checkbox) => checkbox.key);

            setCategoriesList(categories);
        }
    }, [filters]);

    return (
        <NavbarTemplate>
            <div className="w-full flex 2xl:flex-row flex-col-reverse sm:gap-10 gap-5">
                <div className="w-full flex sm:flex-col 2xl:flex-row gap-5">
                    <Filters
                        isLoading={categoriesQuery.isLoading}
                        filters={filters}
                        onFilter={(newFilters) => setFilters(newFilters)}
                    />
                    <UserTopScore categoriesList={categoriesList} />
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default RecruiterPage;
