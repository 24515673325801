import { faBan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';

/**
 * AccessCamera modal warns about mandatory camera access
 * @param {{ onCancel: () => void }} props
 */
function AccessCamera({ onCancel }) {
    return (
        <div className="w-full p-10 flex flex-col gap-10 items-center">
            <div className="w-full flex flex-col gap-5 items-center">
                <FontAwesomeIcon icon={faTriangleExclamation} className="text-smorange-dark" size="5x" />
                <p>{i18next.t('accept-access-camera')}</p>
            </div>
            <Button color="green" icon={faBan} onClick={onCancel}>
                {i18next.t('cancel')}
            </Button>
        </div>
    );
}

AccessCamera.propTypes = {
    onCancel: PropTypes.func.isRequired,
};

export default AccessCamera;
