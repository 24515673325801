/**
 * updateAdminCompetition updates a competition.
 * @method PUT
 * @param {string} guid
 * @param {string} name
 * @param {string} status
 * @param {string} visibility
 * @param {string|null} description
 * @param {string|null} duration
 * @param {string|null} challenges
 * @param {string|null} startDate
 * @param {string|null} endDate
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
*      messages: string;
*      context: 'success' | 'message' | 'warn' | 'error';
* }>}
*/
export default async function updateAdminCompetition(guid, name, status, visibility, description, duration, challenges, startDate, endDate, jwt, language) {
    
    try {
    const url = `${process.env.REACT_APP_APP_URL}/api/v1/competition/${guid}`;
       const method = 'PUT';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           'User-Agent': window.navigator.userAgent,
           Authorization: jwt,
       };

       const body = JSON.stringify({ name, status, visibility, description, duration, challenges, startDate, endDate});

       const response = await fetch(url, { method, headers, body });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}