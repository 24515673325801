import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const toastColors = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    info: 'bg-blue-500 text-white',
    warning: 'bg-yellow-500 text-black',
    default: 'bg-gray-500 text-white',
};

/**
 * Toast component displays a message with a specific style and auto-dismisses after a set duration.
 * @param {{
*      message: string;
*      type: 'success' | 'error' | 'info' | 'warning' | 'default';
*      isVisible: boolean;
*      duration: number;
*      onClose: (() => void) | undefined;
* }} props
*/
function Toast({ message, type, isVisible, duration, onClose }) {
    const [show, setShow] = useState(isVisible);

    useEffect(() => {
        let timer;
        if (isVisible) {
            setShow(true);
            timer = setTimeout(() => {
                setShow(false);
                if (onClose) onClose();
            }, duration);
        } else {
            setShow(false);
        }

        return () => clearTimeout(timer);
    }, [isVisible, duration, onClose]);

    const handleClick = () => {
        setShow(false);
        if (onClose) onClose();
    };

    return (
        show && (
            <div
                role="alert"
                className={classNames(
                    'fixed bottom-4 right-4 px-4 py-2 rounded-md shadow-lg select-none',
                    toastColors[type] || toastColors.default
                )}
            >
                <div className="flex items-center justify-between">
                    <p className="pr-4">{message}</p>
                    <button
                        type="button"
                        className="text-white hover:text-gray-200 transition-colors duration-200"
                        onClick={handleClick}
                        aria-label="close"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        )
    );
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning', 'default']),
    isVisible: PropTypes.bool,
    duration: PropTypes.number,
    onClose: PropTypes.func,
};

Toast.defaultProps = {
    type: 'default',
    isVisible: false,
    duration: 3000,
    onClose: undefined,
};

export default Toast;