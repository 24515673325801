import PropTypes from 'prop-types';

function Repeat({ count, children }) {
    return <>{Array.from({ length: count }).map(() => children)}</>;
}

Repeat.propTypes = {
    count: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default Repeat;
