import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import repeat from '../../utils/repeat';

/**
 * Checkbox atom creates a series of checkboxes for single/multiple selection with a loading state
 * @param {{
 *      isLoading: boolean;
 *      useKeys: boolean;
 *      color: 'green' | 'blue' | 'orange' | 'red';
 *      unique: boolean;
 *      grid: boolean;
 *      values: {
 *          key: string;
 *          checked: boolean;
 *          value: React.ReactNode;
 *      }[];
 *      onCheck: (values: {
 *          key: string;
 *          checked: boolean;
 *          value: React.ReactNode;
 *      }[]) => void;
 * }} props
 */
function Checkbox({ isLoading, useKeys, color, unique, grid, values, onCheck }) {
    /**
     * Update values and run onCheck
     * @type {(index: number, value: boolean) => void}
     */
    const handleCheck = useCallback(
        (index, value) => {
            const newValues = [...values];
            if (unique) {
                for (let i = 0; i < newValues.length; i += 1) {
                    newValues[i].checked = false;
                }
            }

            newValues[index].checked = value;
            onCheck(newValues);
        },
        [unique, values, onCheck],
    );

    /**
     * Update the checked field of the checkbox via keyboard
     * @type {(event: KeyboardEvent) => void}
     */
    const handleKeyPress = useCallback(
        (event) => {
            if (!isLoading && useKeys) {
                const index = parseInt(event.key, 10) - 1;
                if (index >= 0 && index < values.length) {
                    event.preventDefault();
                    handleCheck(index, !values[index].checked);
                }
            }
        },
        [handleCheck, isLoading, useKeys, values],
    );

    // Add Keyboards Events
    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress);
        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [handleKeyPress]);

    if (isLoading) {
        return (
            <div className={classNames(grid ? 'grid grid-cols-2 gap-x-5 gap-y-3' : 'flex flex-col gap-3')}>
                {repeat(
                    grid ? 6 : 4,
                    <div className="w-full flex gap-3">
                        <Skeleton
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            circle={unique}
                            borderRadius={4}
                            containerClassName="w-5 h-5"
                        />
                        <Skeleton
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            borderRadius={4}
                            containerClassName="flex-1 h-5"
                        />
                    </div>,
                )}
            </div>
        );
    }

    return (
        <div className={classNames(grid ? 'grid grid-cols-2 gap-x-5 gap-y-3' : 'flex flex-col gap-3')}>
            {values.map((checkbox, index) => (
                <button
                    key={`checkbox:${checkbox.key}`}
                    type="button"
                    onClick={() => handleCheck(index, !checkbox.checked)}
                    onKeyDown={(event) => event.preventDefault()}
                    className="w-full flex gap-3 items-center select-none outline-none"
                >
                    <div
                        className={classNames(
                            'w-5 h-5 flex-shrink-0 text-white flex justify-center items-center',
                            unique ? 'rounded-full' : 'rounded',
                            {
                                'bg-smgray': !checkbox.checked,
                                'bg-smgreen-dark': checkbox.checked && color === 'green',
                                'bg-smblue-dark': checkbox.checked && color === 'blue',
                                'bg-smorange-dark': checkbox.checked && color === 'orange',
                                'bg-smred-dark': checkbox.checked && color === 'red',
                            },
                        )}
                    >
                        {checkbox.checked && <FontAwesomeIcon icon={faCheck} className="h-2.5 aspect-square" />}
                    </div>
                    <div className="text-left">{checkbox.value}</div>
                </button>
            ))}
        </div>
    );
}

Checkbox.propTypes = {
    isLoading: PropTypes.bool,
    useKeys: PropTypes.bool,
    color: PropTypes.oneOf(['green', 'blue', 'orange', 'red']).isRequired,
    unique: PropTypes.bool,
    grid: PropTypes.bool,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.node.isRequired,
            checked: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    onCheck: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
    isLoading: false,
    useKeys: false,
    unique: false,
    grid: false,
};

export default Checkbox;
