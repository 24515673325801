import i18next from 'i18next';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CopyInput({ value, label }) {
    const inputRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        if (inputRef.current) {
            inputRef.current.select();
            navigator.clipboard.writeText(value);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 5000);
        }
    };

    const inputId = `copy-input-${label.replace(/\s+/g, '-').toLowerCase()}`;

    return (
        <div className="w-full">
            {label && (
                <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mb-1">
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    ref={inputRef}
                    id={inputId}
                    type="text"
                    value={value}
                    readOnly
                    className="w-full px-4 py-2 pr-10 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-srorange focus:border-srorange"
                />
                <button
                    type="button"
                    onClick={handleCopy}
                    title={i18next.t('copy-clipboard')}
                    className={classNames(
                        "absolute inset-y-0 right-0 flex items-center px-3 rounded-r-md",
                        "text-gray-500 hover:text-srorange-dark transition-colors duration-200",
                        "focus:outline-none focus:ring-2 focus:ring-srorange focus:border-srorange"
                    )}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </button>
            </div>
            {isCopied && (
                <p className="mt-1 text-xs text-srorange-dark">{i18next.t('copied-into-clipboard')}</p>
            )}
        </div>
    );
}

CopyInput.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
};

CopyInput.defaultProps = {
    label: '',
};

export default CopyInput;