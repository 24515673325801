import { faBan, faPaperPlane, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';

/**
 * ConfirmationModal for sending an answer
 * @param {{
 *      message: string;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function ConfirmationModal({ message, onAccept, onCancel }) {
    return (
        <div className="w-full p-10 flex flex-col gap-10 items-center">
            <FontAwesomeIcon icon={faCircleQuestion} className="sm:h-20 h-10 text-smgreen-dark" />
            <p className="text-center">{message}</p>
            <div className="w-full flex gap-5">
                <Button color="green" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="white" icon={faPaperPlane} onClick={onAccept}>
                    {i18next.t('send-answer')}
                </Button>
            </div>
        </div>
    );
}

ConfirmationModal.propTypes = {
    message: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;