import PropTypes from 'prop-types';

const Spinner = function Spinner({ isLoading, message, type }) {
    if (!isLoading) return null;

    const getColorClass = () => {
        switch (type) {
            case 'warn':
                return 'text-smorange-dark';
            case 'success':
                return 'text-smgreen-dark';
            case 'info':
                return 'text-smgray-dark';
            default:
                return 'text-smgray-dark';
        }
    };

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90 z-50">
            <div className="w-16 h-16 border-4 border-t-transparent border-black rounded-full animate-spin" />
            <span className={`text-2xl font-semibold mt-4 ${getColorClass()}`}>{message}</span>
        </div>
    );
};

Spinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.string,
    type: PropTypes.oneOf(['warn', 'success', 'info']),
};

Spinner.defaultProps = {
    message: '',
    type: 'info',
  };

export default Spinner;