import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import recruiterFilteredCategories from '../../services/recruiterFilteredCategories';
import Container from '../atoms/Container';
import Repeat from '../atoms/Repeat';
import Title from '../atoms/Title';
import CardToggle from '../molecules/CardToggle';
import Pager from '../molecules/Pager';

function UserTopScore({ categoriesList }) {
    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    };
    const { session } = useSession();
    const [page, setPage] = useState(0);

    const ranksPerPage = parseInt(process.env.REACT_APP_RANKS_PER_PAGE, 10);

    const usersTopQuery = useQuery({
        queryKey: [session, 'categoriesList', categoriesList, page],
        queryFn: () => recruiterFilteredCategories(page + 1, categoriesList, session, i18next.language),
        enabled: !!session,
    });

    return (
        <div className="w-full flex flex-col gap-5">
            <Title size="xl">{i18next.t('scoreboard')}</Title>
            <Container>
                <div className="w-full divide-y">
                    <div className="w-full flex p-4 gap-4 justify-around items-center">
                        <div className="w-16 text-center font-bold text-lg flex-shrink-0">{i18next.t('top')}</div>
                        <div className="w-60 text-center font-bold text-lg flex-shrink-0">{i18next.t('user')}</div>
                        <div className="w-26 text-center font-bold text-lg flex-shrink-0">{i18next.t('score')}</div>
                        <div className="w-96 text-center font-bold text-lg flex-shrink-0">{i18next.t('category')}</div>
                    </div>
                    {usersTopQuery.isLoading ? (
                        <Repeat count={ranksPerPage}>
                            <div className="w-full flex p-4 gap-4">
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-16 h-5 flex-shrink-0"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-full h-5"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-28 h-5 flex-shrink-0"
                                />
                                <Skeleton
                                    baseColor="#e4e4e7"
                                    highlightColor="#f4f4f5"
                                    height="100%"
                                    containerClassName="w-44 h-5 flex-shrink-0"
                                />
                            </div>
                        </Repeat>
                    ) : (
                        (usersTopQuery.data?.rankingByCat || []).map(({ GUID, UserName, TotalScore, tech }, index) => {
                            const displayedIndex = page * ranksPerPage + index + 1;
                            return (
                                <div
                                    key={`rank:${UserName}`}
                                    className="w-full flex p-4 gap-4 justify-around items-center"
                                >
                                    <div className="w-16 text-center font-semibold flex-shrink-0">
                                        {displayedIndex > 3 ? (
                                            <p className="font-semibold">{displayedIndex}</p>
                                        ) : (
                                            <FontAwesomeIcon
                                                size="xl"
                                                icon={faMedal}
                                                className={classNames({
                                                    'text-yellow-400': displayedIndex === 1,
                                                    'text-slate-400': displayedIndex === 2,
                                                    'text-amber-700': displayedIndex === 3,
                                                })}
                                            />
                                        )}
                                    </div>
                                    <div className="w-60 text-center flex gap-2 items-center">
                                        <button
                                            type="submit"
                                            className="w-full"
                                            onClick={() => navigateTo(`/profile/details/${GUID}`)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    navigateTo(`/profile/details/${GUID}`);
                                                }
                                            }}
                                            role="link"
                                            tabIndex={0}
                                        >
                                            <p>{UserName}</p>
                                        </button>
                                    </div>
                                    <div className="w-26 text-center flex-shrink-0">{TotalScore} pts</div>
                                    <div className="w-96 text-center justify-center">
                                        {tech && tech.length > 0 && (
                                            <CardToggle
                                                key={i18next.t('categories')}
                                                title={i18next.t('categories')}
                                                size="lg"
                                            >
                                                <div className="w-full flex flex-row justify-center items-center gap-8 pt-6 pb-6">
                                                    <ul className="w-full flex flex-col flex-wrap justify-around items-center ">
                                                        {tech.map((tech2) => (
                                                            <div
                                                                key={tech2.Category}
                                                                className="w-full flex flex-row justify-around items-center gap-5 pb-2"
                                                            >
                                                                {tech2.Score != null ? (
                                                                    <div className="flex flex-col">
                                                                        <li className="font-black text-gray-500">
                                                                            <span className="text-black font-black">
                                                                                {i18next.t('category')}
                                                                            </span>
                                                                        </li>
                                                                        <p className="text-black">{tech2.Category}</p>
                                                                    </div>
                                                                ) : null}
                                                                {tech2.Score != null ? (
                                                                    <div className="flex flex-col">
                                                                        <li className="font-black text-gray-500">
                                                                            <span className="text-black font-black">
                                                                                {i18next.t('total-score')}
                                                                            </span>
                                                                        </li>
                                                                        <p className="text-black">{tech2.Score}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </CardToggle>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </Container>

            {usersTopQuery.data?.rankingByCat && usersTopQuery.data.rankingByCat.length > 0 && (
                <div className="w-full flex justify-center">
                    <Pager pages={usersTopQuery.data.pages} page={page} setPage={setPage} />
                </div>
            )}
        </div>
    );
}

UserTopScore.propTypes = {
    categoriesList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserTopScore;
