// CompetitionViewModal.jsx

import { faCircle, faClose, faInfinity, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Tag from '../atoms/Tag';
import Banner from '../atoms/Banner';
import CardToggle from '../molecules/CardToggle';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';
import CopyInput from '../molecules/CopyInput';

/**
 * CompetitionViewModal allows the user to view an specific competition and its details.
 * @param {{
 *      data: object;
 *      onCancel: () => void;
 * }} props
 */
function CompetitionViewModal({
    data,
    onCancel
}) { 

    const categories = Object.keys(data.challenges || {});
    const totalChallenges = categories.reduce((total, category) => total + data.challenges[category].length, 0);

    return (
        <div>
            <div className="w-full p-5 flex flex-col gap-5">
                <div className="flex items-center justify-center">
                    {(() => {
                        switch (data.status) {
                            case "ACTIVE":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('active')}>
                                        <FontAwesomeIcon color="green" icon={faCircle} />
                                    </div>
                                );
                            case "INACTIVE":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('inactive')}>
                                        <FontAwesomeIcon color="orange" icon={faCircle} />
                                    </div>
                                );
                            case "DELETED":
                                return (
                                    <div className="w-1/12 text-center" title={i18next.t('deleted')}>
                                        <FontAwesomeIcon color="red" icon={faCircle} />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })()}
                    <div className="w-10/12 text-center truncate" title={i18next.t('name')}>
                        <Banner color="green" borderColor="white">{data.name}</Banner>
                    </div>
                    {data.visibility === "PUBLIC" ? (
                        <div className="w-1/12 text-center" title={i18next.t('pub')}>
                            <FontAwesomeIcon color="green" icon={faUnlock} />
                        </div>
                    ) : (
                        <div className="w-1/12 text-center" title={i18next.t('priv')}>
                            <FontAwesomeIcon color="blue" icon={faLock} />
                        </div>
                    )}
                </div>
                <CopyInput value={data.guid} label="GUID" />
                <div className="flex items-center justify-center gap-x-8">
                    <div className="flex w-1/3 flex-col items-center text-center">
                        <Tag size="md" color="gray">{i18next.t('duration')}</Tag>
                        <div className="w-full">
                            {
                                data.duration 
                                    ?   <div title={i18next.t('duration')}>
                                            {formatTime(data.duration)}
                                        </div>
                                    :   <div title={i18next.t('duration')}>
                                            <FontAwesomeIcon color="gray" icon={faInfinity} />
                                        </div>
                            }
                        </div>
                    </div>
                    {data.startDate && (
                        <div className="flex w-1/3 flex-col items-center text-center">
                            <Tag size="md" color="gray">{i18next.t('start-date')}</Tag>
                            <div className="w-full">
                                {data.startDate && formatDate(data.startDate.date)}
                            </div>
                        </div>
                    )}
                    {data.endDate && (
                        <div className="flex w-1/3 flex-col items-center text-center">
                            <Tag size="md" color="gray">{i18next.t('end-date')}</Tag>
                            <div className="w-full">
                                {data.endDate && formatDate(data.endDate.date)}
                            </div>
                        </div>
                    )}
                </div>
                {data.description && (
                    <div className="flex flex-col items-center justify-center gap-3">
                        <div className="inline-block">
                            <Tag size="md" color="gray">{i18next.t('description')}</Tag>
                        </div>
                        <div className="w-full border-y-2 border-gray-100 p-2" title={i18next.t('description')}>
                            {data.description}
                        </div>
                    </div>
                )}
            </div>  
            <div className="w-full p-5 flex flex-col gap-5">
                {categories.length > 0 && (
                    <div className="flex justify-start mb-3 gap-x-3">
                        <Tag size="md" color="orange" shadow>
                            {`${i18next.t('challenges')}: ${totalChallenges}`}
                        </Tag>
                        <Tag size="md" color="orange" shadow>
                            {`${i18next.t('categories')}: ${categories.length}`}
                        </Tag>
                    </div>
                )}
                {categories.length > 0 ? (
                    categories.map((category) => (
                        <div key={category}>
                            <div className="flex justify-left mb-3 ml-5">
                                <div title={i18next.t('category-name')}> 
                                    <Tag size="md" color="green">{category}</Tag>
                                </div>
                                <div title={i18next.t('challenges-amount')}>
                                    <Tag size="sm" color="gray">
                                        {`${data.challenges[category].length}`}
                                    </Tag>
                                </div>
                            </div>
                            {data.challenges[category].map((challenge) => (
                                <div key={challenge.guid} className="mt-1">
                                    <CardToggle
                                        size="sm"
                                        key={challenge.guid}
                                        title={challenge.name}
                                    >
                                        <div className="flex flex-wrap">
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/6">
                                                    <Tag color="blue" size="sm">GUID</Tag>
                                                </div>
                                                <div className="w-5/6">
                                                    <span>{challenge?.guid}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/6">
                                                    <Tag color="yellow"size="sm">{i18next.t('type')}</Tag>
                                                </div>
                                                <div className="w-5/6">
                                                    <span>{challenge?.typeAnswer}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/6">
                                                    <Tag color="red" size="sm">{i18next.t('score')}</Tag>
                                                </div>
                                                <div className="w-5/6">
                                                    <span>{challenge?.score}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/6">
                                                    <Tag color="gray" size="sm">
                                                        {i18next.t('status')}
                                                    </Tag>
                                                </div>
                                                <div className="w-5/6">
                                                    <span>
                                                        {challenge.status}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                <div className="w-1/6">
                                                    <Tag color="orange" size="sm">
                                                        {i18next.t('description')}
                                                    </Tag>
                                                </div>
                                                <div className="w-5/6">
                                                    <span>
                                                        {challenge.description}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardToggle>
                                </div>
                            ))}
                        </div>
                    ))
                    
                ) : (
                    <Banner borderColor='red'>
                        {i18next.t('challenges-not-found')}
                    </Banner>
                )}
            </div>
            <div className="fixed top-0 right-0 m-4 items-center" title={i18next.t('close')}>
                <Button color="red" icon={faClose} onClick={onCancel}>
                    {i18next.t('close')}
                </Button>
            </div>
        </div>
        
    );
}

CompetitionViewModal.propTypes = {
    data: PropTypes.shape().isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CompetitionViewModal;