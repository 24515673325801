/**
 * Adds a leading zero to a number if it's less than nine
 * @param {number} number
 * @returns {string} The prefixed number
 */
export default function prefixNumber(number) {
    if (number < 10) {
        return `0${number}`;
    }

    return String(number);
}
