import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import resetPassword from '../../services/resetPassword';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Title from '../atoms/Title';

/**
 * ResetPassword organism creates a form to reset the password with newPassword/confirm newPassword
 */
function ResetPassword() {
    const { user } = useParams();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const navigate = useNavigate();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    // Reset password with newPassword and confirm newPassword
    const resetMutation = useMutation({
        mutationFn: ({ password }) => resetPassword(user, password, i18next.language),
        onMutate: () => {
            removeFeedback();
        },
        onSuccess: () => {
            createFeedback('success', i18next.t('password-successfully-reset'), true);
            navigate('/auth/sign-in');
        },
        onError: () => {
            createFeedback('error', i18next.t('something-went-wrong'));
            passwordRef.current.value = '';
            passwordRef.current.focus();
        },
    });

    /**
     * Validate and reset Password
     * @param {React.FormEvent} e
     */
    const handleReset = (e) => {
        e.preventDefault();

        const password = passwordRef.current?.value;
        const confirmPassword = confirmPasswordRef.current?.value;

        // Validate empty values
        if (!password || !confirmPassword) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            return;
        }

        // Validate password
        if (!/^.{8,}$/.test(password)) {
            createFeedback('warn', i18next.t('password-regex'));
            return;
        }

        // Passwords don't match
        if (password !== confirmPassword) {
            createFeedback('warn', i18next.t('passwords-not-match'));
            return;
        }

        resetMutation.mutate({ password });
    };

    return (
        <div className="w-full flex flex-col gap-5">
            <Container>
                <div className="w-full px-5 py-7 flex flex-col gap-7 items-center">
                    <div className="w-full flex flex-col gap-2 items-center">
                        <Title size="xl">{i18next.t('reset-password')}</Title>
                        <Title size="sm">{i18next.t('you-will-recover-your-account')}</Title>
                    </div>

                    <form onSubmit={handleReset} className="w-full flex flex-col gap-3">
                        <Input
                            inputRef={passwordRef}
                            color="gray"
                            type="password"
                            complete="new-password"
                            focus
                            placeholder={i18next.t('new-password')}
                        />
                        <Input
                            inputRef={confirmPasswordRef}
                            color="gray"
                            type="password"
                            complete="new-password"
                            placeholder={i18next.t('confirm-password')}
                        />

                        <Button
                            isLoading={resetMutation.isLoading}
                            submit
                            color="green"
                            icon={faRotateRight}
                            onClick={handleReset}
                        >
                            {i18next.t('reset-password')}
                        </Button>
                    </form>
                </div>
            </Container>

            {feedbackElement}
        </div>
    );
}

export default ResetPassword;
