/**
 * compileAnswer runs the code answer and gives feedback of the code
 * @method GET
 * @param {string} competition
 * @param {string} challenge
 * @param {{
 *     guid: string;
 *     type: string;
 *     data: string;
 *     checked: boolean | undefined;
 *     language: {
 *         guid: string;
 *         code: string;
 *         name: string;
 *         editor: string;
 *         template: string;
 *     } | null;
 * }[]} answers
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<{
 *      messages: string;
 *      context: 'success' | 'message' | 'warn' | 'error';
 * }>}
 */
export default async function compileAnswer(competition, challenge, answers, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/${competition}/challenges/${challenge}/compile`;
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers, body: JSON.stringify({ answers }) });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }

        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
