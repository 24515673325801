import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useSession from '../../hooks/useSession';
import autoLogin from '../../services/autoLogin';

/**
 * AutoLogin page shows a charging status while creates the account of an unregistered user
 */
function AutoLoginPage() {
    const { user, competition } = useParams();
    const { createSession } = useSession();
    const { createFeedback, removeFeedback } = useFeedback();

    const navigate = useNavigate();

    // Validate user token
    const autoLoginQuery = useQuery({
        queryKey: ['auto-login', user],
        queryFn: () => autoLogin(user, i18next.language),
    });

    // Handle response for validate user token
    useEffect(() => {
        removeFeedback();
        if (autoLoginQuery.isError) {
            createFeedback('error', i18next.t('something-went-wrong'), true);
            navigate('/auth/sign-in');
        } else if (autoLoginQuery.isSuccess) {
            createSession(autoLoginQuery.data.jwt);
            if (autoLoginQuery.data.pending) {
                createFeedback('message', i18next.t('set-password-continue'), true);
                navigate(`/profile/${competition}`);
                return;
            }

            navigate('/competitions/private');
        }
    }, [autoLoginQuery, competition, createFeedback, removeFeedback, createSession, navigate]);

    return (
        <div className="w-full h-screen p-5 flex flex-col gap-10 justify-center items-center">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" className="text-smgreen-dark animate-spin" />
            <div className="flex flex-col gap-5 items-center">
                <p className="sm:text-3xl text-lg font-semibold">{i18next.t('welcome-to-smart-ranks')}</p>
                <p className="sm:text-base text-xs">{i18next.t('redirected-in-moments')}</p>
            </div>
        </div>
    );
}

export default AutoLoginPage;
