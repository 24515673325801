import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import getCategories from '../../services/getCategories';
import Search from '../molecules/Search';
import Competitions from '../organisms/Competitions';
import NavbarTemplate from '../templates/NavbarTemplate';

/**
 * Competitions page shows the list of public or private competitions
 */
function CompetitionsPage() {
    const { visibility } = useParams();
    const { session } = useSession();
    const [criteria, setCriteria] = useState({ name: '', status: [], categories: [] });
    const [filters, setFilters] = useState();

    // Get categories and update filters
    const categoriesQuery = useQuery({
        queryKey: ['categories'],
        queryFn: () => getCategories(i18next.language),
    });

    // Load categories for filters
    useEffect(() => {
        if (categoriesQuery.data) {
            const { categories } = categoriesQuery.data;
            const categoriesFilter = {
                key: 'categories',
                unique: false,
                title: i18next.t('categories'),
                checkboxes: categories.map(({ id, name }) => ({ key: `${id}`, value: name, checked: false })),
            };

            setFilters([categoriesFilter]);
        }
    }, [categoriesQuery.data]);

    // Update criteria on change filters
    useEffect(() => {
        if (filters) {
            const categories = filters
                .find((filter) => filter.key === 'categories')
                .checkboxes.filter((checkbox) => checkbox.checked)
                .map((checkbox) => parseInt(checkbox.key, 10));

            setCriteria((prev) => ({ ...prev, categories }));
        }
    }, [filters]);

    /**
     * Update criteria on click search
     * @param {string} input
     */
    const handleSearch = (input) => {
        setCriteria((prev) => ({ ...prev, name: input }));
    };

    // Handle session
    if (visibility === 'private' && !session) {
        return <Navigate to="/auth/sign-in" />;
    }

    if (visibility === 'history' && !session) {
        return <Navigate to="/auth/sign-in" />;
    }

    return (
        <NavbarTemplate>
            <div className="w-full flex 2xl:flex-row flex-col-reverse sm:gap-10 gap-5">
                <Competitions visibility={visibility} criteria={criteria} />
                <div className="2xl:max-w-md w-full flex flex-col gap-5">
                    <Search
                        isLoading={categoriesQuery.isLoading}
                        placeholder={i18next.t('search-competitions')}
                        filters={filters}
                        onFilter={(newFilters) => setFilters(newFilters)}
                        onSearch={handleSearch}
                    />
                </div>
            </div>
        </NavbarTemplate>
    );
}

export default CompetitionsPage;
