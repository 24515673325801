/**
 * getFinishedCompetitionDetails gets the
 * @method GET
 * @param {string} guid - Competition GUID
 * @param {string} jwt - JWT token
 * @param {string} language - Language
 * @returns {Promise<{
 *  competition: {
 *    competition: string,
 *    total: number,
 *    candidate: {
 *      name: string,
 *      email: string,
 *      date: string
 *    },
 *    categories: Array<{
 *      name: string,
 *      score: number,
 *      total: number
 *    }>,
 *    score: number,
 *  }
 * }>}
 */
export default async function getFinishedCompetitionDetails(guid, jwt, language) {
    try {
        const url = `${process.env.REACT_APP_APP_URL}/api/v1/competitions/result/info/${guid}`;
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: jwt,
        };

        const response = await fetch(url, { method, headers });
        const data = await response.json();

        if (!response.ok) {
            const errorData = { controlled: true, data };
            throw errorData;
        }
        return data;
    } catch (error) {
        if (error.controlled) {
            throw error.data;
        }

        const errorData = { status: 500, messages: [error.message], context: 'error' };
        throw errorData;
    }
}
