import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import Feedback from '../components/atoms/Feedback';

/**
 * useFeedback hook provides functionality for injecting and removing feedback messages
 * @returns {{
 *      feedbackElement: JSX.Element;
 *      createFeedback: (type: 'success' | 'message' | 'warn' | 'error', text: string, store: boolean) => void;
 *      removeFeedback: () => void;
 * }}
 */
function useFeedback() {
    const root = useRef(null);
    const ref = useRef();

    /**
     * Injects a typed Feedback into the "feedback" element
     * @type {(type: 'success' | 'message' | 'warn' | 'error', text: string, store: boolean) => void}
     */
    const createFeedback = useCallback((type, text, store) => {
        if (store) {
            sessionStorage.setItem('feedback', JSON.stringify({ type, text }));
        }

        if (ref.current) {
            if (!root.current) {
                root.current = ReactDOM.createRoot(ref.current);
            }

            root.current.render(<Feedback type={type}>{text}</Feedback>);
            ref.current.removeAttribute('hidden');
        }
    }, []);

    /**
     * Unmount the injected Feedback
     * @type {() => void}
     */
    const removeFeedback = useCallback(() => {
        sessionStorage.removeItem('feedback');
        if (ref.current && root.current) {
            root.current.unmount();
            root.current = null;
            ref.current.setAttribute('hidden', true);
        }
    }, []);

    // Load stored feedback
    useEffect(() => {
        const feedbackData = sessionStorage.getItem('feedback');
        if (ref.current && feedbackData) {
            const { type, text } = JSON.parse(feedbackData);
            createFeedback(type, text);
        }
    }, [ref, createFeedback]);

    return { feedbackElement: <div ref={ref} hidden />, createFeedback, removeFeedback };
}

export default useFeedback;
