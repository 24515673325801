/**
 * datetimeToUTC converts a date and time from datetime-local format to UTC format
 * @param {string} dateTimeLocal - Date and time in datetime-local format (YYYY-MM-DDTHH:MM)
 * @returns {string} Date and time in UTC format (Y-m-d H:i)
 */
export default function datetimeToUTC(dateTimeLocal) {
    const [datePart, timePart] = dateTimeLocal.split('T');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');

    // Formatear la fecha y hora en el formato esperado por el backend
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
}