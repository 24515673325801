import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import activateAccount from '../../services/activateAccount';

/**
 * ActivateAccount page shows a charging status while activates the user account
 */
function ActivateAccountPage() {
    const { user } = useParams();
    const { createFeedback, removeFeedback } = useFeedback();
    const navigate = useNavigate();

    // Validate user
    const activateAccountQuery = useQuery({
        queryKey: ['activate-account', user],
        queryFn: () => activateAccount(user, i18next.language),
    });

    // Handle response for activate user account
    useEffect(() => {
        removeFeedback();
        if (activateAccountQuery.isError) {
            createFeedback('error', i18next.t('something-went-wrong'), true);
            navigate('/auth/sign-up');
        } else if (activateAccountQuery.isSuccess) {
            createFeedback('success', i18next.t('account-successfully-activated'), true);
            navigate('/auth/sign-in');
        }
    }, [activateAccountQuery, createFeedback, removeFeedback, navigate]);

    return (
        <div className="w-full h-screen p-5 flex flex-col gap-10 justify-center items-center">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" className="text-smgreen-dark animate-spin" />
            <div className="flex flex-col gap-5 items-center">
                <p className="sm:text-3xl text-lg font-semibold">{i18next.t('activating-account')}</p>
                <p className="sm:text-base text-xs">{i18next.t('redirected-in-moments')}</p>
            </div>
        </div>
    );
}

export default ActivateAccountPage;
