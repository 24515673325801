import { faRotateLeft, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import FormTemplate from '../templates/FormTemplate';

/**
 * Error page shows an alert to notify the malfunction of the app
 */
function ErrorPage() {
    const navigate = useNavigate();
    const error = useRouteError();

    // Report the error in new relic
    useEffect(() => {
        if (window.NREUM && window.NREUM.noticeError) {
            window.NREUM.noticeError(error);
        }
    }, [error]);

    return (
        <FormTemplate>
            <div className="flex flex-col gap-7 items-center">
                <div className="flex flex-col gap-3">
                    <FontAwesomeIcon icon={faTriangleExclamation} size="6x" className="text-smred-dark" />
                    <p className="sm:text-2xl text-lg text-center">{i18next.t('something-went-wrong')}</p>
                </div>
                <button type="button" onClick={() => navigate(-1)} className="flex gap-2 items-center outline-none">
                    <FontAwesomeIcon icon={faRotateLeft} size="lg" />
                    <p>{i18next.t('go-back')}</p>
                </button>
            </div>
        </FormTemplate>
    );
}

export default ErrorPage;
