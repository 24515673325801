/**
 * UTCToDatetime converts a date and time from UTC format to datetime-local format
 * @param {string} dateTimeUTC - Date and time in UTC format (Y-m-d H:i)
 * @returns {string} Date and time in datetime-local format (YYYY-MM-DDTHH:MM)
 */
export default function UTCToDatetime(dateTimeUTC) {
    if (!dateTimeUTC) return '';
    
    const date = new Date(dateTimeUTC);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDateTime;
}