import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

/**
 * Highlight atom renders HTML plain text code by converting and highlighting it to DOM
 * @param {{ children: string; }} props
 */
function Highlight({ children }) {
    /**
     * Replace the <pre><code> with their highlighted content
     * @param {import('html-react-parser').DOMNode} node
     */
    const handleHighlight = (node) => {
        if (node.name === 'pre' && node.firstChild.name === 'code') {
            return (
                <SyntaxHighlighter
                    style={atomOneLight}
                    customStyle={{ backgroundColor: '#f4f4f5', borderRadius: '6px' }}
                    showLineNumbers
                    lineNumberStyle={{ color: '#a1a1aa' }}
                >
                    {node.children[0].children[0].data}
                </SyntaxHighlighter>
            );
        }

        return node;
    };

    return <div className="whitespace-pre-wrap">{parse(children, { replace: handleHighlight })}</div>;
}

Highlight.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Highlight;
