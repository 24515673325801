/**
 * getEducations gets all the education info of the logged in user
 * @method GET
 * @param {string} jwt
 * @param {string} language
 * @returns {Promise<Array<{
*       educationID: number | null;
*       institutionName: string | null;
*       degree: string | null;
*       area: string | null;
*       startDate: string | null;
*       endDate: string | null;
*       description: string | null;
* }>>}
*/
export default async function getUser(jwt, language) {
   try {
       const url = `${process.env.REACT_APP_APP_URL}/api/v1/user/education`;
       const method = 'GET';
       const headers = {
           'Content-Type': 'application/json',
           'Accept-Language': language,
           Authorization: jwt,
       };

       const response = await fetch(url, { method, headers });
       const data = await response.json();

       if (!response.ok) {
           const errorData = { controlled: true, data };
           throw errorData;
       }

       return data.education;
   } catch (error) {
       if (error.controlled) {
           throw error.data;
       }

       const errorData = { status: 500, messages: [error.message], context: 'error' };
       throw errorData;
   }
}
