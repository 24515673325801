/**
 * formatDate converts a date in UTC (GMT +0) to a local date format
 * @param {string} date
 * @param {boolean} monthFormat - If true, format as YYYY/MM
 * @returns {string} Formated local date
 */
export default function formatDate(date, monthFormat = false) {
    const dateFormatted = new Date(date);

    if (monthFormat) {
        let year = dateFormatted.getFullYear();
        let month = dateFormatted.getMonth() + 1;
        
        if (month === 12) {
            month = 1;
            year+=1;
        }

        month = month.toString().padStart(2, '0');
        return `${year}/${month}`;
    }


    const day = dateFormatted.getDate().toString().padStart(2, '0');
    const month = (dateFormatted.getMonth() + 1).toString().padStart(2, '0');
    const year = dateFormatted.getFullYear();
    let hours = dateFormatted.getHours();
    let minutes = dateFormatted.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) hours -= 12;
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}